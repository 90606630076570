import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from '@altibox/design-system-component-lib';

import { RelocationStepId } from 'app/store/slices/relocations-slice';
import { useAppSelector } from 'app/hooks/redux-thunk';

import styles from './steps-header.module.scss';

export const StepsHeader = () => {
  const currentStep = useAppSelector((state) => state.relocation.currentStep);

  const { t } = useTranslation();

  const renderStep = (name: string, index: number, step: RelocationStepId) => {
    let ariaLabel = t('pages.relocationForm.stepsNavigation.ariaLabel', { step: index + 1, name });
    if (step === index) {
      ariaLabel += '. ' + t('pages.relocationForm.stepsNavigation.ariaLabelActiveStep');
    }

    return (
      <li className={styles.step} key={`step-li-${name}`} aria-label={ariaLabel}>
        <div className={styles.container}>
          <div className={`${styles.number} ${step === index ? styles.active : ''}`}>{index + 1}</div>{' '}
          <Typography
            variant="uiText3"
            component="div"
            bold={true}
            className={`${styles.heading} ${step === index ? styles.show : ''}`}
            maxBreakpoint={Breakpoint.TABLET}
          >
            {name}
          </Typography>
        </div>
      </li>
    );
  };

  const steps = [
    t('pages.relocationForm.stepsNavigation.address'),
    t('pages.relocationForm.stepsNavigation.date'),
    t('pages.relocationForm.stepsNavigation.summary'),
  ];
  return (
    <header className={styles.stepsHeader}>
      <ul className={styles.steps}>{steps.map((name, index) => renderStep(name, index, currentStep))}</ul>
    </header>
  );
};
