import { useTranslation } from 'react-i18next';
import {
  Breakpoint,
  Input,
  Typography as T,
  UiComponentState,
  HeadingLevel,
  RadioButton,
} from '@altibox/design-system-component-lib';
import classNames from 'classnames';
import { AlertWarning } from 'app/media';

import React, { useState, Dispatch } from 'react';
import { AvaliableNetworkModes } from '../../../../network-common';
import { Helper } from 'app/components/helper/helper';
import { ChannelWidthType } from 'app/store/types/network-types';
import { ConfigAction, WifiBandConfig } from './ssid-reducer';
import { SsidSelect } from './components/ssid-select';

import styles from './ssid-form.module.scss';

interface Props {
  availableNetworkModes: AvaliableNetworkModes | null;
  uiShowAdvanced?: boolean;
  uiHideBasic?: boolean;
  uiHideEncryption?: boolean;
  fieldValues: { [name: string]: MinesiderBackend.FieldValues };
  className?: string;
  isCombined: boolean;
  config: WifiBandConfig;
  configIndex: number;
  configDispatch: Dispatch<ConfigAction>;
}

export const SSIDForm = (props: Props) => {
  const { t } = useTranslation();
  const {
    availableNetworkModes,
    uiHideBasic,
    uiHideEncryption,
    uiShowAdvanced,
    fieldValues,
    className,
    isCombined,
    config,
    configIndex,
    configDispatch,
  } = props;

  const [hidePassword, setHidePassword] = useState(true);
  const [showSSIDNameHelper, setShowSSIDNameHelper] = useState(false);
  const [showPasswordHelper, setShowPasswordHelper] = useState(false);

  const DEFAULT_ENCRYPTION_OPTION = [{ value: 'WPA2', label: t('pages.network.encryptions.WPA2') }];

  const { data: configData } = config;

  // The 2.4GHz is the basis for the configuration, so only display that if they are identical.
  if (configData.type === 'STANDARD' && isCombined && configData.radioBand !== 2) {
    return null;
  }

  // If network is combined or is mesh,
  const wifiBand = !configData.isMesh && !isCombined ? configData.radioBand : undefined;
  const componentId = configData.id + (wifiBand || '');

  const encryptionOptions = () =>
    fieldValues.enc_protocol?.values?.default.map((val) => ({
      label: t(`pages.network.encryptions.DYNAMIC_KEYS.${val}`),
      value: val,
    })) || DEFAULT_ENCRYPTION_OPTION;

  const getCompatibleNetworkModes = (newModeValue: string) => {
    const currentChannelWidth = configData.channelWidth;

    if (!currentChannelWidth) {
      return;
    }

    // Choose a compatible channel width, try to match up with existing selection.
    return availableNetworkModes?.find((mode) => mode.id === newModeValue)?.channelWidths[
      currentChannelWidth as ChannelWidthType
    ];
  };

  const onChangeEncryption = (e: React.ChangeEvent<HTMLSelectElement>) => {
    configDispatch({ index: configIndex, type: 'encProtocol', value: e.currentTarget.value });
  };

  const onChangeMode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateModeCompatibility(e.currentTarget.value);
  };

  const onChangeChannelWidth = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateChannelWidthCompatibility(e.currentTarget.value);
  };

  const onChangeChannel = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateChannel(parseInt(e.currentTarget.value, 10) || 0);
  };

  const updateModeCompatibility = (newModeValue: string) => {
    configDispatch({ index: configIndex, type: 'mode', value: newModeValue });

    const currentChannelWidth = configData.channelWidth;

    if (!currentChannelWidth) {
      return;
    }

    // Choose a compatible channel width, try to match up with existing selection.
    const compatibleModes = availableNetworkModes?.find((mode) => mode.id === newModeValue)?.channelWidths;
    const isCompatible = getCompatibleNetworkModes(newModeValue);

    // If no existing selection exist, default to first available value.
    if (!isCompatible && compatibleModes) {
      const data = Object.entries(compatibleModes)[0];
      updateChannelWidthCompatibility(data[0]);
    }
  };

  const updateChannelWidthCompatibility = (newChannelWidthValue: string) => {
    configDispatch({ index: configIndex, type: 'channelWidth', value: newChannelWidthValue });
    const currentMode = configData.mode;
    const currentChannel = configData.channel;

    if (!currentMode) {
      return;
    }

    // Choose a compatible channel, try to match up with existing selection.
    const channelsFromSelectedNetworkMode = getCompatibleNetworkModes(currentMode)?.channels || [];
    const isCompatible = Object.entries(channelsFromSelectedNetworkMode).find(
      (channel) => (parseInt(channel[0], 10) || 0) === currentChannel,
    );

    // If no existing selection exist, default to first available value.
    if (!isCompatible) {
      const data = Object.entries(channelsFromSelectedNetworkMode)[0];
      updateChannel(parseInt(data[0], 10) || 0);
    }
  };

  const updateChannel = (value: number) => {
    configDispatch({ index: configIndex, type: 'channel', value });
  };

  const invalidInputHelperText = (text: string, domId: string) => (
    <div className={styles.iconContainer}>
      <div>
        <AlertWarning />
      </div>
      <T variant="uiText3" component="span" className={styles.helpText} maxBreakpoint={Breakpoint.TABLET} id={domId}>
        {text}
      </T>
    </div>
  );

  const passwordInput = () => (
    <section>
      <Helper
        heading={t('pages.network.advanced.wifi.helpTexts.password.title')}
        text={t('pages.network.ssid.password')}
        textVariant="uiText2"
        textComponent="h3"
        alertHeadingElement={HeadingLevel.H4}
        id={`passwordLabel${componentId}`}
      >
        <T variant="uiText3" component="span" maxBreakpoint={Breakpoint.TABLET} id={`passwordHelpText${componentId}`}>
          {t('pages.network.advanced.wifi.helpTexts.password.paragraph1')}
        </T>
      </Helper>
      <Input
        defaultValue={configData.encKey}
        id={`ssid_password_${componentId}`}
        data-testid="network-password"
        isHidden={hidePassword}
        onToggleHidden={() => setHidePassword(!hidePassword)}
        isPassword={true}
        onChange={(e) => configDispatch({ index: configIndex, type: 'networkPassword', value: e.currentTarget.value })}
        onBlur={() => setShowPasswordHelper(true)}
        label={''}
        aria-labelledby={`passwordLabel${componentId}`}
        aria-describedby={`passwordHelpText${componentId}`}
        uiComponentState={
          !config.formMetadata.isValidNetworkPassword && showPasswordHelper
            ? UiComponentState.ERROR
            : UiComponentState.SUCCESS
        }
        className={styles.inputLabel}
      />
      {showPasswordHelper && !config.formMetadata.isValidNetworkPassword && (
        <div className={styles.helpTextContainer}>
          {invalidInputHelperText(t('pages.network.ssid.passwordHelper'), `ssid_password_${componentId}`)}
        </div>
      )}
    </section>
  );

  const basicSettings = () => {
    if (uiHideBasic) {
      return null;
    }
    return (
      <>
        {ssidName()}

        {!uiHideEncryption && (
          <SsidSelect
            helperHeading={t('pages.network.advanced.wifi.helpTexts.encryption.title')}
            label={t('pages.network.ssid.encryptionName')}
            options={encryptionOptions()}
            onChange={onChangeEncryption}
            uniqueName={`encryption${componentId}`}
            selectedValue={configData.encProtocol}
            helperContent={
              <>
                <T variant="uiText3" component="p" maxBreakpoint={Breakpoint.TABLET}>
                  {t('pages.network.advanced.wifi.helpTexts.password.paragraph1')}
                </T>
                <T variant="uiText3" component="p" maxBreakpoint={Breakpoint.TABLET}>
                  {t('pages.network.ssid.encryptionHelper')}
                </T>
              </>
            }
          />
        )}

        {passwordInput()}
      </>
    );
  };

  const advancedSettings = () => {
    if (!uiShowAdvanced || configData.mode === null) {
      return null;
    }
    const networkModeOptions = availableNetworkModes!
      .filter((mode) => mode.id !== 'N_A')
      .map((mode) => ({
        value: mode.id,
        label: t(`pages.network.wifiModes.DYNAMIC_KEYS.${mode.id}`),
      }));

    const networkChannelWidthOptions = () =>
      availableNetworkModes!
        .filter((mode) => mode.id === configData.mode)
        .map((mode) =>
          (Object.keys(mode.channelWidths) as ChannelWidthType[]).map((key) => {
            const width = mode.channelWidths[key];
            return {
              value: key,
              label: width.displayName,
            };
          }),
        )[0];

    const networkChannelOptions = () =>
      availableNetworkModes!
        .filter((mode) => mode.id === configData.mode)
        .map((mode) => {
          if (configData.channelWidth) {
            const channels = mode.channelWidths[configData.channelWidth as ChannelWidthType].channels;
            return Object.entries(channels).map((channel) => ({
              value: channel[0],
              label: channel[1].displayName,
            }));
          }
        })[0];

    return (
      <>
        {ssidVisibilityInput()}

        <SsidSelect
          label={t('pages.network.ssid.mode')}
          helperHeading={t('pages.network.advanced.wifi.helpTexts.mode.title')}
          onChange={onChangeMode}
          options={networkModeOptions}
          uniqueName={`networkMode${componentId}`}
          selectedValue={configData.mode}
          helperContent={
            <>
              <T variant="uiText3" component="p" maxBreakpoint={Breakpoint.TABLET}>
                {t('pages.network.advanced.wifi.helpTexts.mode.paragraph1')}
              </T>
              <T variant="uiText3" component="p" maxBreakpoint={Breakpoint.TABLET}>
                {t('pages.network.advanced.wifi.helpTexts.mode.paragraph2')}
              </T>
            </>
          }
        />

        <SsidSelect
          label={t('pages.network.ssid.channelWidth')}
          helperHeading={t('pages.network.advanced.wifi.helpTexts.channelWidth.title')}
          uniqueName={`channelWidth${componentId}`}
          options={networkChannelWidthOptions()}
          onChange={onChangeChannelWidth}
          selectedValue={configData.channelWidth}
          helperContent={
            <T variant="uiText3" component="p" maxBreakpoint={Breakpoint.TABLET}>
              {t('pages.network.advanced.wifi.helpTexts.channelWidth.paragraph1')}
            </T>
          }
        />

        <SsidSelect
          label={t('pages.network.ssid.channel')}
          helperHeading={t('pages.network.advanced.wifi.helpTexts.channel.title')}
          uniqueName={`channel${componentId}`}
          options={networkChannelOptions() || []}
          onChange={onChangeChannel}
          selectedValue={configData.channel}
          helperContent={
            <T variant="uiText3" component="p" maxBreakpoint={Breakpoint.TABLET}>
              {t('pages.network.advanced.wifi.helpTexts.channel.paragraph1')}
            </T>
          }
        />
      </>
    );
  };

  const ssidName = () => (
    <section>
      <Helper
        text={t('pages.network.ssid.name')}
        heading={t('pages.network.advanced.wifi.helpTexts.ssid.title')}
        textVariant="uiText2"
        textComponent="h3"
        alertHeadingElement={HeadingLevel.H4}
        id={`ssidName${componentId}`}
      >
        <div id={`ssidNameHelpText${componentId}`} className={styles.informationText}>
          <T variant="uiText3" component="p">
            {t('pages.network.advanced.wifi.helpTexts.ssid.paragraph1')}
          </T>
          <T variant="uiText3" component="p">
            {t('pages.network.ssid.nameRules')}
          </T>
        </div>
      </Helper>
      <Input
        defaultValue={configData.ssid}
        name="ssid_name"
        id={`ssid_name_${componentId}`}
        label={''}
        onChange={(e) => configDispatch({ index: configIndex, type: 'networkSsid', value: e.currentTarget.value })}
        onBlur={() => setShowSSIDNameHelper(true)}
        type="text"
        aria-labelledby={`ssidName${componentId}`}
        aria-describedby={`ssidNameHelpText${componentId}`}
        uiComponentState={
          !config.formMetadata.isValidNetworkSsid && showSSIDNameHelper
            ? UiComponentState.ERROR
            : UiComponentState.SUCCESS
        }
        className={styles.inputLabel}
      />
      <div className={styles.helpTextContainer}>
        {!config.formMetadata.isValidNetworkSsid &&
          showSSIDNameHelper &&
          invalidInputHelperText(t('pages.network.ssid.nameRules'), `ssidNameHelpText${componentId}`)}
      </div>
    </section>
  );

  const ssidVisibilityInput = () => (
    <section>
      <RadioButton
        legend={t('pages.network.ssid.ssidBroadcastRadioHeading')}
        options={[
          { label: t('pages.network.ssid.ssidBroadcastRadioLabelVisible'), value: 'false' },
          { label: t('pages.network.ssid.ssidBroadcastRadioLabelHidden'), value: 'true' },
        ]}
        selectedValue={configData.isSSidHidden?.toString()}
        getSelectedValue={(selectedValue) =>
          configDispatch({ index: configIndex, type: 'isSSidHidden', value: selectedValue !== 'false' })
        }
        groupname={`isSSidHidden_${componentId}`}
        className={styles.isSsidHiddenRadio}
      />
    </section>
  );

  return (
    <>
      <div className={classNames(className, styles.form)}>
        {basicSettings()}

        {advancedSettings()}
      </div>
    </>
  );
};
