import {
  Alert,
  Breakpoint,
  NavigationList,
  NavigationListItem,
  Typography,
} from '@altibox/design-system-component-lib';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { navigationService } from 'app/service/navigation/navigation-service';

import styles from './streamingServiceNavigationList.module.scss';

interface Props {
  onRecoveryClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  product: MinesiderBackend.TvServiceContent;
}

export const StreamingServiceNavigationList = ({ product, onRecoveryClick }: Props) => {
  const { t } = useTranslation();
  if (!product.portalId) {
    return null;
  }

  const portalId = product.portalId;
  const navLinks: NavigationListItem[] = [];

  if (portalId === 'NETFLIX') {
    navLinks.push({
      label: t(`pages.streamingService.DYNAMIC_KEYS.${portalId}.tvStoreLinkLabel`),
      subtitle: t(`pages.streamingService.DYNAMIC_KEYS.${portalId}.tvStoreLinkSubtitle`),
      url: `${navigationService.PAGES.tvStore.url}?portalId=${portalId}`,
    });
  }
  if (portalId === 'SUMOBASIS') {
    navLinks.push({
      label: t(`pages.streamingService.DYNAMIC_KEYS.${portalId}.upgradeLinkLabel`),
      subtitle: t(`pages.streamingService.DYNAMIC_KEYS.${portalId}.upgradeLinkSubtitle`),
      url: 'https://play.tv2.no/login-altibox?redirectUrl=/pakker',
    });
  }
  if (product.type === 'FOC' && !product.lockedTo) {
    navLinks.push({
      label: t('pages.streamingService.focUnsubscribeLinkLabel', { name: product.name }),
      url: `${navigationService.PAGES.foc.url}?portalId=${portalId}`,
    });
  }
  if (product.actions?.includes('RECOVERY')) {
    navLinks.push({
      label: t('pages.streamingService.recoveryLinkLabel'),
      subtitle: t('pages.streamingService.recoveryLinkSubtitle', { name: product.name }),
      linkType: 'button',
      onButtonClick: onRecoveryClick,
    });
  }

  if (navLinks.length < 1) {
    return null;
  }

  return (
    <>
      <Typography
        component={'h2'}
        variant="headline5"
        maxBreakpoint={Breakpoint.TABLET}
        className={styles.navigationListHeader}
      >
        {t('pages.streamingService.navigationListHeading')}
      </Typography>

      {product.lockedTo && (
        <div className={styles.alertContainer}>
          <Alert
            alertType="info"
            heading={`${t('pages.streamingService.lockPeriodAlertHeading', { name: product.name })}`}
            headingElement="strong"
            isExpandable={false}
          />
        </div>
      )}

      <NavigationList links={navLinks} className={styles.navigationList} />
    </>
  );
};
