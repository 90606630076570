import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from '@altibox/design-system-component-lib';

import { Panel } from 'app/components/panel';
import { InfoCircle, Tv, VoipGrey, Wifi } from 'app/media';
import { CustomerLocationContext, isActiveLocation, isInactiveCustomer } from 'app/store/types/user-context-types';
import { LocationSiteName } from 'app/components/location-sitename/location-sitename';
import { LocationIcon } from '../location-icon/location-icon';

import styles from './location-panel.module.scss';

interface Props {
  onClick: () => void;
  location: CustomerLocationContext;
}

interface Service {
  i18n: string;
  iconComponent: ReactElement;
}

interface ServiceMap {
  [index: string]: Service;
}

const serviceMap: ServiceMap = {
  INTERNET: {
    i18n: 'services.internet.name',
    iconComponent: <Wifi className={styles.wifi} />,
  },
  TV: {
    i18n: 'services.tv.name',
    iconComponent: <Tv className={styles.tv} />,
  },
  VOIP: {
    i18n: 'services.voip.name',
    iconComponent: <VoipGrey />,
  },
  TA_VOIP: {
    i18n: 'services.ta_voip.name',
    iconComponent: <VoipGrey />,
  },
};

export const LocationPanel: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { location } = props;
  const services: Service[] = isActiveLocation(location)
    ? location.services
        .filter((serviceKey) => Object.keys(serviceMap).includes(serviceKey))
        .map((serviceKey) => serviceMap[serviceKey])
    : [];

  return (
    <Panel onClick={props.onClick}>
      <div className={styles.addressContainer}>
        <div className={styles.row}>
          <LocationIcon customerType={props.location.customerType} className={styles.house} role="presentation" />
          <Typography
            data-hj-suppress={true}
            className={styles.title}
            variant="headline5"
            component="h4"
            maxBreakpoint={Breakpoint.MOBILE}
          >
            {location.address.streetaddress || t('components.locationPanel.unknownStreetAddress')}
          </Typography>
        </div>
        <div className={styles.addressRow}>
          <div className={styles.container}>
            <LocationSiteName location={location} />
          </div>
        </div>
      </div>
      {services.map((service) => (
        <ServiceComponent key={service.i18n} service={service} />
      ))}
      {(isInactiveCustomer(location) && (
        <div className={styles.container}>
          <InfoCircle variant="filled" role="graphics-symbol" title={t('components.locationPanel.infoIconTitle')} />
          <Typography variant="uiText2" component="p" maxBreakpoint={Breakpoint.TABLET} className={styles.serviceName}>
            {t('components.locationPanel.inactiveLocation')}
          </Typography>
        </div>
      )) ||
        (!isActiveLocation(location) && (
          <div className={styles.container}>
            <InfoCircle variant="filled" role="graphics-symbol" title={t('components.locationPanel.infoIconTitle')} />
            <Typography
              variant="uiText2"
              component="p"
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.serviceName}
            >
              {t('components.locationPanel.newLocation')}
            </Typography>
          </div>
        ))}
    </Panel>
  );
};

interface ServiceProps {
  service: Service;
}

const ServiceComponent: React.FC<ServiceProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.serviceIcon} title="">
        {props.service.iconComponent}
      </div>
      <Typography className={styles.serviceName} variant="uiText2" component="p" maxBreakpoint={Breakpoint.TABLET}>
        {t(props.service.i18n)}
      </Typography>
    </div>
  );
};
