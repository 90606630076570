import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Breakpoint,
  Button,
  ButtonType,
  Input,
  Typography,
  UiComponentState,
} from '@altibox/design-system-component-lib';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { CustomerPage } from 'app/pages/page-wrapper';
import { navigationService } from 'app/service/navigation/navigation-service';
import { changeEmailAccountSenderName } from 'app/store/actions/email-thunks';
import { FetchStatus } from 'app/store/root-types';

import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner/spinner';
import { emailSenderNameIsValid } from 'app/components/email/email-common/email-common';
import { useAppNavigation } from 'app/utils/navigation-utils';

import commonEmailCss from '../email-common.module.scss';
import styles from './change-sender-name.module.scss';

export const ChangeSenderName: CustomerPage = () => {
  const { emailAccountId } = useParams();
  const { emailAccounts } = useAppSelector((state) => state.email);
  const { fetchStatus } = useAppSelector((state) => state.email.changeSenderName);
  const dispatch = useAppDispatch();
  const { goToPath } = useAppNavigation();
  const [editAccount, setEditAccount] = useState<MinesiderBackend.EmailAccount>();
  const { t } = useTranslation();
  const inputInit = {
    isTouched: false,
    isValid: false,
    value: '',
  };
  const [form, setForm] = useState({
    firstName: { ...inputInit },
    lastName: { ...inputInit },
  });

  useEffect(() => {
    if (fetchStatus === FetchStatus.FULFILLED) {
      goToPath(navigationService.PAGES.email.url);
    }
  }, [fetchStatus]);

  useEffect(() => {
    const account = emailAccounts?.find((x) => x.id?.toString() === emailAccountId);
    if (!account) {
      goToPath(navigationService.PAGES.email.url);
      return;
    }

    setEditAccount(account);
    if (account.firstName && account.lastName) {
      setForm({
        ...form,
        firstName: {
          isTouched: true,
          isValid: emailSenderNameIsValid(account.firstName),
          value: account.firstName,
        },
        lastName: {
          isTouched: true,
          isValid: emailSenderNameIsValid(account.lastName),
          value: account.lastName,
        },
      });
    }
  }, [emailAccounts]);

  const formIsValid = () => form.firstName.isValid && form.lastName.isValid;

  const submitChangeSenderName = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setForm({
      ...form,
      firstName: {
        ...form.firstName,
        isTouched: true,
      },
      lastName: {
        ...form.lastName,
        isTouched: true,
      },
    });

    if (formIsValid()) {
      dispatch(
        changeEmailAccountSenderName({
          emailAccountId: editAccount!.id.toString(),
          emailAccountName: {
            firstname: form.firstName.value.trim(),
            lastname: form.lastName.value.trim(),
          },
        }),
      );
    }
  };

  const lastNameUiComponentState = () =>
    form.lastName.isTouched && !form.lastName.isValid ? UiComponentState.ERROR : undefined;
  const firstNameUiComponentState = () =>
    form.firstName.isTouched && !form.firstName.isValid ? UiComponentState.ERROR : undefined;
  const lastNameHelpText = () =>
    form.lastName.isTouched && !form.lastName.isValid
      ? t('pages.emailChangeSenderName.validation.lastName')
      : undefined;
  const firstNameHelpText = () =>
    form.firstName.isTouched && !form.firstName.isValid
      ? t('pages.emailChangeSenderName.validation.firstName')
      : undefined;

  return (
    <ContainerFixed isNarrow={true}>
      <form className={commonEmailCss.mobileEdge}>
        <Typography
          component="h1"
          variant="headline4"
          maxBreakpoint={Breakpoint.TABLET}
          className={commonEmailCss.heading}
        >
          {t('pages.emailChangeSenderName.heading', { emailAddress: editAccount?.email })}
        </Typography>
        <div className={commonEmailCss.formBorder}>
          <Typography
            variant="paragraph2"
            component="p"
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.senderNameInformation}
          >
            {t('pages.emailChangeSenderName.senderNameInformation', { emailAddress: editAccount?.email })}
          </Typography>
          <div className={styles.firstName}>
            <Input
              id="senderFirstName"
              label={t('pages.emailChangeSenderName.firstName')}
              value={form.firstName.value}
              onChange={(e) =>
                setForm({
                  ...form,
                  firstName: {
                    value: e.currentTarget.value,
                    isValid: emailSenderNameIsValid(e.currentTarget.value),
                    isTouched: true,
                  },
                })
              }
              uiComponentState={firstNameUiComponentState()}
              helpText={firstNameHelpText()}
            />
          </div>
          <Input
            id="senderLastName"
            label={t('pages.emailChangeSenderName.lastName')}
            value={form.lastName.value}
            onChange={(e) =>
              setForm({
                ...form,
                lastName: {
                  value: e.currentTarget.value,
                  isValid: emailSenderNameIsValid(e.currentTarget.value),
                  isTouched: true,
                },
              })
            }
            uiComponentState={lastNameUiComponentState()}
            helpText={lastNameHelpText()}
          />
        </div>

        {fetchStatus !== FetchStatus.PENDING && (
          <div className={commonEmailCss.buttonRow}>
            <Button
              buttonType={ButtonType.SECONDARY}
              className={commonEmailCss.cancel}
              onClick={(e) => {
                e.preventDefault();
                goToPath(navigationService.PAGES.email.url);
              }}
              type="button"
            >
              {t('pages.email.cancel')}
            </Button>
            <Button buttonType={ButtonType.PRIMARY_B} onClick={submitChangeSenderName}>
              {t('pages.emailChangeSenderName.changeSenderNameButton')}
            </Button>
          </div>
        )}
        {fetchStatus === FetchStatus.PENDING && <Spinner />}
        <div className={commonEmailCss.errorMessage}>
          {fetchStatus === FetchStatus.REJECTED && (
            <Alert
              alertType="warning"
              heading={t('pages.emailChangeSenderName.validation.alertErrorMessage')}
              headingElement="strong"
              isExpandable={false}
              role="alert"
            />
          )}
        </div>
      </form>
    </ContainerFixed>
  );
};
