import { Breakpoint, Typography } from '@altibox/design-system-component-lib';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceType, FetchStatus } from 'app/store/root-types';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { fetchFixedWirelessAccessStatus } from 'app/store/actions/network-thunks';

import { DeviceImage } from 'app/features/device-image/device-image';
import { Arrow, FwaIcon, FwaStatusOffline, FwaStatusOnline, InfoCircle } from 'app/media';
import { Spinner } from 'app/components/spinner';
import { equipmentGetter } from 'app/utils/device-utils';

import styles from './fixed-wireless-access.module.scss';

interface Props {
  customerId: string;
  servicePointId: string;
  networkData: MinesiderBackend.CustomerNetwork;
}

const ConnectionStatus = ({ errorMessage }: { errorMessage?: string }) => {
  const { t } = useTranslation();
  const { fetchStatus, data } = useAppSelector((state) => state.network.fixedWirelessAccess);
  if (fetchStatus === FetchStatus.FULFILLED) {
    return data?.online ? (
      <>
        <FwaStatusOnline />

        <Typography component="p" variant="uiText1" maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.network.advanced.fixedWirelessAccess.fwaConnect')}
        </Typography>
      </>
    ) : (
      <>
        <FwaStatusOffline />
        <Typography component="p" variant="uiText1" maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.network.advanced.fixedWirelessAccess.fwaNotConnect')}
        </Typography>
      </>
    );
  }
  if (fetchStatus === FetchStatus.REJECTED) {
    return (
      <>
        <div className={styles.statusInformationIcon}>
          <InfoCircle variant="outlined" />
        </div>
        <Typography component="p" variant="uiText3">
          {errorMessage}
        </Typography>
      </>
    );
  }
  return (
    <>
      <Spinner className={styles.miniSpinner} />
      <Typography component="p" variant="uiText3">
        {t('pages.network.advanced.fixedWirelessAccess.loadingMessage')}
      </Typography>
    </>
  );
};

export const FixedWirelessAccess = ({ customerId, servicePointId, networkData }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { fetchStatus: fetchStatusFixedWirelessAccess } = useAppSelector((state) => state.network.fixedWirelessAccess);
  const { data: equipmentEnrichment } = useAppSelector((state) => state.enrichment.equipment);
  const fwaEquipment = equipmentGetter(DeviceType.FIXED_WIRELESS_ACCESS_MODEM, networkData);
  const crmEnrichment = equipmentEnrichment?.find((eq) => eq.provSpecificationId === fwaEquipment?.specification);
  const DeviceIcon = () => {
    if (crmEnrichment?.iconUrl) {
      return <img src={crmEnrichment?.iconUrl} alt={crmEnrichment.friendlyName} />;
    }
    return <FwaIcon />;
  };

  useEffect(() => {
    if (fetchStatusFixedWirelessAccess === FetchStatus.NOT_STARTED) {
      dispatch(fetchFixedWirelessAccessStatus({ customerId, servicePointId }));
    }
    if (fetchStatusFixedWirelessAccess === FetchStatus.REJECTED) {
      setErrorMessage(t('pages.network.advanced.fixedWirelessAccess.serviceErrorMessage'));
    }
  }, [servicePointId, customerId, fetchStatusFixedWirelessAccess]);
  return (
    <div>
      <button aria-expanded={`${expanded}`} onClick={() => setExpanded(!expanded)} className={styles.buttonContainer}>
        <div className={styles.container}>
          <div className={styles.wireless}>
            <DeviceIcon />
            <Typography component="h3" variant="uiText1" bold={true} maxBreakpoint={Breakpoint.TABLET}>
              {t('pages.network.advanced.fixedWirelessAccess.WirelessBroadband')}
            </Typography>
          </div>

          <div
            className={styles.status}
            aria-live="polite"
            aria-busy={fetchStatusFixedWirelessAccess === FetchStatus.PENDING}
          >
            <ConnectionStatus errorMessage={errorMessage} />
          </div>
        </div>
        <div>
          <Arrow direction={expanded ? 'up' : 'down'} variant="accordion" className={styles.arrow} />
        </div>
      </button>
      {expanded && fwaEquipment?.model && (
        <DeviceImage
          className={styles.desktopTwoColumns}
          imageContainer={styles.imageContainer}
          imageColumn={styles.imageColumn}
          equipmentImages={crmEnrichment?.images}
          imageDefaultTextIfNoCaption={
            crmEnrichment?.friendlyName ??
            t('pages.network.advanced.fixedWirelessAccess.fwaModel', {
              modelName: fwaEquipment?.specification,
            })
          }
        >
          <div className={styles.textColumn}>
            <Typography component="h3" variant="uiText2" bold={true} className={styles.fwaModelType}>
              {crmEnrichment?.friendlyName}
            </Typography>

            <Typography component="p" variant="paragraph3" className={styles.fwaInfo}>
              {t('pages.network.advanced.fixedWirelessAccess.fwaInfo')}
            </Typography>
            {crmEnrichment?.customerServiceUrl && (
              <div className={styles.buttonRow}>
                <Typography variant="uiText3" component="p" bold={true} maxBreakpoint={Breakpoint.TABLET}>
                  <a href={crmEnrichment.customerServiceUrl} className={styles.moreInfoLink}>
                    {t('pages.network.advanced.fixedWirelessAccess.fwaReadMoreLink')}
                  </a>
                </Typography>
              </div>
            )}
          </div>
        </DeviceImage>
      )}
    </div>
  );
};
