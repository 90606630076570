import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Breakpoint, Typography } from '@altibox/design-system-component-lib';

import { LinkType } from 'app/components/faq/faq-item/faq-item';
import { List, ItemList } from 'app/features/delivery-status-timeline/components/list/list';
import { DeliveryStatus } from 'app/store/root-types';

import styles from './callout-box.module.scss';

interface CalloutItems {
  [index: string]: (string | ItemList)[];
}

interface LinkItems {
  [index: string]: LinkType[];
}

interface Props {
  activeId: number;
  deliveryStatus: DeliveryStatus;
}

export const CalloutBox: React.FC<Props> = ({ activeId, deliveryStatus }) => {
  const { combinedStep, mainStep } = deliveryStatus.step;
  const { t } = useTranslation();

  const calloutItems: CalloutItems = {
    '1': [
      t('pages.deliveryStatus.timeline.step1-1.callout.paragraph1'),
      {
        heading: t('pages.deliveryStatus.timeline.step1-1.callout.list.heading'),
        items: [
          t('pages.deliveryStatus.timeline.step1-1.callout.list.items.item1'),
          t('pages.deliveryStatus.timeline.step1-1.callout.list.items.item2'),
          t('pages.deliveryStatus.timeline.step1-1.callout.list.items.item3'),
        ],
      },
      t('pages.deliveryStatus.timeline.step1-1.callout.paragraph2'),
    ],
    '2': [t('pages.deliveryStatus.timeline.step2-1.callout.paragraph1')],
    '3': [
      t('pages.deliveryStatus.timeline.step3-1.callout.paragraph1'),
      t('pages.deliveryStatus.timeline.step3-1.callout.paragraph2'),
    ],
    '4': [
      t('pages.deliveryStatus.timeline.step4-1.callout.paragraph1'),
      t('pages.deliveryStatus.timeline.step4-1.callout.paragraph2'),
    ],
    '5': [t('pages.deliveryStatus.timeline.step5-1.callout.paragraph1')],
  };

  const linkItems: LinkItems = {
    '3-1': [
      {
        label: t('pages.deliveryStatus.timeline.step3-1.callout.links.selfEffort.label'),
        url: t('pages.deliveryStatus.timeline.step3-1.callout.links.selfEffort.url'),
      },
    ],
  };

  const isList = (value: string | ItemList) => typeof value === 'object' && value !== null;

  return (
    <aside
      id={`step-${activeId}-call-out`}
      className={classNames(styles.calloutBox, styles[`step${mainStep}Background`])}
      aria-labelledby={`step-${activeId}`}
      aria-live="polite"
    >
      {activeId &&
        calloutItems[activeId].map((item: string | ItemList, index: number) =>
          isList(item) ? (
            <List list={item as ItemList} key={index} />
          ) : (
            <Typography
              className={styles.paragraph}
              component="p"
              variant="paragraph2"
              key={index}
              maxBreakpoint={Breakpoint.TABLET}
            >
              {item as string}
            </Typography>
          ),
        )}
      {activeId &&
        linkItems[combinedStep] &&
        linkItems[combinedStep].map((link, idx) => (
          <Typography key={idx} className={styles.linkItem} component="p" variant="paragraph3" renderAsHTML={true}>
            {`<a key=${link.label} href=${link.url}>
                ${link.label}
              </a>`}
          </Typography>
        ))}
    </aside>
  );
};
