import { Typography } from '@altibox/design-system-component-lib';
import { useTranslation } from 'react-i18next';
import { NetworkMenuItem } from '../network-menu-item';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { areBandsIdentical } from 'app/pages/internet/network-common';
import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { useEffect, useState } from 'react';

interface Props {
  networkData: MinesiderBackend.CustomerNetwork;
  wifiIds: string[];
}

export const CombinedBand = ({ networkData, wifiIds }: Props) => {
  const { t } = useTranslation();

  // CSR = Customer Service Representative
  const { isCsr } = useAppSelector((state) => state.auth);

  const { goToPath } = useAppNavigation();
  const [isCombined, setIsCombined] = useState(areBandsIdentical(networkData, networkData.hgw.wifiSettings));

  useEffect(() => {
    setIsCombined(areBandsIdentical(networkData, wifiIds));
  }, [networkData]);

  const canSplitWifiSettings = networkData.wifiSettings.length > 1 && !networkData.hgw.bridge;

  if (!canSplitWifiSettings || isCsr) {
    return null;
  }

  return (
    <NetworkMenuItem
      menuText={t('pages.network.advanced.wifi.bandHeader')}
      actionElement={isCombined ? t('pages.network.advanced.wifi.combined') : t('pages.network.advanced.wifi.combine')}
      helpTextHeader={t('pages.network.advanced.wifi.helpTexts.band.title')}
      onClick={() => goToPath(navigationService.getCombineBandUrl(wifiIds))}
    >
      <Typography variant="paragraph3" component="p">
        {t('pages.network.advanced.wifi.helpTexts.band.paragraph1')}
      </Typography>
    </NetworkMenuItem>
  );
};
