import { Toggle, Typography as T } from '@altibox/design-system-component-lib';
import { useAppDispatch } from 'app/hooks/redux-thunk';
import { setWifiSettings } from 'app/store/actions/network-thunks';
import { useTranslation } from 'react-i18next';
import { areBandsIdentical } from 'app/pages/internet/network-common';
import classNames from 'classnames';

import styles from './access-point-toggle.module.scss';

interface Props {
  id: number;
  name: string;
  toggleIsOn: boolean;
  accessPoint: MinesiderBackend.AccessPoint;
  networkData: MinesiderBackend.CustomerNetwork;
  customerId: string;
}
export const AccessPointToggle = ({ id, name, toggleIsOn, accessPoint, networkData, customerId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const toggleAccessPoint = () => {
    const apWifis = networkData.wifiSettings.filter((wifi) => accessPoint.wifiSettings.includes(wifi.id));

    // If changes have been made directly in prov while bands were identical, the passwords will be out of sync.
    let mustRenameSsid = false;
    if (
      !areBandsIdentical(
        networkData,
        apWifis.map((ap) => ap.id),
      ) &&
      apWifis.every((ap) => ap.ssid === apWifis[0].ssid)
    ) {
      // Make sure the SSID names are unique
      mustRenameSsid = true;
    }

    const newConfig = apWifis?.map((wifi, i) => {
      const { encKey, ...rest } = wifi;
      rest.ssid = mustRenameSsid ? `${rest.ssid}${i}` : rest.ssid;
      rest.enabled = !wifi.enabled;
      return rest;
    });

    dispatch(
      setWifiSettings({
        customerId,
        servicePointId: networkData.servicePointId,
        wifiNetwork: newConfig,
      }),
    );
  };

  const wrapperClasses = classNames(
    styles.toggleWrapper,
    toggleIsOn ? null : styles.disabled,
    accessPoint.inheritFromCpe ? styles.hiddenToggle : null,
  );

  return (
    <div className={wrapperClasses}>
      {accessPoint.inheritFromCpe ? (
        <div className={styles.accessPointNameContainer}>
          <T component="div" variant="headline5" bold={true} className={styles.accessPointName}>
            {name}
          </T>
        </div>
      ) : (
        <Toggle
          id={`accessPoint_${id}`}
          label={name || t('pages.network.advanced.settings.sections.accessPoints.noName')}
          isOn={toggleIsOn}
          textOn={t('pages.network.advanced.settings.on')}
          textOff={t('pages.network.advanced.settings.off')}
          toggle={toggleAccessPoint}
        />
      )}
    </div>
  );
};
