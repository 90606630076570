import { Alert, Breakpoint, Typography } from '@altibox/design-system-component-lib';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';

import { DeviceType, FetchStatus } from 'app/store/root-types';
import { fetchFixedWirelessAccessStatus } from 'app/store/actions/network-thunks';
import { clearFixedWirelessAccess } from 'app/store/actions/network-actions';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';

export const FixedWirelessAccessErrorMessage: ActiveCustomerLocationPage = (props) => {
  const { userContext } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [messageTitle, setMessageTitle] = useState('');

  const customerId = userContext.customerCrmId;
  const servicePointId = userContext.servicePointId;
  const { fetchStatus, data } = useAppSelector((state) => state.network.fixedWirelessAccess);
  const fixedWirelessAccessStatus = data?.online;
  const hasFwa = userContext.equipmentList?.some(
    (equipment) => equipment.category === DeviceType.FIXED_WIRELESS_ACCESS_MODEM,
  );

  useEffect(() => {
    if (hasFwa && customerId && servicePointId && fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchFixedWirelessAccessStatus({ customerId, servicePointId }));
    }
    if (fetchStatus === FetchStatus.REJECTED) {
      setMessageTitle(t('pages.network.advanced.fixedWirelessAccess.alert.errorMessage'));
    }
    if (fetchStatus === FetchStatus.FULFILLED && fixedWirelessAccessStatus === false) {
      setMessageTitle(t('pages.network.advanced.fixedWirelessAccess.alert.errorMessageTitle'));
    }
  }, [servicePointId, customerId, fetchStatus]);

  return messageTitle ? <RenderAlert text={messageTitle} /> : null;
};

interface Props {
  text: string;
}

const RenderAlert = ({ text }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetchStatus } = useAppSelector((state) => state.network.fixedWirelessAccess);
  return (
    <div>
      <Alert
        alertType={'critical'}
        heading={text}
        headingElement="strong"
        isExpandable={false}
        role={'alert'}
        isDismissable={true}
        isInitiallyExpanded={fetchStatus === FetchStatus.REJECTED ? false : true}
        onClose={() => dispatch(clearFixedWirelessAccess())}
      >
        <Typography variant="uiText2" component="div" maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.network.advanced.fixedWirelessAccess.alert.errorMessageText')}
        </Typography>
      </Alert>
    </div>
  );
};
