import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Breakpoint,
  Button,
  ButtonType,
  Input,
  RadioButton,
  Select,
  Typography,
  UiComponentSize,
} from '@altibox/design-system-component-lib';

import { helpText, uiComponentState } from 'app/features/form/form-utils';
import { useChangeHandler } from 'app/features/form/use-change-handler';
import { ErrorMessages } from 'app/features/form';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { emailAddressIsValid } from 'app/utils/validators';

import { Card } from 'app/components/card';
import { Datepicker } from 'app/components/datepicker';
import { IconMessage } from 'app/components/icon-message';
import { PhoneInput } from 'app/components/phone-input';
import { Spinner } from 'app/components/spinner';

import {
  formatCountryCode,
  FormModel,
  isCountryCodeValid,
  isEmpty,
  isFormValid,
  isNameValid,
  isNumber,
  toFormModel,
  toValueModel,
} from './users-form-utils';
import { iso8601ToDayFullMonthAndYear } from 'app/utils/date-utils';

import styles from './user-form.module.scss';

interface Props {
  initialValues: MinesiderBackend.CrmUser;
  heading: string;
  onSubmit: (user: CrmUser) => void;
  canEditRole?: boolean;
  submitButtonName: string;
  isLoading?: boolean;
  error?: string;
  canEditDateOfBirth?: boolean;
}

export type CrmUser = MinesiderBackend.UpdateCrmUser & MinesiderBackend.CreateCrmUser;
const NO_COUNTRY_CODE = '+47';

export const UserForm: React.FC<Props> = (props) => {
  const { initialValues, heading, onSubmit, canEditRole, submitButtonName, isLoading, error, canEditDateOfBirth } =
    props;
  const users = useAppSelector((state) => state.users.users);
  const customerCrmId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.customerCrmId);
  const dateOfBirth = initialValues.dateOfBirth;
  const { t } = useTranslation();
  const [form, setForm] = useState(toFormModel(initialValues));
  const [errorMessage, setErrorMessage] = useState<string>();
  const { goToPath } = useAppNavigation();

  const roleOptions = ['ADMIN', 'USER'].map((role) => ({
    value: role,
    label: t(`features.user.role.DYNAMIC_KEYS.${role}`),
  }));
  const contactMethodOptions = ['EMAIL_MY_MESSAGES', 'SMS', 'TELEPHONE'].map((contactMethod) => ({
    value: contactMethod,
    label: t(`features.user.contactMethod.DYNAMIC_KEYS.${contactMethod}`),
  }));

  const errorRequired = t('pages.userEdit.validation.required');
  const errorRequiredPhoneNumber = t('pages.userEdit.validation.requiredPhoneNumber');
  const errorInvalidPhoneNumber = t('pages.userEdit.validation.invalidPhoneNumber');
  const validateRequired = (value: string, message?: string) => (isEmpty(value) ? message ?? errorRequired : undefined);
  const validateName = (value: string) => {
    if (isEmpty(value)) {
      return errorRequired;
    }
    return isNameValid(value) ? undefined : t('pages.userEdit.validation.invalidName');
  };
  const validateEmail = (value: string) => {
    if (isEmpty(value)) {
      return errorRequired;
    }
    if (!emailAddressIsValid(value)) {
      return t('pages.userEdit.validation.invalidEmail');
    }
    return users?.find((user) => user.email === value && user.id !== initialValues.id)
      ? t('pages.userEdit.validation.duplicateEmail')
      : undefined;
  };
  const validateMobilePhoneNumber = useCallback(
    (value: string) => {
      if (isEmpty(value)) {
        return errorRequired;
      }
      return form.mobileCountryCode.value === NO_COUNTRY_CODE && !isNumber(value) ? errorInvalidPhoneNumber : undefined;
    },
    [form.mobileCountryCode.value, errorRequired, errorInvalidPhoneNumber],
  );
  const validateMobilePhoneCountryCode = (value: string) => {
    if (isEmpty(value) || !isCountryCodeValid(value)) {
      return errorInvalidPhoneNumber;
    }
  };
  const validateHomePhoneNumber = useCallback(
    (value: string) => {
      if (form.preferredContactMethod.value === 'TELEPHONE' && isEmpty(value)) {
        return errorRequiredPhoneNumber;
      }
      return !isEmpty(value) && form.homePhoneCountryCode.value === NO_COUNTRY_CODE && !isNumber(value)
        ? errorInvalidPhoneNumber
        : undefined;
    },
    [
      form.preferredContactMethod.value,
      form.homePhoneCountryCode.value,
      errorRequiredPhoneNumber,
      errorInvalidPhoneNumber,
    ],
  );
  const validateHomePhoneCountryCode = useCallback(
    (value: string) => {
      if (!isEmpty(form.homePhoneNumber.value) && (isEmpty(value) || !isCountryCodeValid(value))) {
        return errorInvalidPhoneNumber;
      }
      return form.preferredContactMethod.value === 'TELEPHONE' && isEmpty(value) ? errorRequiredPhoneNumber : undefined;
    },
    [form.homePhoneNumber.value, form.preferredContactMethod.value, errorRequiredPhoneNumber, errorInvalidPhoneNumber],
  );

  const handleCancel = () => goToPath(navigationService.PAGES.users.url);
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setErrorMessage(undefined);

    const validatedForm: FormModel = {
      email: { ...form.email, isTouched: true, error: validateEmail(form.email.value) },
      firstName: { ...form.firstName, isTouched: true, error: validateName(form.firstName.value) },
      lastName: { ...form.lastName, isTouched: true, error: validateName(form.lastName.value) },
      dateOfBirth: {
        year: {
          ...form.dateOfBirth.year,
          isTouched: true,
          error: validateRequired(form.dateOfBirth.year.value, t('pages.userEdit.validation.invalidYear')),
        },
        month: {
          ...form.dateOfBirth.month,
          isTouched: true,
          error: validateRequired(form.dateOfBirth.month.value, t('pages.userEdit.validation.invalidMonth')),
        },
        day: {
          ...form.dateOfBirth.day,
          isTouched: true,
          error: validateRequired(form.dateOfBirth.day.value, t('pages.userEdit.validation.invalidDay')),
        },
      },
      role: { ...form.role, isTouched: true, error: validateRequired(form.role.value) },
      mobileCountryCode: {
        value: formatCountryCode(form.mobileCountryCode.value),
        isTouched: true,
        error: validateMobilePhoneCountryCode(form.mobileCountryCode.value),
      },
      mobilePhoneNumber: {
        ...form.mobilePhoneNumber,
        isTouched: true,
        error: validateMobilePhoneNumber(form.mobilePhoneNumber.value),
      },
      homePhoneCountryCode: {
        value: formatCountryCode(form.homePhoneCountryCode.value),
        isTouched: true,
        error: validateHomePhoneCountryCode(form.homePhoneCountryCode.value),
      },
      homePhoneNumber: {
        ...form.homePhoneNumber,
        isTouched: true,
        error: validateHomePhoneNumber(form.homePhoneNumber.value),
      },
      preferredContactMethod: {
        ...form.preferredContactMethod,
        isTouched: true,
        error: validateRequired(form.preferredContactMethod.value),
      },
    };

    setForm(validatedForm);
    if (isFormValid(validatedForm)) {
      onSubmit(toValueModel(customerCrmId!, form));
    } else {
      setErrorMessage(t('pages.userEdit.validation.invalidForm'));
    }
  };
  const handleChange = useChangeHandler<string, FormModel>(setForm);

  const firstNameChanged = (e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ firstName: f }));
  const firstNameBlur = (e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ firstName: f }), validateName);
  const lastNameChanged = (e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ lastName: f }));
  const lastNameBlur = (e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ lastName: f }), validateName);
  const handleDatePickerChange = useCallback(
    (dateValue: string | null) => {
      if (!dateValue) {
        return;
      }
      const split = dateValue.split('.');
      const day = split[0];
      const month = split[1];
      const year = split[2];
      if (form.dateOfBirth.day.value !== day) {
        handleChange(day, (f) => ({ dateOfBirth: { ...form.dateOfBirth, day: f } }));
      }
      if (form.dateOfBirth.month.value !== month) {
        handleChange(month, (f) => ({ dateOfBirth: { ...form.dateOfBirth, month: f } }));
      }
      if (form.dateOfBirth.year.value !== year) {
        handleChange(year, (f) => ({ dateOfBirth: { ...form.dateOfBirth, year: f } }));
      }
    },
    [form.dateOfBirth],
  );
  const handleDayBlur = (day: string) =>
    handleChange(
      day,
      (f) => ({ dateOfBirth: { ...form.dateOfBirth, day: f } }),
      (v) => validateRequired(v, t('pages.userEdit.validation.invalidDay')),
    );
  const handleMonthBlur = (month: string) =>
    handleChange(
      month,
      (f) => ({ dateOfBirth: { ...form.dateOfBirth, month: f } }),
      (v) => validateRequired(v, t('pages.userEdit.validation.invalidMonth')),
    );
  const handleYearBlur = (year: string) =>
    handleChange(
      year,
      (f) => ({ dateOfBirth: { ...form.dateOfBirth, year: f } }),
      (v) => (v.length < 4 ? t('pages.userEdit.validation.invalidYear') : undefined),
    );
  const roleChanged = (e: ChangeEvent<HTMLSelectElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ role: f }), validateRequired);
  const emailChanged = (e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ email: f }));
  const emailBlur = (e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ email: f }), validateEmail);
  const mobileNumberChanged = (code: string, phone: string) => {
    handleChange(code, (f) => ({ mobileCountryCode: f }));
    handleChange(phone, (f) => ({ mobilePhoneNumber: f }));
  };
  const mobileNumberBlur = (code: string, phone: string) => {
    handleChange(formatCountryCode(code), (f) => ({ mobileCountryCode: f }), validateMobilePhoneCountryCode);
    handleChange(phone, (f) => ({ mobilePhoneNumber: f }), validateMobilePhoneNumber);
  };
  const homePhoneNumberChanged = (code: string, phone: string) => {
    handleChange(code, (f) => ({ homePhoneCountryCode: f }));
    handleChange(phone, (f) => ({ homePhoneNumber: f }));
  };
  const homePhoneNumberBlur = (code: string, phone: string) => {
    handleChange(formatCountryCode(code), (f) => ({ homePhoneCountryCode: f }), validateHomePhoneCountryCode);
    handleChange(phone, (f) => ({ homePhoneNumber: f }), validateHomePhoneNumber);
  };
  const contactMethodChanged = (value: string) => handleChange(value, (f) => ({ preferredContactMethod: f }));

  useEffect(() => {
    setErrorMessage(error);
  }, [error, setErrorMessage]);

  useEffect(() => {
    setForm((f) => ({
      ...f,
      homePhoneCountryCode: {
        ...f.homePhoneCountryCode,
        error: validateHomePhoneCountryCode(f.homePhoneCountryCode.value),
      },
      homePhoneNumber: { ...f.homePhoneNumber, error: validateHomePhoneNumber(f.homePhoneNumber.value) },
    }));
  }, [validateHomePhoneCountryCode, validateHomePhoneNumber, setForm]);

  useEffect(() => {
    setForm((f) => ({
      ...f,
      mobilePhoneNumber: { ...f.mobilePhoneNumber, error: validateMobilePhoneNumber(f.mobilePhoneNumber.value) },
    }));
  }, [validateMobilePhoneNumber, setForm]);

  return (
    <form className={styles.userForm}>
      <Card header={heading} headingLevel="h1">
        <div className={styles.fieldSection}>
          {initialValues.role !== 'PRIMARY' ? (
            <div className={styles.fieldRow}>
              <Input
                id="firstName"
                label={t('pages.userEdit.fields.firstName.name')}
                value={form.firstName.value}
                helpText={helpText(form.firstName)}
                uiComponentState={uiComponentState(form.firstName)}
                onChange={firstNameChanged}
                onBlur={firstNameBlur}
                className={styles.firstName}
              />
              <Input
                id="lastName"
                label={t('pages.userEdit.fields.lastName.name')}
                value={form.lastName.value}
                helpText={helpText(form.lastName)}
                uiComponentState={uiComponentState(form.lastName)}
                onChange={lastNameChanged}
                onBlur={lastNameBlur}
                className={styles.lastName}
              />
            </div>
          ) : (
            <div>
              <Typography
                variant="uiText2"
                component="label"
                bold={true}
                maxBreakpoint={Breakpoint.TABLET}
                className={styles.fullName}
              >
                {t('pages.userEdit.fields.fullName.name')}
              </Typography>
              <Typography data-hj-suppress={true} component="div" variant="uiText2" maxBreakpoint={Breakpoint.TABLET}>
                {initialValues.firstName} {initialValues.lastName}
              </Typography>

              <IconMessage
                status="info"
                className={styles.iconMessage}
                text={t('pages.userEdit.fields.fullName.hint')}
              />
            </div>
          )}
          <div>
            {canEditDateOfBirth ? (
              <div className={styles.dateOfBirth}>
                <div className={styles.datepicker}>
                  <Datepicker
                    legend={t('pages.userEdit.fields.dateOfBirth.name')}
                    labels={{
                      day: t('pages.userEdit.fields.dateOfBirth.day'),
                      year: t('pages.userEdit.fields.dateOfBirth.year'),
                      month: t('pages.userEdit.fields.dateOfBirth.month'),
                    }}
                    errorMsgs={{ day: '', year: '', month: '' }}
                    placeholders={{ day: '', year: '', month: '' }}
                    dateValue={handleDatePickerChange}
                    onDayBlur={handleDayBlur}
                    onMonthBlur={handleMonthBlur}
                    onYearBlur={handleYearBlur}
                    defaultDateValue={{
                      year: form.dateOfBirth.year.value ?? '',
                      month: form.dateOfBirth.month.value ?? '',
                      day: form.dateOfBirth.day.value ?? '',
                    }}
                  />
                </div>
                <ErrorMessages
                  className={styles.errorMessages}
                  fields={[form.dateOfBirth.day, form.dateOfBirth.month, form.dateOfBirth.year]}
                />
              </div>
            ) : (
              <>
                {dateOfBirth && (
                  <div className={styles.dateOfBirth}>
                    <Typography variant="uiText2" component="label" bold={true} maxBreakpoint={Breakpoint.TABLET}>
                      {t('pages.userEdit.dateOfBirth')}
                    </Typography>
                    <Typography component="div" variant="uiText2" maxBreakpoint={Breakpoint.TABLET}>
                      <div className={styles.datepicker}>{iso8601ToDayFullMonthAndYear(dateOfBirth)} </div>
                    </Typography>
                  </div>
                )}
              </>
            )}
            <div className={styles.roleInfo}>
              {canEditRole ? (
                <Select
                  id="role"
                  label={t('pages.userEdit.fields.role.name')}
                  options={roleOptions}
                  value={form.role.value}
                  onChange={roleChanged}
                />
              ) : (
                <>
                  <Typography variant="uiText2" component="label" bold={true} maxBreakpoint={Breakpoint.TABLET}>
                    {t('pages.userEdit.fields.role.name')}
                  </Typography>
                  <Typography variant="uiText2" component="div" maxBreakpoint={Breakpoint.TABLET}>
                    {t(`features.user.role.DYNAMIC_KEYS.${initialValues.role}`)}
                  </Typography>
                </>
              )}
              <IconMessage
                status="info"
                text={t('pages.userEdit.fields.role.hint')}
                textVariant="uiText3"
                className={styles.iconMessage}
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldSection}>
          <Input
            id="email"
            label={t('pages.userEdit.fields.email.name')}
            value={form.email.value}
            helpText={helpText(form.email)}
            uiComponentState={uiComponentState(form.email)}
            onChange={emailChanged}
            onBlur={emailBlur}
          />
          <div className={styles.fieldRow}>
            <PhoneInput
              id="mobilePhoneNumber"
              label={t('pages.userEdit.fields.mobilePhoneNumber.name')}
              countryCodeLabel={t('pages.userEdit.fields.mobileCountryCode.name')}
              code={form.mobileCountryCode.value}
              phoneNumber={form.mobilePhoneNumber.value}
              helpText={helpText(form.mobilePhoneNumber) ?? helpText(form.mobileCountryCode)}
              uiComponentState={uiComponentState(form.mobilePhoneNumber) ?? uiComponentState(form.mobileCountryCode)}
              handleChange={mobileNumberChanged}
              handleBlur={mobileNumberBlur}
              className={styles.phoneInput}
            />
            <PhoneInput
              id="homePhoneNumber"
              label={t('pages.userEdit.fields.homePhoneNumber.name')}
              countryCodeLabel={t('pages.userEdit.fields.homePhoneCountryCode.name')}
              code={form.homePhoneCountryCode.value}
              phoneNumber={form.homePhoneNumber.value}
              helpText={helpText(form.homePhoneNumber) ?? helpText(form.homePhoneCountryCode)}
              uiComponentState={uiComponentState(form.homePhoneNumber) ?? uiComponentState(form.homePhoneCountryCode)}
              handleChange={homePhoneNumberChanged}
              handleBlur={homePhoneNumberBlur}
              className={styles.phoneInput}
            />
          </div>
        </div>
        <div className={styles.fieldSection}>
          <RadioButton
            groupname="contactMethod"
            legend={t('pages.userEdit.fields.contactMethod.name')}
            options={contactMethodOptions}
            selectedValue={form.preferredContactMethod.value}
            getSelectedValue={contactMethodChanged}
            uiComponentState={uiComponentState(form.preferredContactMethod)}
            helpText={helpText(form.preferredContactMethod)}
            className={styles.contactMethod}
          />
        </div>
      </Card>
      {errorMessage && (
        <Alert
          alertType="warning"
          heading={errorMessage}
          headingElement="strong"
          isExpandable={false}
          role="alert"
          className={styles.alert}
        />
      )}
      <div className={styles.buttonBar}>
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            <Button
              buttonType={ButtonType.SECONDARY}
              onClick={handleCancel}
              type="button"
              uiComponentSize={UiComponentSize.LARGE}
            >
              {t('pages.userEdit.buttons.cancel.name')}
            </Button>
            <Button
              buttonType={ButtonType.PRIMARY_B}
              onClick={handleSubmit}
              type="submit"
              uiComponentSize={UiComponentSize.LARGE}
            >
              {submitButtonName}
            </Button>
          </>
        )}
      </div>
    </form>
  );
};
