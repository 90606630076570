import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Breakpoint, Col, Grid, GridWidth, Typography } from '@altibox/design-system-component-lib';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { getStreamingServices } from 'app/store/actions/streaming-service-actions';
import { navigationService } from 'app/service/navigation/navigation-service';
import { Navigation } from 'app/features/navigation';
import { Page } from 'app/service/navigation/pages/page-types';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { Spinner } from 'app/components/spinner';
import { Chromecast, RemoteControl } from 'app/media';
import { STREAMING_SERVICE_WHITELIST, checkCombinedServices } from './streaming-utils';

import styles from './streaming-services.module.scss';

export const StreamingServices: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const siteId = props.userContext.siteId;
  const customerLocation = props.userContext;
  const [pages, setPages] = useState<Page[]>([]);
  const { streamingServicesFetchStatus, streamingServices } = useAppSelector((state) => state.streaming);
  const personalizedSections = navigationService.getPersonalizedLinkSections([{ pages }], customerLocation);

  useEffect(() => {
    if (streamingServicesFetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(getStreamingServices(siteId));
    }
  }, [streamingServicesFetchStatus]);

  useEffect(() => {
    if (!streamingServices) {
      return;
    }

    const navigationPages = streamingServices
      .filter((service) => STREAMING_SERVICE_WHITELIST.find((x) => x === service.portalId))
      .sort((serviceA, serviceB) => {
        if (serviceA.priority && serviceB.priority) {
          return serviceA.priority - serviceB.priority;
        } else if (serviceA.priority) {
          return -1;
        } else if (serviceB.priority) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((service) => ({
        url: navigationService.getConcreteStreamingUrl(service.portalId || ''),
        i18n: `pages.streamingService.DYNAMIC_KEYS.${checkCombinedServices(service.portalId)}.name`,
      }));

    setPages(navigationPages);
  }, [streamingServices]);

  return (
    <Grid width={GridWidth.NARROW}>
      <Col sm={2} md={6} lg={12}>
        <Typography
          component="h1"
          variant="headline5"
          maxBreakpoint={Breakpoint.TABLET}
          className={styles.streamingHeader}
        >
          <Chromecast className={styles.chromecast} />
          {t('pages.streamingServices.name')}
        </Typography>
        {streamingServicesFetchStatus === FetchStatus.PENDING && <Spinner />}
        {streamingServicesFetchStatus === FetchStatus.REJECTED && (
          <Alert
            heading={t('pages.streamingServices.getStreamingServicesFailed')}
            headingElement="strong"
            alertType="warning"
            role="alert"
            isExpandable={false}
          />
        )}
        {streamingServicesFetchStatus === FetchStatus.FULFILLED && (
          <>
            {pages.length > 0 && (
              <div>
                {personalizedSections.map((section) => (
                  <Navigation section={section} key={`key-streaming-${section.title}`} />
                ))}
              </div>
            )}
            <div className={styles.noActiveStreamingServices}>
              <RemoteControl className={styles.remoteControl} />
              <Typography className={styles.paragraph} component="p" variant="uiText2">
                {t('pages.streamingServices.noStreamingService')}
              </Typography>
              <a className={styles.buttonLink} href={`${navigationService.PAGES.foc.url}`}>
                {t('pages.streamingServices.goToFreedomOfChoice')}
              </a>
            </div>
          </>
        )}
      </Col>
    </Grid>
  );
};
