import { useTranslation } from 'react-i18next';
import { Typography } from '@altibox/design-system-component-lib';
import { NavLink } from 'react-router-dom';

import { navigationService } from 'app/service/navigation/navigation-service';
import { RelocationStatus } from 'app/store/slices/relocations-slice';
import { Spinner } from 'app/components/spinner';
import { ReactComponent as SubmitSuccess } from 'app/media/icons/success.svg';
import { ReactComponent as SubmitFailure } from 'app/media/icons/failure.svg';
import { Arrow } from 'app/media';
import { useAppSelector } from 'app/hooks/redux-thunk';

import styles from './relocation-receipt.module.scss';

export const RelocationReceipt = () => {
  const { t } = useTranslation();
  const relocationStatus = useAppSelector((state) => state.relocation.status);

  const getJsx = (status: RelocationStatus) => {
    switch (status) {
      case RelocationStatus.SUBMITTING:
        return <Spinner />;
      case RelocationStatus.SUCCESS:
        return (
          <>
            <SubmitSuccess className={styles.submitIcon} />
            <Typography className={styles.heading} variant="headline5" component="h2">
              {t('pages.relocationForm.receiptStep.headingSuccess')}
            </Typography>
            <Typography component="p" variant="paragraph2" className={styles.ingress}>
              {t('pages.relocationForm.receiptStep.ingress')}
            </Typography>

            <NavLink to={navigationService.PAGES.home.url} className={styles.toFrontPageLink}>
              <Arrow direction="right" variant="long" className={styles.arrow} />
              <Typography component="span" variant="uiText2" className={styles.linkText} bold={true}>
                {t('pages.relocationForm.receiptStep.backLink')}
              </Typography>
            </NavLink>
          </>
        );

      case RelocationStatus.FAILURE:
        return (
          <>
            <SubmitFailure className={styles.submitIcon} />
            <Typography className={styles.heading} variant="headline5" component="h2">
              {t('pages.relocationForm.receiptStep.headingFailure')}
            </Typography>
          </>
        );
      default:
        return <p>Unexpected RelocationStatus: {status}</p>;
    }
  };

  return <div className={styles.relocationReceiptContainer}>{getJsx(relocationStatus)}</div>;
};
