import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Typography } from '@altibox/design-system-component-lib';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { Spinner } from 'app/components/spinner';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { internetSettingsTabList, useAppNavigation } from 'app/utils/navigation-utils';
import { CombinedBand } from '../network-menu-item/combined-band/combined-band';
import { SSID } from '../ssid/ssid';
import { useLoadNetworkSettings } from 'app/hooks/use-network';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { ContainerFixed } from 'app/components/container';
import { HiddenH1 } from 'app/components/hidden-h1';
import { navigationService } from 'app/service/navigation/navigation-service';
import { NavigationLink } from 'app/components/navigation-link';
import { Pages } from 'app/service/navigation/pages';

import styles from './wifi-advanced.module.scss';

export const WifiAdvanced: ActiveCustomerLocationPage = (props) => {
  const customerId = props.userContext.customerCrmId;
  const servicePointId = props.userContext.servicePointId;
  const { t } = useTranslation();

  const { data: networkData, fetchStatus } = useAppSelector((state) => state.network);
  const tabList = internetSettingsTabList({ userContext: props.userContext, networkData });
  const { goToPath } = useAppNavigation();
  const [wifiSettingsList, setWifiSettingsList] = useState<string[]>();
  const [deviceName, setDeviceName] = useState<string>();
  const { wifiSettingId } = useParams();

  useLoadNetworkSettings({ servicePointId, customerId });

  useEffect(() => {
    let foundConfig = false;
    if (!wifiSettingId) {
      return;
    }

    if (networkData?.hgw.wifiSettings.includes(wifiSettingId)) {
      // Don't display device name if it's the only device with wifi to configure
      setDeviceName(t('pages.network.ispDevices.hgw'));
      setWifiSettingsList(networkData.hgw.wifiSettings);
      foundConfig = true;
    }

    networkData?.accessPoints.forEach((ap) => {
      if (!foundConfig && ap.wifiSettings.includes(wifiSettingId)) {
        setDeviceName(ap.name || ap.macAddress);
        setWifiSettingsList(ap.wifiSettings);
        foundConfig = true;
      }
    });
    if (fetchStatus === FetchStatus.FULFILLED && !foundConfig) {
      goToPath(Pages.internetSettingsOverview.url);
    }
  }, [fetchStatus]);

  if (fetchStatus === FetchStatus.REJECTED) {
    return (
      <Alert
        alertType="critical"
        heading={t('pages.network.advanced.wifi.errors.unknown')}
        headingElement="h2"
        isExpandable={false}
      />
    );
  }

  if (fetchStatus === FetchStatus.PENDING || !networkData || !wifiSettingsList) {
    return <Spinner />;
  }

  const { wifiSettings: wifiIds, regexValidations, fieldValues } = networkData.hgw;

  return (
    <ContainerFixed isNarrow={false}>
      <HiddenH1>{t('pages.network.advanced.name')}</HiddenH1>

      <TabbedContent selectedTabKey={'overview'} tabList={tabList}>
        <div className={styles.container}>
          <Typography component="h2" variant="headline5" className={styles.headline}>
            {t('pages.network.advanced.wifi.advanced.title', {
              deviceName,
              count: networkData.accessPoints.length + 1,
            })}
          </Typography>
          <CombinedBand networkData={networkData} wifiIds={wifiIds} />
          <SSID
            uiHideBasic={true}
            uiShowAdvanced={true}
            servicePointId={servicePointId}
            customerId={customerId}
            wifiIds={wifiSettingsList}
            regexValidations={regexValidations}
            fieldValues={fieldValues}
          />
        </div>
        <NavigationLink
          to={navigationService.PAGES.internetSettingsOverview.url}
          direction="left"
          text={t('pages.network.advanced.links.backToWifiSettings')}
        />
      </TabbedContent>
    </ContainerFixed>
  );
};
