import { useCallback, useEffect } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { fetchNetworkSettings } from 'app/store/actions/network-thunks';
import { updateFirewallRule } from 'app/store/actions/firewall-thunks';

import { navigationService } from 'app/service/navigation/navigation-service';
import { ContainerFixed } from 'app/components/container';
import { FirewallRuleForm } from './firewall-rule-form/firewall-rule-form';
import { Spinner } from 'app/components/spinner';
import { internetSettingsTabList, useAppNavigation } from 'app/utils/navigation-utils';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { HiddenH1 } from 'app/components/hidden-h1';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';

export const UpdateFirewallRulePage: ActiveCustomerLocationPage = (props) => {
  const dispatch = useAppDispatch();
  const { goToPath } = useAppNavigation();

  const customerId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.customerCrmId);
  const servicePointId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.servicePointId);
  const { ruleId: ruleIdString } = useParams();
  const ruleId = Number.parseInt(ruleIdString || '0', 10);
  const { fetchStatus: networkFetchStatus, data: networkData } = useAppSelector((state) => state.network);
  const {
    updateFirewallRule: { fetchStatus: updateStatus },
  } = useAppSelector((state) => state.firewall);
  const rule = (networkData?.networkSettings?.portForwardings || []).find((r) => r.id === ruleId);
  const tabList = internetSettingsTabList({ userContext: props.userContext, networkData });

  if (!servicePointId || !customerId) {
    return <Spinner />;
  }

  // replacing the "Port forwarding" tab to make it target the "update rule" page.
  tabList.portForwarding = {
    i18n: 'features.tabbedContent.internetSettings.portForwarding',
    page: navigationService.PAGES.firewallUpdateRule,
    url: navigationService.PAGES.firewallUpdateRule.url.replace(':ruleId', ruleIdString!),
  };

  useEffect(() => {
    if (networkFetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchNetworkSettings({ servicePointId, customerId }));
    }
  }, [networkFetchStatus, servicePointId, customerId]);

  const handleSubmit = useCallback(
    (values: MinesiderBackend.UpdatePortForwarding) => {
      dispatch(
        updateFirewallRule({
          customerId,
          servicePointId,
          ruleId,
          rule: values,
        }),
      ).then(() => goToPath(navigationService.PAGES.internetSettingsPortForwarding.url));
    },
    [dispatch, customerId, servicePointId, ruleId],
  );

  const handleCancel = () => goToPath(navigationService.PAGES.internetSettingsPortForwarding.url);

  return (
    <ContainerFixed isNarrow={false}>
      <HiddenH1>{t('pages.network.advanced.name').toString()}</HiddenH1>
      <TabbedContent selectedTabKey={'portForwarding'} tabList={tabList}>
        <>
          {(networkFetchStatus === FetchStatus.NOT_STARTED || networkFetchStatus === FetchStatus.PENDING) && (
            <Spinner />
          )}
          {networkData && (
            <FirewallRuleForm
              title={t('pages.firewall.buttons.updateFirewallRule')}
              initialValues={rule}
              customerNetwork={networkData}
              fetchStatus={updateStatus}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              button={t('pages.firewall.buttons.submitUpdateRule')}
            />
          )}
        </>
      </TabbedContent>
    </ContainerFixed>
  );
};
