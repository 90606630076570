import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Breakpoint, Input, Typography } from '@altibox/design-system-component-lib';

import { ErrorMessages } from 'app/features/form/error-messages';
import { Field, uiComponentState } from 'app/features/form/form-utils';
import { Card } from 'app/components/card';
import { FormModel } from '../firewall-setting-utils';
import { IpAddressWithSeparator } from 'app/pages/internet/network-common';
import { InfoCircle } from 'app/media';

import styles from './firewall-rule-form.module.scss';

interface Props {
  network: MinesiderBackend.CustomerNetwork;
  form: FormModel;
  handleChangeString: (
    value: string,
    field: (f: Field<string>) => Partial<FormModel>,
    validator?: (value: string) => string | undefined,
  ) => void;
  validateIp: (value: string) => string | undefined;
  ipAddressArray: string[];
}

export const LanCard: React.FC<Props> = (props) => {
  const { form, handleChangeString, validateIp, ipAddressArray } = props;
  const { t } = useTranslation();
  const lanIpAddressLastPartChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChangeString(e.currentTarget.value, (f) => ({ intIpLastOctet: f }), validateIp);

  return (
    <Card className={styles.card}>
      <div>
        <Typography
          variant="uiText1"
          component="h2"
          maxBreakpoint={Breakpoint.TABLET}
          bold={true}
          className={styles.ipAddressHeading}
        >
          {t('pages.firewall.createNewFirewallRule.ipAddress')}
        </Typography>
        <div className={styles.ipAddress}>
          <IpAddressWithSeparator ipAddressArray={ipAddressArray} numbersToShow={3} />
          <Input
            id="ipAddressLastPart"
            label={t('pages.firewall.createNewFirewallRule.ipAddress')}
            hideLabel={true}
            value={form.intIpLastOctet.value}
            onChange={lanIpAddressLastPartChanged}
            uiComponentState={uiComponentState(form.intIpLastOctet)}
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.smallInputField}
            maxLength={3}
          />
        </div>
        <ErrorMessages fields={[form.intIpLastOctet]} className={styles.errorName} />
        <div className={styles.helpTextContainer}>
          <div className={styles.iconContainer}>
            <InfoCircle
              variant="filled"
              role="graphics-symbol"
              title={t('pages.firewall.explainMessages.explainIpAddress')}
              className={styles.infoIcon}
            />
          </div>
          <Typography variant="uiText3" component="span" maxBreakpoint={Breakpoint.TABLET} id="IpHelpText">
            <Trans i18nKey="pages.firewall.explainMessages.explainIpAddress" />
          </Typography>
        </div>
      </div>
    </Card>
  );
};
