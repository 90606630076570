// https://github.com/i18next/i18next-browser-languageDetector#adding-own-detection-functionality

export const customLanguageDetector = {
  name: 'customLanguageDetector',
  lookup() {
    return browserLanguageDetection() || topLevelDomainDetection();
  },
};

const getSupportedLanguage = (language: string) => {
  switch (language) {
    case 'nb':
    case 'no':
      return 'nb';
    case 'nn':
      return 'nn';
    case 'da':
      return 'da';
    default:
      return null;
  }
};
const browserLanguageDetection = () => {
  let selectedLanguage = '';
  try {
    navigator.languages.forEach((language) => {
      if (!selectedLanguage) {
        selectedLanguage = getSupportedLanguage(language) || '';
      }
    });
  } catch {
    return null;
  }
  return selectedLanguage;
};

export const topLevelDomainDetection = () => {
  const toplevel = /\w{2,10}$/.exec(window.location.hostname)?.[0] || 'no';
  switch (toplevel) {
    case 'no':
      return 'nb';
    case 'dk':
      return 'da';
    default:
      return 'nb';
  }
};
