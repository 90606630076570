import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Breakpoint,
  Button,
  ButtonType,
  Input,
  Typography,
  UiComponentState,
} from '@altibox/design-system-component-lib';

import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';
import { useAnnounceNavigation } from 'app/hooks';
import { FetchStatus, PinLifecycleState } from 'app/store/root-types';
import { getPin, setPin } from 'app/store/actions/pin-thunks';
import { pinChanged, setCurrentPinState } from 'app/store/actions/pin-actions';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';

import styles from './change-pin.module.scss';

export const ChangePin = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fetchStatus = useAppSelector((state) => state.pin.fetchStatus);
  const customerId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.customerCrmId);
  const pinData = useAppSelector((state) => state.pin.data);
  const { pin, currentState } = useAppSelector((state) => state.pin);
  const [hidden, setHidden] = useState(true);
  const { announceElement } = useAnnounceNavigation();

  const inputComponentState = currentState === PinLifecycleState.ERROR ? UiComponentState.ERROR : undefined;
  useEffect(() => {
    if (customerId) {
      dispatch(getPin(customerId));
    }
  }, []);

  const handlePinChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(pinChanged(event.currentTarget.value.replace(/\D/g, '')));
  };

  const toggleHidden = (): void => {
    setHidden(!hidden);
  };
  const changePinForm = () => (
    <div className={styles.changePinFormContainer}>
      {currentState === PinLifecycleState.SAVING ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.inputContainer}>
            <Input
              id="changePinInput"
              label={t('pages.changePin.inputLabel')}
              helpText={t('pages.changePin.inputHelpText')}
              maxBreakpoint={Breakpoint.TABLET}
              isPassword={true}
              onToggleHidden={toggleHidden}
              isHidden={hidden}
              onChange={handlePinChange}
              value={pin}
              maxLength={4}
              autoFocus={true}
              uiComponentState={inputComponentState}
              autoComplete="new-password"
              inputMode="numeric"
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              buttonType={ButtonType.SECONDARY}
              className={styles.cancelButton}
              onClick={handleSetState(PinLifecycleState.INITIAL)}
            >
              {t('pages.changePin.buttonCancel')}
            </Button>
            <Button buttonType={ButtonType.PRIMARY_B} className={styles.confirmButton} onClick={handleSaveClick}>
              {t('pages.changePin.buttonSave')}
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const handleSetState =
    (newState: PinLifecycleState): (() => void) =>
    () =>
      dispatch(setCurrentPinState(newState));

  const handleSaveClick = () => {
    if (pin && pin.length === 4) {
      const setPinRequest = {
        newPin: pin,
      };
      dispatch(setPin(setPinRequest));
    } else {
      dispatch(setCurrentPinState(PinLifecycleState.ERROR));
    }
  };

  return (
    <ContainerFixed isNarrow={true}>
      <div className={styles.changePinContainer}>
        <>
          {fetchStatus === FetchStatus.PENDING && <Spinner />}
          {fetchStatus === FetchStatus.REJECTED && (
            <Alert
              heading={t(`pages.product.errorMessage.getProductsFailed`)}
              headingElement="strong"
              alertType="warning"
              role="alert"
              isExpandable={false}
            />
          )}
          {fetchStatus === FetchStatus.FULFILLED && (
            <>
              <Typography
                variant="headline5"
                component="h1"
                className={styles.changePinHeadline}
                maxBreakpoint={Breakpoint.TABLET}
                ref={announceElement}
              >
                {currentState === PinLifecycleState.SAVED
                  ? t('pages.changePin.savedTitle')
                  : t('pages.changePin.title')}
              </Typography>
              {pinData?.pin && (
                <Typography
                  variant="uiText1"
                  bold={true}
                  component="p"
                  className={styles.changePinHeadline}
                  maxBreakpoint={Breakpoint.TABLET}
                  ref={announceElement}
                >
                  {t('pages.changePin.showPin', { code: pinData.pin })}
                </Typography>
              )}
              <Typography
                variant="paragraph2"
                component="p"
                className={styles.changePinParagraph}
                maxBreakpoint={Breakpoint.TABLET}
              >
                {t('pages.changePin.body')}
              </Typography>
              {currentState === PinLifecycleState.EDITING ||
              currentState === PinLifecycleState.ERROR ||
              currentState === PinLifecycleState.SAVING ? (
                changePinForm()
              ) : (
                <Button
                  className={styles.confirmButton}
                  onClick={handleSetState(PinLifecycleState.EDITING)}
                  buttonType={ButtonType.PRIMARY_B}
                >
                  {t('pages.changePin.buttonNew')}
                </Button>
              )}
            </>
          )}
        </>
      </div>
    </ContainerFixed>
  );
};
