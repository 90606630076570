import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from '@altibox/design-system-component-lib';

import { navigationService } from 'app/service/navigation/navigation-service';
import { menuTelephone } from 'app/utils/navigation-utils';
import { ActiveCustomerLocationPage } from '../page-wrapper';
import { Navigation } from 'app/features/navigation';

import styles from 'app/pages/menu-page-common.module.scss';

const allLinkSections = [
  {
    pages: menuTelephone,
  },
];

export const TelephonePage: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const customerLocation = props.userContext;
  const personalizedSections = customerLocation
    ? navigationService.getPersonalizedLinkSections(allLinkSections, customerLocation)
    : [];

  return (
    <>
      <Typography
        component="h1"
        variant="headline5"
        maxBreakpoint={Breakpoint.TABLET}
        className={styles.menuPageHeader}
      >
        {t(navigationService.PAGES.telephone.i18n)}
      </Typography>

      <div className={styles.container}>
        {personalizedSections.map((section) => (
          <Navigation section={section} key={`key-telephone-${section.title}`} />
        ))}
      </div>
    </>
  );
};
