import { UiComponentState } from '@altibox/design-system-component-lib';

export interface Field<T> {
  isTouched?: boolean;
  error?: string;
  value: T;
}

export const inputChange = <T>(value: T, validator?: (value: T) => string | undefined): Field<T> => ({
  value,
  isTouched: true,
  error: validator && validator(value),
});

export const helpText = <T>(input?: Field<T>) => (input && input.isTouched ? input.error : undefined);
export const uiComponentState = <T>(input?: Field<T>) =>
  input && input.isTouched && input.error ? UiComponentState.ERROR : undefined;
