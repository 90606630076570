import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Breakpoint, Typography as T } from '@altibox/design-system-component-lib';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { ToastMessage, FetchStatus } from 'app/store/root-types';
import { getEmailAccountResponse } from 'app/store/actions/email-thunks';
import { CustomerPage } from '../page-wrapper';
import { clearEmailForms } from 'app/store/actions/email-actions';

import { Spinner } from 'app/components/spinner';
import { ContainerFixed } from 'app/components/container';
import { EmailAccountsInactive } from 'app/components/email/email-accounts-inactive/email-accounts-inactive';
import { EmailAccountsActive } from 'app/components/email/email-accounts-active/email-accounts-active';
import { EmailInactiveWarning } from 'app/features/email-inactive-warning/email-inactive-warning';

import styles from './email.module.scss';
import commonEmailCss from './email-common.module.scss';

export const Email: CustomerPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetchStatus, emailAccounts, toastMessage } = useAppSelector((state) => state.email);
  const [showToastMessage, setShowToastMessage] = useState<ToastMessage | null>(null);

  useEffect(() => {
    dispatch(getEmailAccountResponse());
  }, []);

  useEffect(() => {
    if (toastMessage) {
      setShowToastMessage(toastMessage);
      dispatch(clearEmailForms());
    }
  }, [toastMessage]);

  return (
    <>
      <EmailInactiveWarning className={styles.inactiveWarningBanner} />

      <ContainerFixed isNarrow={true}>
        <div className={commonEmailCss.mobileEdge}>
          {showToastMessage && (
            <div className={styles.toast}>
              <Alert
                alertType={showToastMessage.alertType}
                heading={showToastMessage.heading}
                headingElement="strong"
                isExpandable={false}
                isDismissable={true}
                role={showToastMessage.role}
              />
            </div>
          )}
          <T
            variant="headline4"
            component="h1"
            className={styles.heading}
            // ref={announceElement}
            maxBreakpoint={Breakpoint.TABLET}
          >
            {t('pages.email.name')}
          </T>
          <T
            component="div"
            variant="paragraph2"
            maxBreakpoint={Breakpoint.TABLET}
            renderAsHTML={true}
            className={styles.generalEmailInformation}
          >
            {t('pages.email.generalEmailInformation')}
          </T>
          {fetchStatus === FetchStatus.PENDING && <Spinner />}
          {fetchStatus === FetchStatus.FULFILLED && (
            <>
              <EmailAccountsActive emailAccounts={emailAccounts} />
              <EmailAccountsInactive emailAccounts={emailAccounts} />
            </>
          )}
          {fetchStatus === FetchStatus.REJECTED && (
            <Alert
              alertType="warning"
              heading={t('pages.email.accountsFetchRejected')}
              headingElement="strong"
              isExpandable={false}
              role="alert"
            />
          )}
        </div>
      </ContainerFixed>
    </>
  );
};
