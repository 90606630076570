import { Button, ButtonType, Typography, UiComponentSize } from '@altibox/design-system-component-lib';
import { DeviceImage } from 'app/features/device-image/device-image';
import { useTranslation } from 'react-i18next';
import { Download } from 'app/media';

import styles from './device-list.module.scss';

interface EquipmentInfo {
  deivceName?: string;
  description?: string;
  images?: MinesiderBackend.EquipmentImage[];
  customerServiceUrl?: string;
  userManual?: string;
}
export const DeviceInfo = ({ deivceName, description, images, customerServiceUrl, userManual }: EquipmentInfo) => {
  const { t } = useTranslation();
  return (
    <DeviceImage
      className={styles.desktopTwoColumns}
      imageContainer={styles.imageContainer}
      imageColumn={styles.imageColumn}
      equipmentImages={images}
    >
      <div className={styles.metaContainer}>
        <Typography component="h3" variant="uiText2" bold={true} className={styles.hgwModelType}>
          {deivceName}
        </Typography>
        {description && (
          <Typography component="p" variant="paragraph3" className={styles.hgwInfo}>
            {description}
          </Typography>
        )}
        <div className={styles.linkContainer}>
          {userManual && (
            <Button
              buttonType={ButtonType.SECONDARY}
              type="button"
              className={styles.iconButton}
              uiComponentSize={UiComponentSize.SMALL}
              onClick={() => window.open(userManual)}
            >
              <Typography variant="uiText3" bold={true} component="span" className={styles.link}>
                {t('pages.network.wifiSettings.hgwUserManualDownload')}
              </Typography>
              <div className={styles.icon}>
                <Download />
              </div>
            </Button>
          )}
          {customerServiceUrl && (
            <div className={styles.articleLinkContainer}>
              <a href={customerServiceUrl} className={styles.articleLink}>
                <Typography variant="uiText3" bold={true} component="span" className={styles.link}>
                  {t('pages.network.wifiSettings.hgwIntroReadMore')}
                </Typography>
              </a>
            </div>
          )}
        </div>
      </div>
    </DeviceImage>
  );
};
