import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from '@altibox/design-system-component-lib';
import { LocationPanel } from 'app/components/location-panel/';
import { CrmSystem, CustomerLocationContext } from 'app/store/types/user-context-types';
import { useAppSelector } from 'app/hooks/redux-thunk';

import styles from './location-list.module.scss';
interface Props {
  selectLocation: (customerLocation: CustomerLocationContext) => void;
}

interface PartnerCustomerMap {
  [index: string]: CustomerLocationMap;
}

interface CustomerLocationMap {
  [index: string]: CustomerLocationContext[];
}

const LocationList: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { selectLocation } = props;

  const onSelectLocation =
    (customerLocation: CustomerLocationContext): (() => void) =>
    () => {
      selectLocation(customerLocation);
    };

  const customerLocations = useAppSelector((state) => state.userContext.customerLocations);

  const activeOrNewCustomerLocations = customerLocations.filter(
    (customerLocation) =>
      (customerLocation.status === 'ACTIVE' || customerLocation.status === 'NEW') &&
      customerLocation.crmSystem !== CrmSystem.NONE,
  );

  const inactiveCustomerLocations = customerLocations.filter(
    (customerLocation) => customerLocation.status === 'INACTIVE',
  );

  // If customer is returning but has no active locations, put those in the end
  const customerLocationList = activeOrNewCustomerLocations.concat(inactiveCustomerLocations);

  // Turn the CustomerLocations list into a nested map of partner and customer
  const partnerCustomerLocationsMap = customerLocationList.reduce((cplm, cl): PartnerCustomerMap => {
    cplm[cl.partnerLegalName] = {
      ...cplm[cl.partnerLegalName],
      [cl.customerCrmId]: [],
    };
    return cplm;
  }, {} as PartnerCustomerMap);

  // Populate the map with locations
  customerLocationList.forEach((customerLocation) =>
    partnerCustomerLocationsMap[customerLocation.partnerLegalName][customerLocation.customerCrmId].push(
      customerLocation,
    ),
  );

  // Layout for each location at a partner
  const LocationInfo = (partnerLegalName: string, customerNumber: string) => (
    <ul aria-describedby="locationListHeader" className={styles.locationList}>
      {
        // List locations
        partnerCustomerLocationsMap[partnerLegalName][customerNumber].map((location, idx) => (
          <li key={`${location.customerCrmId}-${location.servicePointId}-${idx}`} className={styles.locationListItem}>
            <LocationPanel location={location} onClick={onSelectLocation(location)} />
          </li>
        ))
      }
    </ul>
  );

  return (
    <div className={styles.container}>
      {Object.keys(partnerCustomerLocationsMap).map((partnerLegalName) => (
        <div key={partnerLegalName} className={styles.partnerContainer}>
          <Typography
            className={styles.partnerTitle}
            variant="headline5"
            component="h2"
            maxBreakpoint={Breakpoint.TABLET}
          >
            {partnerLegalName}
          </Typography>
          {Object.keys(partnerCustomerLocationsMap[partnerLegalName]).map((customerNumber) => (
            <div key={customerNumber} className={styles.customerContainer}>
              <Typography
                className={styles.customerTitle}
                variant="uiText2"
                component="h3"
                bold={true}
                maxBreakpoint={Breakpoint.TABLET}
              >
                {`${t('components.locationList.customerNumber')} ${customerNumber}`}
              </Typography>

              {LocationInfo(partnerLegalName, customerNumber)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export { LocationList };
