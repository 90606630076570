import React, { useEffect, useState } from 'react';
import { Breakpoint, Input, Typography } from '@altibox/design-system-component-lib';
import classNames from 'classnames';

import { addDayMonthLeadingZero } from 'app/utils/string-util';

import styles from './datepicker.module.scss';

enum InputIds {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export interface DateProps {
  [key: string]: string;
  day: string;
  month: string;
  year: string;
}

interface Props {
  legend: string;
  dateValue: (value: string | null) => void;
  labels: DateProps;
  placeholders: DateProps;
  errorMsgs: DateProps;
  customClass?: string;
  defaultDateValue?: DateProps | null;
  maxBreakpoint?: Breakpoint;
  onDayBlur?: (value: string) => void;
  onMonthBlur?: (value: string) => void;
  onYearBlur?: (value: string) => void;
}

export const Datepicker: React.FC<Props> = ({
  legend,
  labels,
  placeholders,
  dateValue,
  errorMsgs,
  defaultDateValue,
  maxBreakpoint,
  onDayBlur,
  onMonthBlur,
  onYearBlur,
}) => {
  const [dateValues, setDateValues] = useState<DateProps>({
    day: defaultDateValue?.day || '',
    month: defaultDateValue?.month || '',
    year: defaultDateValue?.year || '',
  });
  const [inputErrors, setInputErrors] = useState<DateProps>({ day: '', month: '', year: '' });

  // Validation
  const dateValueHasErrors = Object.keys(inputErrors).some((key: string) => inputErrors[key] !== '');

  // Formating
  const removeLetters = (value: string): string => value.replace(/\D/g, '');
  const formatedValue = (value: string, length: number): string => removeLetters(value).substr(0, length);

  const dateBlurHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const formatted = Number(value) === 0 ? '01' : addDayMonthLeadingZero(formatedValue(value, 2));
    switch (id) {
      case InputIds.DAY:
        if (onDayBlur) {
          onDayBlur(value);
        }
        if (value !== '') {
          setDateValues({ ...dateValues, day: formatted });
          setInputErrors({ ...inputErrors, day: '' });
          return;
        }
        setInputErrors({ ...inputErrors, day: errorMsgs.day });
        break;
      case InputIds.MONTH:
        if (onMonthBlur) {
          onMonthBlur(value);
        }
        if (value !== '') {
          setDateValues({ ...dateValues, month: formatted });
          setInputErrors({ ...inputErrors, month: '' });
          return;
        }
        setInputErrors({ ...inputErrors, month: errorMsgs.month });
        break;
      case InputIds.YEAR:
        if (onYearBlur) {
          onYearBlur(value);
        }
        if (value.length !== 4) {
          setInputErrors({ ...inputErrors, year: errorMsgs.year });
          return;
        }
        setDateValues({ ...dateValues, year: value });
        setInputErrors({ ...inputErrors, year: '' });
        break;
    }
  };

  const dayChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const day = Number(event.currentTarget.value) > 31 ? '31' : formatedValue(event.currentTarget.value, 2);
    setDateValues({ ...dateValues, day });
  };
  const monthChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const month = Number(event.currentTarget.value) > 12 ? '12' : formatedValue(event.currentTarget.value, 2);
    setDateValues({ ...dateValues, month });
  };
  const yearChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateValues({ ...dateValues, year: formatedValue(event.currentTarget.value, 4) });
  };

  useEffect(() => {
    if (dateValueHasErrors) {
      dateValue(null);
      return;
    }
    dateValue(`${dateValues.day}.${dateValues.month}.${dateValues.year}`);
  }, [dateValues]);

  return (
    <fieldset className={styles.datepicker}>
      <Typography className={styles.heading} variant="uiText2" component="legend" bold={true}>
        {legend}
      </Typography>

      <div className={styles.inputGroup}>
        <div className={styles.day}>
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            id={InputIds.DAY}
            label={labels.day}
            placeholder={placeholders.day}
            onChange={dayChangeHandler}
            onBlur={dateBlurHandler}
            value={dateValues.day}
            maxBreakpoint={maxBreakpoint}
          />
        </div>
        <div className={styles.month}>
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            id={InputIds.MONTH}
            label={labels.month}
            placeholder={placeholders.month}
            onChange={monthChangeHandler}
            onBlur={dateBlurHandler}
            value={dateValues.month}
            maxBreakpoint={maxBreakpoint}
          />
        </div>
        <div
          className={classNames(
            styles.year,
            !maxBreakpoint || maxBreakpoint === Breakpoint.DESKTOP ? styles.maxBreakpointDesktop : '',
          )}
        >
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            id={InputIds.YEAR}
            label={labels.year}
            placeholder={placeholders.year}
            onChange={yearChangeHandler}
            onBlur={dateBlurHandler}
            value={dateValues.year}
            maxBreakpoint={maxBreakpoint}
          />
        </div>
      </div>
      {dateValueHasErrors && (
        <div className={styles.errors}>
          {[inputErrors.day, inputErrors.month, inputErrors.year]
            .filter((inputError) => inputError)
            .map((errorMessage, index) => (
              <Typography className={styles.error} variant="uiText4" component="p" key={`key-inputerror-${index}`}>
                {errorMessage}
              </Typography>
            ))}
        </div>
      )}
    </fieldset>
  );
};
