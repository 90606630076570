import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { fetchNetworkSettings } from 'app/store/actions/network-thunks';
import { createFirewallRule } from 'app/store/actions/firewall-thunks';
import { internetSettingsTabList, useAppNavigation } from 'app/utils/navigation-utils';
import { navigationService } from 'app/service/navigation/navigation-service';
import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';
import { FirewallRuleForm } from './firewall-rule-form/firewall-rule-form';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { HiddenH1 } from 'app/components/hidden-h1';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';

export const CreateFirewallRulePage: ActiveCustomerLocationPage = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();

  const customerId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.customerCrmId);
  const servicePointId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.servicePointId);
  const { fetchStatus: networkFetchStatus, data: networkData } = useAppSelector((state) => state.network);
  const {
    createFirewallRule: { fetchStatus: createStatus },
  } = useAppSelector((state) => state.firewall);

  if (!servicePointId || !customerId) {
    return <Spinner />;
  }

  const tabList = internetSettingsTabList({ userContext: props.userContext, networkData });

  useEffect(() => {
    if (networkFetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchNetworkSettings({ servicePointId, customerId }));
    }
  }, [networkFetchStatus, servicePointId, customerId]);

  const handleSubmit = (values: MinesiderBackend.UpdatePortForwarding) => {
    dispatch(
      createFirewallRule({
        customerId,
        servicePointId,
        rule: values,
      }),
    ).then(() => goToPath(navigationService.PAGES.internetSettingsPortForwarding.url));
  };
  const handleCancel = () => goToPath(navigationService.PAGES.internetSettingsPortForwarding.url);

  // replacing the "Port forwarding" tab to make it target the "Create rule" page.
  tabList.portForwarding = {
    i18n: 'features.tabbedContent.internetSettings.portForwarding',
    page: navigationService.PAGES.firewallCreateRule,
  };

  return (
    <ContainerFixed isNarrow={false}>
      <HiddenH1>{t('pages.network.advanced.name').toString()}</HiddenH1>
      <TabbedContent selectedTabKey={'portForwarding'} tabList={tabList}>
        <>
          {(networkFetchStatus === FetchStatus.NOT_STARTED || networkFetchStatus === FetchStatus.PENDING) && (
            <Spinner />
          )}
          {networkData && (
            <FirewallRuleForm
              title={t('pages.firewall.createNewFirewallRule.heading')}
              customerNetwork={networkData}
              fetchStatus={createStatus}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              button={t('pages.firewall.buttons.submitNewRule')}
            />
          )}
        </>
      </TabbedContent>
    </ContainerFixed>
  );
};
