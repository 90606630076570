import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@altibox/design-system-component-lib';

import { Arrow } from 'app/media';
import { useMedia } from 'app/hooks';

import { Screen, HeaderLogo } from './header-logo';
import { resolveAltiboxFrontPageDomain, startLogout } from 'app/utils/navigation-utils';

import styles from './header.module.scss';

export const Header: React.FC = () => {
  const { t } = useTranslation();

  const isDesktop = useMedia<Screen>(['(min-width: 768px)'], [Screen.Md], Screen.Sm) >= Screen.Md;

  const altiboxFrontPageDomain = resolveAltiboxFrontPageDomain();
  const altiboxFrontPageUrl = `https://${altiboxFrontPageDomain}`;

  return (
    <header className={styles.header}>
      <a className={styles.mainContentShortcut} href="#content">
        {t('features.header.Hopp til hovedinnholdet')}
      </a>

      <div className={styles.headerInner}>
        {isDesktop && (
          <a href={altiboxFrontPageUrl} className={styles.altiboxLink}>
            <Arrow className={styles.arrow} role="presentation" variant={'long'} direction={'left'} />
            <span className={styles.linkText}>{altiboxFrontPageDomain}</span>
          </a>
        )}
        <HeaderLogo isDesktop={isDesktop} />
        <Button onClick={() => startLogout()} className={styles.logout}>
          {t('features.header.Logg ut')}
        </Button>
      </div>
    </header>
  );
};
