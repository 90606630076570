import React from 'react';
import classNames from 'classnames';
import { Breakpoint, Input, Typography, UiComponentState } from '@altibox/design-system-component-lib';

import { IconMessage } from 'app/components/icon-message';

import styles from './phone-input.module.scss';

interface Props {
  id: string;
  countryCodeLabel: string;
  label?: string;
  description?: string;
  code?: string;
  phoneNumber?: string;
  handleChange?: (code: string, phoneNumber: string) => void;
  handleBlur?: (code: string, phoneNumber: string) => void;
  uiComponentState?: UiComponentState;
  helpText?: string;
  maxBreakpoint?: Breakpoint;
  className?: string;
}

export const PhoneInput: React.FC<Props> = (props) => {
  const {
    id,
    countryCodeLabel,
    label,
    description,
    code,
    phoneNumber,
    handleChange,
    handleBlur,
    uiComponentState,
    helpText,
    maxBreakpoint,
    className,
  } = props;

  const codeInputId = `${id}-code`;
  const phoneNumberInputId = `${id}-phoneNumber`;
  const ids = [codeInputId, phoneNumberInputId];

  const codeAndPhoneClassNames = classNames(
    styles.codeAndPhone,
    uiComponentState === UiComponentState.ERROR ? styles.error : '',
    !maxBreakpoint || maxBreakpoint === Breakpoint.DESKTOP ? styles.maxBreakpointDesktop : '',
  );

  const getIconStatus = () => {
    switch (uiComponentState) {
      case UiComponentState.ERROR:
        return 'error';
      case UiComponentState.SUCCESS:
        return 'success';
      default:
        return 'info';
    }
  };

  return (
    <div className={className}>
      <div className={styles.labelAndDescription}>
        {label && (
          <Typography
            component="label"
            variant="uiText2"
            bold={true}
            maxBreakpoint={Breakpoint.TABLET}
            htmlFor={id + '-phoneNumber'}
          >
            <div className={classNames(styles.label, uiComponentState === UiComponentState.ERROR ? styles.error : '')}>
              {label}
            </div>
          </Typography>
        )}
        {description && (
          <Typography
            component="span"
            variant="uiText2"
            italic={true}
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.description}
          >
            {description}
          </Typography>
        )}
      </div>
      <div className={codeAndPhoneClassNames}>
        <Input
          id={codeInputId}
          label={countryCodeLabel}
          hideLabel={true}
          value={code ?? ''}
          onChange={(e) => handleChange && handleChange(e.currentTarget.value, phoneNumber ?? '')}
          onBlur={(e) => {
            const insidePhoneInput =
              ids.includes(e.target.id) && e.relatedTarget?.id && ids.includes(e.relatedTarget.id);
            if (!insidePhoneInput && handleBlur) {
              handleBlur(e.currentTarget.value, phoneNumber ?? '');
            }
          }}
          maxBreakpoint={maxBreakpoint}
          className={classNames(styles.input, styles.codeInput)}
        />
        <div className={styles.divider} />
        <Input
          id={phoneNumberInputId}
          label=""
          value={phoneNumber ?? ''}
          onChange={(e) => handleChange && handleChange(code ?? '', e.currentTarget.value)}
          onBlur={(e) => {
            const insidePhoneInput =
              ids.includes(e.target.id) && e.relatedTarget?.id && ids.includes(e.relatedTarget.id);
            if (!insidePhoneInput && handleBlur) {
              handleBlur(code ?? '', e.currentTarget.value);
            }
          }}
          maxBreakpoint={maxBreakpoint}
          className={classNames(styles.input, styles.phoneNumberInput)}
        />
      </div>
      {helpText && <IconMessage status={getIconStatus()} text={helpText} />}
    </div>
  );
};
