import { Typography } from '@altibox/design-system-component-lib';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks/redux-thunk';

import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { NetworkMenuItem } from '../network-menu-item';
import { FALLBACK_SELFCARE_HGW_URL } from 'app/pages/internet/network-common';

import styles from '../../wifi-settings.module.scss';

interface Props {
  canToggleBridge: boolean;
}
export const BridgeMode = ({ canToggleBridge }: Props) => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();
  const { data: networkData } = useAppSelector((state) => state.network);
  const { data: equipmentEnrichment } = useAppSelector((state) => state.enrichment.equipment);
  const isOn = networkData?.hgw.bridge;

  if (!canToggleBridge) {
    return null;
  }

  const hgwEnrichment = equipmentEnrichment?.find(
    (equipment) => equipment.provSpecificationId === networkData?.hgw.type,
  );

  return (
    <NetworkMenuItem
      networkData={networkData}
      menuText={t('pages.network.advanced.settings.sections.bridge.name')}
      helpTextHeader={t('pages.network.advanced.settings.helpTexts.bridge.title')}
      onClick={() => goToPath(navigationService.PAGES.internetSettingsWifiBridgeMode.url)}
      actionElement={
        isOn
          ? t('pages.network.advanced.settings.sections.bridge.statusOn')
          : t('pages.network.advanced.settings.sections.bridge.statusOff')
      }
    >
      <div className={styles.informationText}>
        <Typography variant="paragraph3" component="p" className={styles.helpText}>
          {t('pages.network.advanced.settings.helpTexts.bridge.paragraph1.content')}
        </Typography>
        <Typography variant="paragraph3" component="p" className={styles.helpText}>
          {t('pages.network.advanced.settings.helpTexts.bridge.paragraph2.content')}
        </Typography>
        {(hgwEnrichment?.bridgeModePortLabel && (
          <Typography variant="paragraph3" component="p" className={styles.helpText}>
            {t('pages.network.advanced.settings.helpTexts.bridge.port.known', {
              bridgeModePortLabel: hgwEnrichment.bridgeModePortLabel,
            })}
          </Typography>
        )) || (
          <Typography variant="paragraph3" component="p" className={styles.helpText}>
            <Trans i18nKey={'pages.network.advanced.settings.helpTexts.bridge.port.unknown'}>
              placeholdertext
              <a href={hgwEnrichment?.customerServiceUrl || FALLBACK_SELFCARE_HGW_URL} />
            </Trans>
          </Typography>
        )}
      </div>
    </NetworkMenuItem>
  );
};
