import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Toggle, Breakpoint } from '@altibox/design-system-component-lib';

import styles from './subscription-item.module.scss';

interface Props {
  id: string;
  description: string;
  name: string;
  frequency: string;
  user?: ReactElement;
  toggleState: boolean;
  toggleHandler: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  isPending: boolean;
}

export const SubscriptionItem: React.FC<Props> = ({
  id,
  description,
  name,
  frequency,
  user,
  toggleHandler,
  toggleState,
  isPending,
}) => {
  const { t } = useTranslation();

  return (
    <li className={styles.item}>
      <div className={styles.toggleContainer}>
        <Toggle
          id={id}
          label={name}
          isOn={toggleState}
          toggle={toggleHandler}
          textOn={t('features.subscriptions.text.on')}
          textOff={t('features.subscriptions.text.off')}
          name={id}
          isDisabled={isPending}
        />
      </div>
      <Typography variant="paragraph1" component="p" className={styles.description} maxBreakpoint={Breakpoint.TABLET}>
        {description}
      </Typography>
      <Typography variant="paragraph1" component="p" className={styles.frequencyUser} maxBreakpoint={Breakpoint.TABLET}>
        {frequency}
      </Typography>
      {user && (
        <Typography
          variant="paragraph1"
          component="p"
          className={styles.frequencyUser}
          maxBreakpoint={Breakpoint.TABLET}
        >
          {user}
        </Typography>
      )}
    </li>
  );
};
