const CONFIG_ATTRIBUTES = {
  AUTH_CALLBACK_URL: 'AUTH_CALLBACK_URL',
  CLIENT_ID: 'CLIENT_ID',
  SCOPE: 'SCOPE',
  AUTHORIZATION_URL: 'AUTHORIZATION_URL',
  CHANGE_PASSWORD_URL: 'CHANGE_PASSWORD_URL',
  CHANGE_MULTIFACTORAUTHENTICATION_URL: 'CHANGE_MULTIFACTORAUTHENTICATION_URL',
  LOGOUT_URL: 'LOGOUT_URL',
  LOGGED_OUT_REDIRECT_URL: 'LOGGED_OUT_REDIRECT_URL',
  OAUTH_PROVIDER: 'OAUTH_PROVIDER',
  MINESIDER_BACKEND_URL: 'MINESIDER_BACKEND_URL',
  MAINTENANCE_URL: 'MAINTENANCE_URL',
  GTM_ID: 'GTM_ID',
  CI360_TENANT_SHORT_NAME: 'CI360_TENANT_SHORT_NAME',
  CI360_TENANT_ID: 'CI360_TENANT_ID',
  SENTRY_ENABLED: 'SENTRY_TOGGLE',
  SENTRY_DSN: 'SENTRY_DSN',
  SENTRY_TRACING_RATE: 'SENTRY_TRACING_RATE',
  PUPPET_ENVIRONMENT: 'PUPPET_ENVIRONMENT',
  LYSE_RELOCATION_URL: 'LYSE_RELOCATION_URL',
  LYSE_INVOICE_URL: 'LYSE_INVOICE_URL',
  LYSE_CONSENT_URL: 'LYSE_CONSENT_URL',
  LOCIZE_PROJECT_ID: 'LOCIZE_PROJECT_ID',
  LOCIZE_API_KEY: 'LOCIZE_API_KEY',
  LOCIZE_VERSION: 'LOCIZE_VERSION',
  USERINFORMATION_VERIFICATION_FETCH_TIMEOUT: 'USERINFORMATION_VERIFICATION_FETCH_TIMEOUT',
};

export { CONFIG_ATTRIBUTES };
