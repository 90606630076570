import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks/redux-thunk';
import {
  Breakpoint,
  Button,
  ButtonType,
  Input,
  Typography as T,
  UiComponentSize,
  UiComponentState,
} from '@altibox/design-system-component-lib';

import { Spinner } from 'app/components/spinner';
import { InfoCircle } from 'app/media';
import { useUpdateAccessPoint } from 'app/hooks/use-network';

import { FetchStatus } from 'app/store/root-types';
import { AccessPointToggle } from './access-point-toggle/access-point-toggle';
import { CombinedBand } from '../network-menu-item/combined-band/combined-band';
import { CopySettingsToggle } from './copy-settings-toggle/copy-settings-toggle';
import { SSID } from '../ssid/ssid';
import { NetworkMenuItem } from '../network-menu-item/network-menu-item';
import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';

import styles from './wifi-pluss.module.scss';
import commonStyles from 'app/pages/internet/network-common.module.scss';
interface Props {
  accessPoints: MinesiderBackend.AccessPoint[];
  customerNetwork: MinesiderBackend.CustomerNetwork;
  servicePointId: string;
  customerId: string;
}

export const WifiPluss = ({ accessPoints, customerNetwork, servicePointId, customerId }: Props) => {
  const { t } = useTranslation();

  const { goToPath } = useAppNavigation();
  const { fetchStatus, itemBeingUpdated, saveTrigger, updateAccessPointDevice } = useUpdateAccessPoint();
  const [apNames, setApNames] = useState(accessPoints.map((ap) => ({ id: ap.id, name: ap.name, touched: false })));

  const { data: equipmentEnrichment, fetchStatus: enrichmentFetchStatus } = useAppSelector(
    (state) => state.enrichment.equipment,
  );

  const wifiPlusAccessPointEnrichment = equipmentEnrichment?.find(
    (eq) => eq.provSpecificationId === accessPoints[0].deviceType,
  );

  const saveAccessPointName = (id: number, inheritFromCpe: boolean) => {
    if (fetchStatus === FetchStatus.PENDING) {
      return;
    }
    const deviceName = apNames.find((ap) => ap.id === id)?.name;
    if (deviceName) {
      updateAccessPointDevice({ id, name: deviceName, inheritFromCpe });
      setApNames(() => apNames.map((ap) => ({ ...ap, touched: false })));
    }
  };

  const updateAccessPointName = (id: number, name: string) => {
    const updatedNames = apNames.map((ap) => {
      if (ap.id === id) return { ...ap, name, touched: true };
      return ap;
    });
    setApNames(updatedNames);
  };

  const isValidName = (id: number) =>
    apNames.find((ap) => ap.id === id)?.name ? UiComponentState.SUCCESS : UiComponentState.ERROR;

  const advancedSettings = ({ accessPoint }: { accessPoint: MinesiderBackend.AccessPoint }) => {
    if (accessPoint.inheritFromCpe) {
      return null;
    }
    return (
      <>
        <SSID
          customerId={customerId}
          servicePointId={servicePointId}
          wifiIds={accessPoint.wifiSettings}
          regexValidations={accessPoint.regexValidations}
          fieldValues={accessPoint.fieldValues}
        />
        <CombinedBand networkData={customerNetwork} wifiIds={accessPoint.wifiSettings} />
        <NetworkMenuItem
          actionElement={t('pages.network.advanced.wifi.advanced.subHeader')}
          menuText={t('pages.network.advanced.wifi.advanced.name')}
          helpTextHeader={t('pages.network.advanced.wifi.helpTexts.advanced.title')}
          onClick={() => goToPath(navigationService.getAdvancedWifiSettingUrl(accessPoint.wifiSettings))}
        >
          <T variant="paragraph3" component="p">
            {t('pages.network.advanced.wifi.helpTexts.advanced.paragraph1')}
          </T>
        </NetworkMenuItem>
      </>
    );
  };

  const accessPointEdit = (accessPoint: MinesiderBackend.AccessPoint) => {
    if (!accessPoint) {
      return null;
    }

    const isApEnabled = accessPoint.wifiSettings.some((settingId) =>
      customerNetwork.wifiSettings.some((wifi) => wifi.id === settingId && wifi.enabled),
    );

    const apNamesIsTouched = apNames.filter((ap) => ap.id === accessPoint.id && ap.touched).length > 0;

    return (
      <li className={styles.accessPointContainer} key={accessPoint.id}>
        <AccessPointToggle
          toggleIsOn={isApEnabled}
          customerId={customerId}
          id={accessPoint.id}
          name={accessPoint.name}
          accessPoint={accessPoint}
          networkData={customerNetwork}
        />
        {isApEnabled && (
          <div className={styles.deviceContainer}>
            <div className={classNames(styles.itemContainer, styles.nameSection)}>
              <Input
                id={`ap-name-${accessPoint.id}`}
                label={t('pages.network.advanced.settings.sections.accessPoints.nameHeading')}
                defaultValue={accessPoint.name}
                onChange={(e) => updateAccessPointName(accessPoint.id, e.currentTarget.value)}
                uiComponentState={isValidName(accessPoint.id)}
              />

              <div className={styles.iconContainer}>
                <div className={styles.infoIcon}>
                  <InfoCircle variant="filled" />
                </div>
                <T variant="uiText3" component="span" className={styles.helpText} maxBreakpoint={Breakpoint.TABLET}>
                  {t('pages.network.advanced.settings.helpTexts.accessPoints.name')}
                </T>
              </div>

              {(fetchStatus !== FetchStatus.PENDING ||
                saveTrigger !== 'name' ||
                itemBeingUpdated !== accessPoint.id) && (
                <Button
                  buttonType={ButtonType.PRIMARY_B}
                  uiComponentSize={UiComponentSize.MEDIUM}
                  aria-disabled={!apNamesIsTouched}
                  className={classNames(styles.buttonSave, apNamesIsTouched ? null : commonStyles.buttonDisabled)}
                  type="submit"
                  onClick={() =>
                    apNamesIsTouched ? saveAccessPointName(accessPoint.id, accessPoint.inheritFromCpe) : null
                  }
                >
                  {t('pages.network.advanced.settings.buttons.save')}
                </Button>
              )}
              {fetchStatus === FetchStatus.PENDING && saveTrigger === 'name' && itemBeingUpdated === accessPoint.id && (
                <Spinner className={styles.spinner} />
              )}
            </div>
            <div className={styles.itemContainer}>
              <CopySettingsToggle
                accessPoint={accessPoint}
                deviceId={accessPoint.id}
                combinedIsEnabled={accessPoint.inheritFromCpe}
                className={classNames(styles.accessPointToggleWrapper, styles.accessPointCombinedWrapper)}
              />
            </div>
            {advancedSettings({ accessPoint })}
          </div>
        )}
      </li>
    );
  };

  return (
    <div className={styles.wifiPlussContainer}>
      <div className={styles.wifiPlussImageContainer}>
        <div className={styles.wifiPlussImage}>
          {enrichmentFetchStatus === FetchStatus.PENDING ? (
            <Spinner />
          ) : (
            <img
              src={wifiPlusAccessPointEnrichment?.images && wifiPlusAccessPointEnrichment.images[0].imageUrl}
              alt=""
            />
          )}
        </div>
        <h2 className={styles.wifiPlussHeader}>{t('pages.network.advanced.settings.sections.accessPoints.name')}</h2>

        <T component="p" variant="uiText2" className={styles.wifiPlussHeaderDescription}>
          {t('pages.network.advanced.settings.sections.accessPoints.paragraph')}
        </T>
      </div>

      <ul>{accessPoints.map((accessPoint) => accessPointEdit(accessPoint))}</ul>
    </div>
  );
};
