import { useTranslation } from 'react-i18next';
import { Alert } from '@altibox/design-system-component-lib';

import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { WifiSettings } from '../wifi-settings/wifi-settings';
import { HiddenH1 } from 'app/components/hidden-h1';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { ContainerFixed } from 'app/components/container';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { useLoadNetworkSettings } from 'app/hooks/use-network';
import { AltiboxWifiOveralt } from '../wifi-settings/awo/altibox-wifi-overalt';
import { FetchStatus } from 'app/store/root-types';
import { internetSettingsTabList } from 'app/utils/navigation-utils';
import { useLoadEquipmentEnrichment } from 'app/hooks/use-equipment';

export const InternetSettings: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const customerId = props.userContext.customerCrmId;
  const servicePointId = props.userContext.servicePointId;
  const { data: networkData, fetchStatus } = useAppSelector((state) => state.network);

  const tabList = internetSettingsTabList({ userContext: props.userContext, networkData });

  useLoadNetworkSettings({ servicePointId, customerId });
  useLoadEquipmentEnrichment();

  const isAwo = networkData?.installationType === 'SINGLE_MESH';

  return (
    <ContainerFixed isNarrow={false}>
      <HiddenH1>{t('pages.network.advanced.name')}</HiddenH1>
      <TabbedContent selectedTabKey={'overview'} tabList={tabList}>
        <>
          {fetchStatus === FetchStatus.REJECTED && (
            <Alert
              heading={t('pages.network.advanced.customerNetworkFailed')}
              headingElement="strong"
              alertType="warning"
              isExpandable={false}
            />
          )}
          {isAwo && <AltiboxWifiOveralt {...props} />}
          {!isAwo && <WifiSettings {...props} />}
        </>
      </TabbedContent>
    </ContainerFixed>
  );
};
