import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Breakpoint,
  Button,
  ButtonType,
  Input,
  Typography,
  UiComponentState,
} from '@altibox/design-system-component-lib';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { navigationService } from 'app/service/navigation/navigation-service';
import { activateEmailAccount } from 'app/store/actions/email-thunks';
import { FetchStatus } from 'app/store/root-types';
import {
  emailAccountPasswordComplexityIsOk,
  emailAccountPasswordIsValid,
  emailAccountPasswordLengthIsOk,
} from 'app/utils/validators';

import { CustomerPage } from 'app/pages/page-wrapper';
import { ContainerFixed } from 'app/components/container';
import { IconMessage } from 'app/components/icon-message/icon-message';
import { emailSenderNameIsValid } from 'app/components/email/email-common/email-common';
import { Spinner } from 'app/components/spinner/spinner';
import { useAppNavigation } from 'app/utils/navigation-utils';

import commonEmailCss from '../email-common.module.scss';

export const ActivateEmailAccount: CustomerPage = () => {
  const { emailAccountId } = useParams();
  const { emailAccounts } = useAppSelector((state) => state.email);
  const { fetchStatus } = useAppSelector((state) => state.email.activateAccount);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();
  const [passwordIsHidden, setPasswordIsHidden] = useState(true);
  const [editAccount, setEditAccount] = useState<MinesiderBackend.EmailAccount>();
  const inputInit = {
    isTouched: false,
    isValid: false,
    value: '',
  };
  const [form, setForm] = useState({
    firstName: { ...inputInit },
    lastName: { ...inputInit },
    password: { ...inputInit },
  });

  useEffect(() => {
    if (fetchStatus === FetchStatus.FULFILLED) {
      goToPath(navigationService.PAGES.email.url);
    }
  }, [fetchStatus]);

  useEffect(() => {
    const account = emailAccounts?.find((x) => x.id?.toString() === emailAccountId);
    if (!account) {
      goToPath(navigationService.PAGES.email.url);
      return;
    }

    setEditAccount(account);
    if (account.firstName && account.lastName) {
      setForm({
        ...form,
        firstName: {
          isTouched: true,
          isValid: emailSenderNameIsValid(account.firstName),
          value: account.firstName,
        },
        lastName: {
          isTouched: true,
          isValid: emailSenderNameIsValid(account.lastName),
          value: account.lastName,
        },
      });
    }
  }, [emailAccounts]);

  const formIsValid = () => form.firstName.isValid && form.lastName.isValid && form.password.isValid;

  const submitActivateAccount = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setForm({
      ...form,
      firstName: {
        ...form.firstName,
        isTouched: true,
      },
      lastName: {
        ...form.lastName,
        isTouched: true,
      },
      password: {
        ...form.password,
        isTouched: true,
      },
    });

    if (formIsValid()) {
      dispatch(
        activateEmailAccount({
          emailAccountId: editAccount!.id.toString(),
          firstname: form.firstName.value,
          lastname: form.lastName.value,
          password: form.password.value,
        }),
      );
    }
  };

  const firstNameUiComponentState = () =>
    form.firstName.isTouched && !form.firstName.isValid ? UiComponentState.ERROR : undefined;
  const lastNameUiComponentState = () =>
    form.lastName.isTouched && !form.lastName.isValid ? UiComponentState.ERROR : undefined;
  const passwordUiComponentState = () =>
    form.password.isTouched && !form.password.isValid ? UiComponentState.ERROR : undefined;
  const firstNameHelpText = () =>
    form.firstName.isTouched && !form.firstName.isValid
      ? t('pages.email.createNewAccount.validation.firstName')
      : undefined;
  const lastNameHelpText = () =>
    form.lastName.isTouched && !form.lastName.isValid
      ? t('pages.email.createNewAccount.validation.lastName')
      : undefined;

  return (
    <ContainerFixed isNarrow={true}>
      <Typography
        component="h1"
        variant="headline4"
        maxBreakpoint={Breakpoint.TABLET}
        className={commonEmailCss.heading}
      >
        {t('pages.emailActivateAccount.heading', { emailAddress: editAccount?.email })}
      </Typography>

      <div className={commonEmailCss.formBorder}>
        <Typography component="div" variant="paragraph2" maxBreakpoint={Breakpoint.TABLET} renderAsHTML={true}>
          {t('pages.emailActivateAccount.confirmActivationInformation', { emailAddress: editAccount?.email })}
        </Typography>

        <div className={commonEmailCss.formRow}>
          <Input
            label={t('pages.email.createNewAccount.firstName')}
            id="firstName"
            value={form.firstName.value}
            onChange={(e) => {
              setForm({
                ...form,
                firstName: {
                  isValid: emailSenderNameIsValid(e.currentTarget.value),
                  isTouched: true,
                  value: e.currentTarget.value,
                },
              });
            }}
            uiComponentState={firstNameUiComponentState()}
            helpText={firstNameHelpText()}
          />
        </div>
        <div className={commonEmailCss.formRowTall}>
          <Input
            label={t('pages.email.createNewAccount.lastName')}
            id="lastName"
            value={form.lastName.value}
            onChange={(e) =>
              setForm({
                ...form,
                lastName: {
                  value: e.currentTarget.value,
                  isValid: emailSenderNameIsValid(e.currentTarget.value),
                  isTouched: true,
                },
              })
            }
            uiComponentState={lastNameUiComponentState()}
            helpText={lastNameHelpText()}
          />
        </div>
        <div className={commonEmailCss.formRow}>
          <Input
            label={t('pages.email.createNewAccount.password')}
            isPassword={true}
            isHidden={passwordIsHidden}
            id="password"
            onToggleHidden={() => setPasswordIsHidden(!passwordIsHidden)}
            helpText={
              passwordUiComponentState() === UiComponentState.ERROR
                ? ''
                : t('pages.email.emailPasswordRequirements.initial')
            }
            onChange={(e) =>
              setForm({
                ...form,
                password: {
                  value: e.currentTarget.value,
                  isTouched: true,
                  isValid: emailAccountPasswordIsValid(e.currentTarget.value),
                },
              })
            }
            uiComponentState={passwordUiComponentState()}
            autoFocus={true}
          />
          {passwordUiComponentState() === UiComponentState.ERROR && (
            <>
              {!emailAccountPasswordLengthIsOk(form.password.value) && (
                <IconMessage status="warning" text={t('pages.email.emailPasswordRequirements.length')} />
              )}
              {!emailAccountPasswordComplexityIsOk(form.password.value) && (
                <IconMessage status="warning" text={t('pages.email.emailPasswordRequirements.complexity')} />
              )}
            </>
          )}
        </div>
      </div>

      <div className={commonEmailCss.buttonRow}>
        {fetchStatus === FetchStatus.PENDING ? (
          <Spinner />
        ) : (
          <>
            <Button
              buttonType={ButtonType.SECONDARY}
              className={commonEmailCss.cancel}
              onClick={(e) => {
                e.preventDefault();
                goToPath(navigationService.PAGES.email.url);
              }}
              type="button"
            >
              {t('pages.email.cancel')}
            </Button>
            <Button buttonType={ButtonType.PRIMARY_B} onClick={submitActivateAccount}>
              {t('pages.emailActivateAccount.confirmActivationButton')}
            </Button>
          </>
        )}
      </div>

      <div className={commonEmailCss.errorMessage}>
        {fetchStatus === FetchStatus.REJECTED && (
          <Alert
            alertType="warning"
            heading={t('pages.emailActivateAccount.errorMessage')}
            headingElement="strong"
            isExpandable={false}
            role="alert"
          />
        )}
      </div>
    </ContainerFixed>
  );
};
