import { useState } from 'react';
import { Helper } from 'app/components/helper/helper';
import { HeadingLevel, Toggle, Typography as T } from '@altibox/design-system-component-lib';
import { useUpdateAccessPoint } from 'app/hooks/use-network';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from '../wifi-pluss.module.scss';

interface Props {
  deviceId: number;
  combinedIsEnabled: boolean;
  accessPoint: MinesiderBackend.AccessPoint;
  className?: string;
}
export const CopySettingsToggle = ({ deviceId, combinedIsEnabled, accessPoint, className }: Props) => {
  const { t } = useTranslation();
  const { updateAccessPointDevice } = useUpdateAccessPoint();
  const [helpElement, setHelpElement] = useState<JSX.Element | undefined>();

  const toggleCallBack = (helpText?: JSX.Element) => {
    setHelpElement(helpText);
  };

  const toggleCopySettings = () => {
    updateAccessPointDevice({
      id: accessPoint.id,
      name: accessPoint.name,
      inheritFromCpe: !accessPoint.inheritFromCpe,
    });
  };

  const wrapperCssClasses = classNames(styles.combinedContainer, className);

  return (
    <div className={wrapperCssClasses}>
      <Helper
        text={t('pages.network.advanced.settings.sections.accessPoints.copySettings')}
        textVariant="uiText1"
        textComponent="h3"
        heading={t('pages.network.advanced.settings.helpTexts.accessPoints.copySettings.title')}
        alertHeadingElement={HeadingLevel.H4}
        className={styles.containerHelper}
        classNameExpanded={styles.containerHelperExpanded}
        handleChildrenCallback={toggleCallBack}
      >
        <T variant="paragraph3" component="p">
          {t('pages.network.advanced.settings.helpTexts.accessPoints.copySettings.content')}
        </T>
      </Helper>
      {helpElement && <div className={styles.helpTextWrapperSameSettings}>{helpElement}</div>}

      <div className={styles.toggleWrapper}>
        <Toggle
          id={`band_toggle_${deviceId}`}
          isOn={combinedIsEnabled}
          textOn={t('pages.network.advanced.settings.on')}
          textOff={t('pages.network.advanced.settings.off')}
          label={''}
          toggle={toggleCopySettings}
          aria-label={'Toggle'}
        />
      </div>
    </div>
  );
};
