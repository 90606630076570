import { useTranslation } from 'react-i18next';
import {
  Alert,
  Breakpoint,
  Button,
  ButtonType,
  Typography,
  UiComponentSize,
} from '@altibox/design-system-component-lib';

import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { navigationService } from 'app/service/navigation/navigation-service';
import { FetchStatus } from 'app/store/root-types';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { useContactDetails } from 'app/hooks/use-contact-details';
import { useAppDispatch } from 'app/hooks/redux-thunk';
import { dismissContactDetailsToastMessage } from 'app/store/actions/contact-details-actions';

import { Card } from 'app/components/card/card';
import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';
import { LogoType, PartnerLogo } from 'app/components/partner-logo/partner-logo';

import styles from './contact-details.module.scss';

export const ContactDetails: ActiveCustomerLocationPage = (props) => {
  const { userContext } = props;
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();
  const dispatch = useAppDispatch();

  const { contactDetails, fetchStatus, displayName, toastMessage, displayContactId } = useContactDetails(userContext);

  const canEditDetails = contactDetails?.isEnterprise !== true && userContext.role === 'PRIMARY';

  const showBillingAddressSection = contactDetails?.billingAddress || contactDetails?.syncBillingAddress;

  const EditButton = () =>
    canEditDetails ? (
      <Button
        buttonType={ButtonType.SECONDARY}
        onClick={() => {
          dispatch(dismissContactDetailsToastMessage());
          goToPath(navigationService.PAGES.contactDetailsEdit.url);
        }}
        className={styles.editButton}
        uiComponentSize={UiComponentSize.LARGE}
      >
        {t('pages.contactDetails.buttons.edit')}
      </Button>
    ) : null;

  const renderAddress = (address: MinesiderBackend.CustomerAddress) => {
    if (address.postbox) {
      return address.postbox;
    }

    return (
      <>
        {address.street} {address.houseNr}
        {address.houseLetter}, {address.zip} {address.city}
      </>
    );
  };

  if (!contactDetails) {
    switch (fetchStatus) {
      case FetchStatus.NOT_STARTED:
      case FetchStatus.PENDING:
        return <Spinner />;
      default:
        return (
          <Alert
            alertType="warning"
            heading={t('pages.contactDetailsCommon.fetchingFailed')}
            headingElement="strong"
            isExpandable={false}
            role="alert"
          />
        );
    }
  }

  return (
    <div className={styles.container}>
      <ContainerFixed isNarrow={true}>
        <>
          {toastMessage && (
            <div className={styles.toast}>
              <Alert
                alertType="success"
                heading={toastMessage}
                headingElement="strong"
                isExpandable={false}
                isDismissable={true}
                role="status"
              />
            </div>
          )}
        </>
        <Typography variant="headline4" component="h1" maxBreakpoint={Breakpoint.TABLET} className={styles.cardTitle}>
          {t('pages.contactDetails.name')}
        </Typography>
        <Card className={styles.card}>
          <div className={styles.cardSection}>
            <Typography
              variant="uiText1"
              component="div"
              bold={true}
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.label}
            >
              {t('pages.contactDetails.customerNumber')}
            </Typography>
            <Typography variant="uiText1" component="div" maxBreakpoint={Breakpoint.TABLET}>
              {contactDetails.customerId}
            </Typography>
          </div>
          <div className={styles.partnerSection}>
            <PartnerLogo className={styles.logo} logoType={LogoType.LOGO} />
            <span>
              <Typography
                variant="uiText1"
                component="div"
                bold={true}
                maxBreakpoint={Breakpoint.TABLET}
                className={styles.label}
              >
                {t('pages.contactDetails.partner')}
              </Typography>
              <Typography variant="uiText1" component="div" maxBreakpoint={Breakpoint.TABLET}>
                {userContext.partnerName}
              </Typography>
            </span>
          </div>
        </Card>

        <Typography variant="headline4" component="h2" maxBreakpoint={Breakpoint.TABLET} className={styles.cardTitle}>
          {t('pages.contactDetails.myContactInformation')}
        </Typography>
        <Card className={styles.card}>
          <div className={styles.cardSection}>
            <Typography
              variant="uiText1"
              component="div"
              bold={true}
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.label}
            >
              {t('pages.contactDetailsCommon.customerRelationships')}
            </Typography>

            <Typography
              data-hj-suppress={true}
              variant="uiText1"
              component="div"
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.name}
            >
              {displayName}
            </Typography>
            <Typography variant="uiText1" component="div" maxBreakpoint={Breakpoint.TABLET}>
              {contactDetails.isEnterprise
                ? t('pages.contactDetails.organizationNumber')
                : t('pages.contactDetails.born')}
              : {displayContactId}
            </Typography>
          </div>
          <div className={styles.cardSection}>
            <Typography
              variant="uiText1"
              component="div"
              bold={true}
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.label}
            >
              {t('pages.contactDetailsCommon.contactDetails')}
            </Typography>
            <Typography
              variant="uiText1"
              component="div"
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.emailAddress}
            >
              {contactDetails.emailAddress}
            </Typography>
            <Typography variant="uiText1" component="div" maxBreakpoint={Breakpoint.TABLET}>
              {contactDetails.mobileCountryCode} {contactDetails.mobileNumber}
            </Typography>
          </div>
          <div className={styles.cardSection}>
            <Typography
              variant="uiText1"
              component="div"
              bold={true}
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.label}
            >
              {t('pages.contactDetailsCommon.contactAddress')}
            </Typography>
            <Typography data-hj-suppress={true} variant="uiText1" component="div" maxBreakpoint={Breakpoint.TABLET}>
              {contactDetails.contactAddress && renderAddress(contactDetails.contactAddress)}
            </Typography>
            {!showBillingAddressSection && <EditButton />}
          </div>
          {showBillingAddressSection && (
            <div>
              <Typography
                variant="uiText1"
                component="div"
                bold={true}
                maxBreakpoint={Breakpoint.TABLET}
                className={styles.label}
              >
                {t('pages.contactDetailsCommon.billingAddress')}
              </Typography>
              <Typography data-hj-suppress={true} variant="uiText1" component="div" maxBreakpoint={Breakpoint.TABLET}>
                {contactDetails.syncBillingAddress
                  ? t('pages.contactDetails.sameAsContactAddress')
                  : renderAddress(contactDetails.billingAddress!)}
              </Typography>
              <EditButton />
            </div>
          )}
        </Card>
      </ContainerFixed>
    </div>
  );
};
