import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, RadioButton, HeadingLevel } from '@altibox/design-system-component-lib';

import { Field } from '../form-utils';
import { FormModel } from '../firewall-setting-utils';
import { Card } from 'app/components/card';
import { Helper } from 'app/components/helper/helper';

import styles from './firewall-rule-form.module.scss';

interface Props {
  form: FormModel;
  setForm: Dispatch<SetStateAction<FormModel>>;
}

export enum ProtocolType {
  TCP = 'TCP',
  UDP = 'UDP',
}

export const FirewallRulePortType: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { form, setForm } = props;
  const inputChange: (value: string, validator?: (value: string) => string | undefined) => Field<string> = (
    value,
    validator,
  ) => ({
    value,
    isTouched: true,
    error: validator && validator(value),
  });
  const handleRadioChange = (
    value: string,
    field: (f: Field<string>) => Partial<FormModel>,
    validator?: (value: string) => string | undefined,
  ) => {
    setForm((formState) => ({
      ...formState,
      ...field(inputChange(value, validator)),
    }));
  };

  return (
    <Card className={styles.card}>
      <div className={styles.formRow}>
        <Helper
          heading={t('pages.firewall.explainMessages.portExplainHeading')}
          text={t('pages.firewall.createNewFirewallRule.portType.heading')}
          textVariant="uiText2"
          textComponent="h3"
          alertHeadingElement={HeadingLevel.H4}
        >
          <Typography variant="uiText3" component="p">
            {t('pages.firewall.explainMessages.portExplain')}
          </Typography>
        </Helper>
        <RadioButton
          className={styles.port}
          groupname="port-type"
          options={[
            {
              label: t('pages.firewall.createNewFirewallRule.portType.TCP'),
              value: ProtocolType.TCP,
            },
            {
              label: t('pages.firewall.createNewFirewallRule.portType.UDP'),
              value: ProtocolType.UDP,
            },
          ]}
          selectedValue={form.type?.value}
          getSelectedValue={(v) => handleRadioChange(v, (f) => ({ type: f }))}
        />
      </div>
    </Card>
  );
};
