/* eslint-disable react/jsx-key */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationPicker } from 'app/components/location-picker';
import { Alert, Breakpoint, Col, Grid, GridWidth, Typography } from '@altibox/design-system-component-lib';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';

import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';
import { ProductItem } from 'app/features/product/components/product-item/product-item';
import { ProductGroup } from 'app/features/product/components/product-category/product-category';

import { getProduct } from 'app/store/actions/products-thunks';

import styles from './products.module.scss';

export const Products: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const siteId = props.userContext.siteId;
  const { fetchStatus, products } = useAppSelector((state) => state.products);

  const sortOrder = ['INTERNET', 'TV', 'TV_CHANNELS', 'VOIP', 'SIP', 'OTHER'];
  const sortFunction = (a: string, b: string) => {
    // sorting categories not in the sortOrder list last.
    if (sortOrder.indexOf(a) === -1) {
      return 1;
    }
    if (sortOrder.indexOf(b) === -1) {
      return -1;
    }

    if (sortOrder.indexOf(a) > sortOrder.indexOf(b)) {
      return 1;
    }
    if (sortOrder.indexOf(a) < sortOrder.indexOf(b)) {
      return -1;
    }
    return 0;
  };

  const categories = (products || [])
    .map((item) => item.category)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort(sortFunction);

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(getProduct(siteId));
    }
  }, [fetchStatus]);
  const customerLocation = props.userContext;

  return (
    <ContainerFixed isNarrow={true} className={styles.container}>
      <Grid width={GridWidth.NARROW}>
        <Col sm={2} md={6} lg={12}>
          {customerLocation ? (
            <LocationPicker selectedLocation={customerLocation} className={styles.locationContainer} />
          ) : null}
        </Col>
      </Grid>
      <Typography variant="headline2" component="h1" className={styles.heading} maxBreakpoint={Breakpoint.TABLET}>
        {t('pages.myProducts.name')}
      </Typography>
      <>
        {fetchStatus === FetchStatus.PENDING && <Spinner />}

        {fetchStatus === FetchStatus.REJECTED && (
          <Alert
            heading={t(`pages.product.errorMessage.getProductsFailed`)}
            headingElement="strong"
            alertType="warning"
            role="alert"
            isExpandable={false}
          />
        )}
        {fetchStatus === FetchStatus.FULFILLED && (
          <div className={styles.options}>
            {categories.map((category, idx) => (
              <ProductGroup name={category} key={idx}>
                {(products || [])
                  .filter((x) => x.category === category)
                  .map((p, index) => (
                    <ProductItem name={p.name} key={index} />
                  ))}
              </ProductGroup>
            ))}
          </div>
        )}
      </>
    </ContainerFixed>
  );
};
