import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@altibox/design-system-component-lib';

import { config } from 'app/config/config';

import styles from './language-switcher.module.scss';

interface Props {
  initialLocizeLanguage: string;
  setShowLanguageBar: React.Dispatch<boolean>;
}

export const LanguageSwitcher = ({ initialLocizeLanguage, setShowLanguageBar }: Props) => {
  const { t, i18n } = useTranslation();
  const [locizeLanguage, setLocizeLanguage] = useState(initialLocizeLanguage);
  const [minimize, setMinimize] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(locizeLanguage);
  }, [locizeLanguage]);

  return (
    <div className={styles.swapLanguage}>
      <div className={minimize ? styles.minimized : ''} hidden={minimize}>
        {config.locize.supportedLanguages.map((lng) => (
          <Button key={lng} onClick={() => setLocizeLanguage(lng)}>
            {t('features.languageBar.swapLanguage')} {lng}
          </Button>
        ))}
      </div>
      <Button onClick={() => setLocizeLanguage('cimode')}>{t('features.languageBar.showKeyOnly')}</Button>
      <Button onClick={() => setMinimize(!minimize)}>{minimize ? '<' : '>'}</Button>
      <Button onClick={() => setShowLanguageBar(false)}>X</Button>
    </div>
  );
};
