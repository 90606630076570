import { Dispatch, useEffect, useState } from 'react';
import { Toggle, Typography } from '@altibox/design-system-component-lib';
import { useTranslation } from 'react-i18next';
import { ConfigAction, WifiBandConfig } from '../ssid/ssid-form/ssid-reducer';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { setManagedWifiSetting, setSingleNetworkSetting, setWifiSettings } from 'app/store/actions/network-thunks';

import styles from './wifi-toggle.module.scss';

interface Props {
  servicePointId: string;
  customerId: string;
  isCombined: boolean;
  isCsr?: boolean;
  config: WifiBandConfig;
  configIndex: number;
  uiHideToggleButton?: boolean;
  className?: string;
  configDispatch: Dispatch<ConfigAction>;
}

export const WifiToggle = ({
  config,
  isCombined,
  isCsr,
  configDispatch,
  configIndex,
  uiHideToggleButton,
  customerId,
  servicePointId,
  className,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: networkData } = useAppSelector((state) => state.network);
  const { data: configData } = config;
  const [toggleLabel, setToggleLabel] = useState<string>();
  const [toggleDescription, setToggleDescription] = useState<string>();

  useEffect(() => {
    if (isCombined) {
      setToggleLabel(t('pages.network.advanced.wifi.heading'));
      return;
    }
    if (config.data.type === 'GUEST') {
      setToggleLabel(t('pages.network.managedWifi.guest.heading'));
      setToggleDescription(t('pages.network.managedWifi.guest.description'));
      return;
    }
    if (config.data.type === 'OFFICE') {
      setToggleLabel(t('pages.network.managedWifi.office.heading'));
      setToggleDescription(t('pages.network.managedWifi.office.description'));
      return;
    }

    setToggleLabel(
      config.data.radioBand === 2 ? t('pages.network.advanced.wifi.band24') : t('pages.network.advanced.wifi.band5'),
    );
    setToggleDescription(
      configData.radioBand === 2
        ? t('pages.network.wifiSettings.Wifi2GhzShortInfo')
        : t('pages.network.wifiSettings.Wifi5GhzShortInfo'),
    );
  }, [config, uiHideToggleButton, isCombined]);

  const toggleBand = (newConfig: WifiBandConfig) => {
    if (isCombined && newConfig.data.type === 'STANDARD') {
      const isHgw = networkData?.hgw.wifiSettings.includes(configData.id);
      const apSettings =
        networkData && networkData.accessPoints.length > 0
          ? networkData?.accessPoints.find((ap) => ap.wifiSettings.includes(configData.id))
          : null;

      const wifiIds = isHgw ? networkData?.hgw.wifiSettings : apSettings?.wifiSettings;

      const configsToToggle = wifiIds?.map((_, i) => {
        const { encKey, ...rest } = networkData?.wifiSettings.find((wifi) => wifi.id === wifiIds[i])!;
        rest.enabled = !configData.enabled;
        return rest;
      });

      dispatch(
        setWifiSettings({
          customerId,
          servicePointId,
          wifiNetwork: configsToToggle!,
        }),
      );
      return;
    }

    const networkConfig: MinesiderBackend.UpdateSingleWifiNetwork = {
      ...newConfig.data,
      isSSidHidden: newConfig.data.isSSidHidden || false,
      enabled: !newConfig.data.enabled,
    };

    if (networkConfig.type === 'OFFICE' || networkConfig.type === 'GUEST') {
      if (isCsr) {
        delete networkConfig.encKey;
      }
      dispatch(
        setManagedWifiSetting({
          servicePointId,
          wifiId: newConfig.data.id,
          customerId,
          networkConfig,
        }),
      );
    }

    if (networkConfig.type === 'STANDARD') {
      delete networkConfig.encKey;
      dispatch(
        setSingleNetworkSetting({
          servicePointId,
          wifiId: newConfig.data.id,
          customerId,
          networkConfig,
        }),
      );
    }

    configDispatch({
      index: configIndex,
      type: 'toggleEnabled',
    });
  };

  return (
    <div className={className}>
      <div className={styles.bandToggle}>
        {uiHideToggleButton ? (
          <Typography variant="uiText1" component="div" bold={true}>
            <label
              aria-label={
                config.data.radioBand === 2
                  ? t('pages.network.advanced.wifi.band24Aria')
                  : t('pages.network.advanced.wifi.band5Aria')
              }
            >
              {toggleLabel}
            </label>
          </Typography>
        ) : (
          <WifiToggleInput label={toggleLabel} config={config} onToggle={() => toggleBand(config)} />
        )}
      </div>

      <div className={styles.bandFrequencyHelp}>
        {!isCombined && (
          <Typography variant="uiText3" component="p" className={styles.bandFrequencyHelpText}>
            {toggleDescription}
          </Typography>
        )}
      </div>
    </div>
  );
};

interface ToggleProps {
  config: WifiBandConfig;
  onToggle: () => void;
  label?: string;
}

const WifiToggleInput = ({ config, onToggle, label }: ToggleProps) => {
  const { t } = useTranslation();

  return (
    <Toggle
      isOn={config.data.enabled}
      id={`band${config.data.radioBand}_${config.data.id}`}
      data-testid={`band${config.data.radioBand}`}
      label={
        label ||
        (config.data.radioBand === 2 ? t('pages.network.advanced.wifi.band24') : t('pages.network.advanced.wifi.band5'))
      }
      aria-label={t('pages.network.advanced.wifi.activateAria', {
        band:
          config.data.radioBand === 2
            ? t('pages.network.advanced.wifi.band24Aria')
            : t('pages.network.advanced.wifi.band5Aria'),
      })}
      textOn={t('pages.network.toggle.on')}
      textOff={t('pages.network.toggle.off')}
      toggle={onToggle}
    />
  );
};
