import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Button, ButtonType, Typography } from '@altibox/design-system-component-lib';

import { FetchStatus } from 'app/store/root-types';

import { Spinner } from 'app/components/spinner';
import { Arrow } from 'app/media';

import styles from './firewall-rule.module.scss';

interface Props {
  rule: MinesiderBackend.PortForwarding;
  onUpdateRule: (rule: MinesiderBackend.PortForwarding) => void;
  onDeleteRule: (rule: MinesiderBackend.PortForwarding) => void;
  fetchStatus: FetchStatus;
}

export const FirewallRule: React.FC<Props> = (props) => {
  const { rule, onUpdateRule, onDeleteRule, fetchStatus } = props;
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const renderPorts = (portFrom: number, portTo: number | undefined) => {
    if (!portTo) {
      return portFrom.toString();
    }
    if (portFrom === portTo) {
      return portFrom;
    }
    return `${portFrom} - ${portTo}`;
  };

  const numberOfPorts = (from: number, to?: number) => {
    if (!to) return 1;
    if (from === to) return 1;
    return to - from + 1;
  };

  const internalPortTo = () =>
    rule.externalPortTo ? rule.externalPortTo - rule.externalPortFrom + rule.internalPort : undefined;

  return (
    <div className={styles.rule}>
      <Typography variant="uiText1" bold={true} component="p" className={styles.name} maxBreakpoint={Breakpoint.TABLET}>
        {rule.name}
      </Typography>
      <Typography variant="uiText1" component="p" className={styles.type} maxBreakpoint={Breakpoint.TABLET}>
        {` ${rule.type}: ${renderPorts(rule.externalPortFrom, rule.externalPortTo)}`}
      </Typography>
      <button aria-expanded={`${expanded}`} onClick={() => setExpanded(!expanded)} className={styles.show}>
        {expanded ? t('pages.firewall.buttons.hideDetails') : t('pages.firewall.buttons.showDetails')}
        {<Arrow direction={expanded ? 'up' : 'down'} variant="accordion" className={styles.arrow} />}
      </button>
      {expanded && (
        <div className={styles.background}>
          <Typography variant="uiText1" component="p" className={styles.ipaddress} maxBreakpoint={Breakpoint.TABLET}>
            {`${t('pages.firewall.createNewFirewallRule.ipAddress')}:  ${rule.internalIp} `}
          </Typography>
          <Typography
            variant="uiText1"
            bold={true}
            component="p"
            className={styles.portHeading}
            maxBreakpoint={Breakpoint.TABLET}
          >
            {t('pages.firewall.externalPort', {
              count: numberOfPorts(rule.externalPortFrom, rule.externalPortTo),
            })}
          </Typography>
          <div className={styles.externalPortNumber}>{renderPorts(rule.externalPortFrom, rule.externalPortTo)}</div>
          <Typography
            variant="uiText1"
            bold={true}
            component="p"
            className={styles.portHeading}
            maxBreakpoint={Breakpoint.TABLET}
          >
            {t('pages.firewall.internalPort', {
              count: numberOfPorts(rule.internalPort, internalPortTo()),
            })}
          </Typography>
          <div className={styles.internalPortNumber}>{renderPorts(rule.internalPort, internalPortTo())}</div>
          {fetchStatus === FetchStatus.PENDING && <Spinner />}
          {fetchStatus !== FetchStatus.PENDING && (
            <>
              <Button className={styles.button} buttonType={ButtonType.SECONDARY} onClick={() => onUpdateRule(rule)}>
                {t('pages.firewall.buttons.updateFirewallRule')}
              </Button>
              <Button className={styles.button} buttonType={ButtonType.TERTIARY} onClick={() => onDeleteRule(rule)}>
                {t('pages.firewall.buttons.deleteFirewallRule')}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};
