import { useTranslation } from 'react-i18next';
import { Col, Grid, GridWidth, Typography } from '@altibox/design-system-component-lib';

import { navigationService } from 'app/service/navigation/navigation-service';
import { OperationalMessages } from 'app/features/operational-messages';
import { LocationPicker } from 'app/components/location-picker';
import { Navigation } from 'app/features/navigation';
import { HiddenH1 } from 'app/components/hidden-h1';
import { InactiveCustomerLocationPage } from 'app/pages/page-wrapper';

import styles from './front-page.module.scss';
import commonStyles from '../common.module.scss';
import { ResubscribeInformation } from '../resubscribe-information/resubscribe-information';

export const InactiveFrontPage: InactiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();

  const inactiveCustomerLinkSections = [
    {
      title: t('pages.inactive.home.title'),
      pages: [
        navigationService.PAGES.inactiveMovies,
        navigationService.PAGES.invoice,
        navigationService.PAGES.invoiceLyse,
        navigationService.PAGES.invoiceLegacy,
        navigationService.PAGES.users,
        navigationService.PAGES.email,
      ],
    },
  ];

  const customerLocation = {
    ...props.userContext,
    address: {
      ...props.userContext.address,
      streetaddress: t('components.locationPanel.inactiveLocation'),
    },
  };

  const personalizedSections = navigationService.getPersonalizedLinkSections(
    inactiveCustomerLinkSections,
    customerLocation,
  );

  return (
    <>
      <div className={styles.welcome}>
        <HiddenH1>{t(navigationService.PAGES.home.i18n)}</HiddenH1>
        <Typography data-hj-suppress={true} component="p" variant="headline4" className={styles.greeting}>
          {t('pages.home.Hei, {{name}}!', { name: customerLocation.firstName })}
        </Typography>
        <Typography component="p" variant="paragraph1">
          {t('pages.home.Her kan du administrere dine Altibox-tjenester', {
            partnerName: customerLocation.partnerName,
          })}
        </Typography>
      </div>
      {
        <Grid width={GridWidth.NARROW}>
          <Col sm={2} md={6} lg={12}>
            <div className={styles.locationContainer}>
              <LocationPicker
                selectedLocation={customerLocation}
                addressLabel={`${t('components.locationPanel.inactiveCustomer')} ${customerLocation.customerCrmId}`}
              />
            </div>
          </Col>
        </Grid>
      }
      <ResubscribeInformation />
      <Grid width={GridWidth.NARROW}>
        <Col sm={2} md={6} lg={12}>
          <OperationalMessages />
          <div className={commonStyles.container}>
            {personalizedSections.map((section) => (
              <div key={`listkey-${section.title}`}>
                <Navigation section={section} />
              </div>
            ))}
          </div>
        </Col>
      </Grid>
    </>
  );
};
