import { createAction, Dispatch } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { ErrorTypes, GlobalError } from 'app/store/root-types';

// Types
// Action creators
export const setGlobalError = createAction<GlobalError>('SET_GLOBAL_ERROR');
export const clearGlobalError = createAction<undefined>('CLEAR_GLOBAL_ERROR');
export const enableTranslatorMode = createAction<undefined>('ENABLE_TRANSLATOR_MODE');
export const dismissVerificationPrompt = createAction<undefined>('DISMISS_VERIFICATION_PROMPT');

// Thunks
export const raiseGlobalError = (error: Error) => async (dispatch: Dispatch) => {
  Sentry.captureException(error);

  dispatch(
    setGlobalError({
      type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR, // consider checking "error instanceof NonRetryableSystemError" when introducing new error types
      message: error.message,
    }),
  );
};
