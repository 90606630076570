import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import 'locize';
import { config } from 'app/config/config';
import { customLanguageDetector } from 'app/utils/language-utils';

const backend = {
  backends: [
    HttpBackend,
    resourcesToBackend((lng, _ns, clb) => {
      import(`./locales/${lng}.json`).then((res) => clb(null, res)).catch((error) => clb(error, null));
    }),
  ],

  backendOptions: [
    {
      // Location of the public json files
      loadPath: '/minesider/static/locales/{{lng}}.json',
      referenceLng: 'nb',
    },
  ],

  version: config.locize.version,
};

// learn more: https://github.com/i18next/i18next-browser-languageDetector
const detector = new LanguageDetector();
detector.addDetector(customLanguageDetector);

const detection = {
  lookupQuerystring: 'lng',
  order: ['querystring', 'customLanguageDetector'],
};

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .use(detector)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend,
    ns: 'nyeminesider',
    supportedLngs: config.locize.supportedLanguages,
    fallbackLng: {
      'nb-NO': ['nb'],
      'nn-NO': ['nb'],
      nn: ['nb'],
      da: ['da', 'nb'],
      dk: ['da', 'nb'],
    },
    preload: ['nb'],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
    returnEmptyString: true,
    detection,
  });

export { i18n };
