import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Col, Grid, GridWidth, Typography } from '@altibox/design-system-component-lib';

import { ContainerFixed } from 'app/components/container';
import { CustomerLocationContext } from 'app/store/types/user-context-types';
import { HiddenH1 } from 'app/components/hidden-h1';
import { LocationPicker } from 'app/components/location-picker';
import { Navigation } from 'app/features/navigation';
import { navigationService } from 'app/service/navigation/navigation-service';
import { Partner } from 'app/utils/partner-utils';

import { ReactComponent as SVGContentAndSettings } from './images/content-and-settings.svg';
import { ReactComponent as SVGHandAndPencil } from './images/hand-and-pencil.svg';
import { ReactComponent as SVGProductAndUsage } from './images/product-and-usage.svg';
import { ReactComponent as SVGProfileAndPerso } from './images/profile-and-perso.svg';
import { ReactComponent as SVGWifiAnd } from './images/wifi-and.svg';

import styles from './welcome.module.scss';

interface Props {
  selectedLocation: CustomerLocationContext;
}
export const Welcome: React.FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation();
  const customerLocation = props.selectedLocation;

  const linkSection = {
    pages: [navigationService.PAGES.orderTrackerOpenNet],
  };

  return (
    <ContainerFixed className={styles.welcomePage}>
      <HiddenH1>{t('welcome.hiddenHeading')}</HiddenH1>
      <Typography variant="headline2" component="p" className={styles.heading}>
        {t('welcome.heading', { firstName: customerLocation?.firstName })}
      </Typography>

      <Grid width={GridWidth.NARROW}>
        <Col sm={2} md={6} lg={12}>
          <div className={styles.locationContainer}>
            <LocationPicker selectedLocation={props.selectedLocation} />
          </div>
        </Col>
      </Grid>

      <div className={styles.ingress}>
        <Typography variant="uiText2" component="p">
          {t('welcome.description')}
        </Typography>
        {customerLocation.partnerNumber === Partner.DK_AltiboxOpenNet ? (
          <div className={styles.orderTracker}>
            <Typography variant="uiText2" component="p">
              {t('welcome.orderTrackerInfo1')}
            </Typography>
            <Typography variant="uiText4" component="p">
              {t('welcome.orderTrackerInfo2')}
            </Typography>
            <Grid width={GridWidth.NARROW}>
              <Col sm={2} md={6} lg={12}>
                <div className={styles.linkSection}>
                  <Navigation section={linkSection} />
                </div>
              </Col>
            </Grid>
          </div>
        ) : null}
      </div>
      <div className={styles.illustrations}>
        <div className={classNames(styles.column, styles.contentAndSettings)}>
          <SVGContentAndSettings title={t('welcome.svgTitles.contentAndSettings')} role="img" />
        </div>
        <div className={classNames(styles.column, styles.productsAndUsage)}>
          <SVGProductAndUsage title={t('welcome.svgTitles.productsAndUsage')} role="img" />
        </div>
        <div className={classNames(styles.column, styles.profileAndPerso)}>
          <SVGProfileAndPerso title={t('welcome.svgTitles.profileAndPerso')} role="img" />
        </div>
        <div className={classNames(styles.column, styles.wifiAnd)}>
          <SVGWifiAnd title={t('welcome.svgTitles.wifiAnd')} role="img" />
        </div>
        <div className={classNames(styles.column, styles.handWithPencil)}>
          <div className={styles.transformation}>
            <SVGHandAndPencil title={t('welcome.svgTitles.handWithPencil')} role="img" />
          </div>
        </div>
      </div>
    </ContainerFixed>
  );
};
