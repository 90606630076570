import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, HeadingLevel, Typography } from '@altibox/design-system-component-lib';

import { Card } from 'app/components/card';
import { Helper } from 'app/components/helper/helper';

import styles from './network-settings-form.module.scss';

interface Props {
  network: MinesiderBackend.CustomerNetwork;
}

export const WanCard: React.FC<Props> = (props) => {
  const { network } = props;
  const { t } = useTranslation();

  return (
    <Card className={styles.card}>
      <div>
        <div className={styles.helpNotice}>
          <Helper
            text={t('pages.network.advanced.settings.sections.wan.name')}
            textVariant="uiText2"
            textComponent="h3"
            heading={t('pages.network.advanced.settings.helpTexts.wan.title')}
            alertHeadingElement={HeadingLevel.H4}
          >
            <Typography variant="paragraph3" component="p">
              {t('pages.network.advanced.settings.helpTexts.wan.content')}
            </Typography>
          </Helper>
        </div>
        <div className={styles.helperLabel}>
          <Helper
            text={t('pages.network.advanced.settings.sections.wan.ipv4')}
            textVariant="uiText2"
            textComponent="label"
            heading={t('pages.network.advanced.settings.helpTexts.ipv4.title')}
            alertHeadingElement={HeadingLevel.H4}
          >
            <Typography variant="paragraph3" component="p">
              {t('pages.network.advanced.settings.helpTexts.ipv4.content')}
            </Typography>
          </Helper>
        </div>
        <Typography
          variant="uiText1"
          component="div"
          maxBreakpoint={Breakpoint.TABLET}
          className={styles.ipAddressLabel}
        >
          {network.networkSettings?.publicIpv4Address || (process.env.NODE_ENV === 'development' ? '127.0.0.1' : null)}
        </Typography>
      </div>
      {network.networkSettings?.publicIpv6Address && (
        <div>
          <div className={styles.helperLabel}>
            <Helper
              text={t('pages.network.advanced.settings.sections.wan.ipv6')}
              textVariant="uiText2"
              textComponent="label"
              heading={t('pages.network.advanced.settings.helpTexts.ipv6.title')}
              alertHeadingElement={HeadingLevel.H4}
            >
              <Typography variant="paragraph3" component="p">
                {t('pages.network.advanced.settings.helpTexts.ipv6.content')}
              </Typography>
            </Helper>
          </div>
          <Typography variant="uiText1" component="div" maxBreakpoint={Breakpoint.TABLET} className={styles.ipv6}>
            {network.networkSettings?.publicIpv6Address}
          </Typography>
        </div>
      )}
    </Card>
  );
};
