import { Trans, useTranslation } from 'react-i18next';

import { Alert, Breakpoint, Button, ButtonType, Typography as T } from '@altibox/design-system-component-lib';
import { ContainerFixed } from 'app/components/container';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';

import styles from './primary-user-email-verification.module.scss';
import { resendVerificationEmail } from 'app/store/actions/users-thunks';
import { dismissVerificationPrompt } from 'app/store/actions/core-actions';
import { FetchStatus } from 'app/store/root-types';

export const UserEmailConfirmation = ({ userDetails }: { userDetails: MinesiderBackend.CrmUser }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetchStatus } = useAppSelector((state) => state.users.resendVerification);

  const processStarted = fetchStatus === FetchStatus.PENDING || fetchStatus === FetchStatus.FULFILLED;

  const resendEmail = () => {
    if (processStarted) {
      return;
    }
    dispatch(resendVerificationEmail(userDetails.id));
    dismiss();
  };

  const dismiss = () => {
    dispatch(dismissVerificationPrompt());
  };

  return (
    <ContainerFixed isNarrow={true}>
      <T
        component="h1"
        variant="headline1"
        id="confirmationLabel"
        className={styles.section}
        bold={true}
        maxBreakpoint={Breakpoint.TABLET}
      >
        <Trans i18nKey="features.primaryUserEmailVerification.heading">
          placeholder
          <span className={styles.noBreak}>e-post</span>
        </Trans>
      </T>
      <T component="p" variant="paragraph1" className={styles.section}>
        {t('features.primaryUserEmailVerification.paragraph')}
      </T>

      <div className={styles.section}>
        <T component="p" variant="uiText1" bold={true} className={styles.subHeader}>
          {t('features.primaryUserEmailVerification.question1')}
        </T>
        <T component="p" variant="paragraph1">
          {t('features.primaryUserEmailVerification.answer1', {
            buttonName: t('features.primaryUserEmailVerification.cta'),
          })}
        </T>
      </div>
      <T component="p" variant="paragraph1" className={styles.section}>
        {t('features.primaryUserEmailVerification.email')}: <strong>{userDetails.email}</strong>
      </T>
      <div className={styles.buttonRow}>
        <button onClick={dismiss} className={styles.dismissButton}>
          <T component="span" variant="uiText3" className={styles.dismissButtonText}>
            {t('features.primaryUserEmailVerification.postpone')}
          </T>
        </button>

        <Button onClick={resendEmail} buttonType={ButtonType.PRIMARY_B} className={styles.cta}>
          {t('features.primaryUserEmailVerification.cta')}
        </Button>
      </div>
      {fetchStatus === FetchStatus.FULFILLED && (
        <>
          <Alert
            alertType="success"
            heading={t('features.primaryUserEmailVerification.confirmed', { email: userDetails.email })}
            headingElement="p"
            isExpandable={false}
            className={styles.information}
          />
        </>
      )}
      {fetchStatus === FetchStatus.REJECTED && (
        <Alert
          alertType="warning"
          heading={t('features.primaryUserEmailVerification.error')}
          headingElement="p"
          isExpandable={false}
          className={styles.information}
        />
      )}
    </ContainerFixed>
  );
};
