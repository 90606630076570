import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Typography, RadioButton, Button, ButtonType } from '@altibox/design-system-component-lib';

import { Datepicker } from 'app/components/datepicker';
import { RelocationMovingDates, RelocationStepId } from 'app/store/slices/relocations-slice';
import {
  setMovingDateOption,
  setMovingInDate,
  setMovingOutDate,
  setCurrentStep,
} from 'app/store/actions/relocation-actions';
import { useAppSelector } from 'app/hooks/redux-thunk';

import styles from './relocation-date.module.scss';

export const RelocationDate: React.FC = () => {
  const dispatch = useDispatch();
  const movingDateOption = useAppSelector((state) => state.relocation.movingDateOption);
  const movingOutDate = useAppSelector((state) => state.relocation.movingOutDate);
  const movingInDate = useAppSelector((state) => state.relocation.movingInDate);
  const [stepIsValid, setStepIsValid] = useState(false);
  const { t } = useTranslation();

  const datepickerLabels = {
    day: t('pages.relocationForm.dateStep.datepicker.labels.day'),
    month: t('pages.relocationForm.dateStep.datepicker.labels.month'),
    year: t('pages.relocationForm.dateStep.datepicker.labels.year'),
  };

  const datepickerPlaceholders = {
    day: t('pages.relocationForm.dateStep.datepicker.placeholders.day'),
    month: t('pages.relocationForm.dateStep.datepicker.placeholders.month'),
    year: t('pages.relocationForm.dateStep.datepicker.placeholders.year'),
  };

  const datepickerErrors = {
    day: t('pages.relocationForm.dateStep.datepicker.errorMessages.day'),
    month: t('pages.relocationForm.dateStep.datepicker.errorMessages.month'),
    year: t('pages.relocationForm.dateStep.datepicker.errorMessages.year'),
  };

  const formatDefaultValues = (dateString: string) => ({
    day: dateString.split('.')[0],
    month: dateString.split('.')[1],
    year: dateString.split('.')[2],
  });

  const handleDateOptions = (value: string) => {
    if (value === RelocationMovingDates.SAME) {
      dispatch(setMovingDateOption(RelocationMovingDates.SAME));
      dispatch(setMovingInDate(null));
    } else if (value === RelocationMovingDates.DIFFERENT) {
      dispatch(setMovingDateOption(RelocationMovingDates.DIFFERENT));
    }
  };

  useEffect(() => {
    if (!movingOutDate || movingOutDate.length < 10) {
      setStepIsValid(false);
      return;
    }
    setStepIsValid(
      movingDateOption === RelocationMovingDates.SAME || (movingInDate !== null && movingInDate.length >= 10),
    );
  }, [movingDateOption, movingInDate, movingOutDate]);

  return (
    <div className={styles.dateSelectorContainer}>
      <Typography id="dateChangeHeader" className={styles.heading} variant="headline5" component="h1">
        {t('pages.relocationForm.dateStep.heading')}
      </Typography>
      <div className={styles.dateOptions}>
        <RadioButton
          groupname="date-option"
          options={[
            {
              label: t('pages.relocationForm.dateStep.dateOptions.sameDate'),
              value: RelocationMovingDates.SAME,
            },
            {
              label: t('pages.relocationForm.dateStep.dateOptions.differentDates'),
              value: RelocationMovingDates.DIFFERENT,
            },
          ]}
          selectedValue={movingDateOption}
          getSelectedValue={(value: string) => handleDateOptions(value)}
        />
      </div>

      <div className={styles.dateSelection}>
        <Datepicker
          legend={
            movingDateOption === RelocationMovingDates.DIFFERENT
              ? t('pages.relocationForm.dateStep.datepicker.movingOutLegend')
              : t('pages.relocationForm.dateStep.datepicker.movingLegend')
          }
          labels={datepickerLabels}
          errorMsgs={datepickerErrors}
          placeholders={datepickerPlaceholders}
          dateValue={(dateValue) => dispatch(setMovingOutDate(dateValue))}
          defaultDateValue={movingOutDate ? formatDefaultValues(movingOutDate) : null}
        />
        {movingDateOption === RelocationMovingDates.DIFFERENT && (
          <Datepicker
            legend={t('pages.relocationForm.dateStep.datepicker.movingInLegend')}
            labels={datepickerLabels}
            errorMsgs={datepickerErrors}
            placeholders={datepickerPlaceholders}
            dateValue={(dateValue) => dispatch(setMovingInDate(dateValue))}
            defaultDateValue={movingInDate ? formatDefaultValues(movingInDate) : null}
          />
        )}
      </div>
      <Typography className={styles.subscriptionPeriodHeading} variant="uiText2" component="p" bold={true}>
        {t('pages.relocationForm.dateStep.subscriptionPeriod.heading')}
      </Typography>
      <Typography className={styles.subscriptionPeriodDescription} variant="uiText2" component="p">
        {t('pages.relocationForm.dateStep.subscriptionPeriod.description')}
      </Typography>
      <div className={styles.stepNavigationContainer}>
        <Button onClick={() => dispatch(setCurrentStep(RelocationStepId.LOCATION))} buttonType={ButtonType.SECONDARY}>
          {t('pages.relocationForm.stepsNavigation.previous')}
        </Button>
        <Button
          onClick={() => dispatch(setCurrentStep(RelocationStepId.SUMMARY))}
          buttonType={ButtonType.PRIMARY_B}
          disabled={!stepIsValid}
        >
          {t('pages.relocationForm.stepsNavigation.next')}
        </Button>
      </div>
    </div>
  );
};
