import { usePartner } from 'app/hooks/use-partner';
import React from 'react';

export enum LogoType {
  LOGO = 'LOGO',
  CO_BOX_AIB_V = 'CO_BOX_AIB_V',
  CO_BOX_AIB_H = 'CO_BOX_AIB_H',
  CO_BOX_PARTNER_V = 'CO_BOX_PARTNER_V',
  CO_BOX_PARTNER_H = 'CO_BOX_PARTNER_H',
  CO_FONT_AIB_COLOR_V = 'CO_FONT_AIB_COLOR_V',
  CO_FONT_AIB_COLOR_H = 'CO_FONT_AIB_COLOR_H',
  CO_FONT_AIB_BLACK_V = 'CO_FONT_AIB_BLACK_V',
  CO_FONT_AIB_BLACK_H = 'CO_FONT_AIB_BLACK_H',
  CO_FONT_AIB_NEGATIVE_V = 'CO_FONT_AIB_NEGATIVE_V',
  CO_FONT_AIB_NEGATIVE_H = 'CO_FONT_AIB_NEGATIVE_H',
  CO_FONT_PARTNER_COLOR_V = 'CO_FONT_PARTNER_COLOR_V',
  CO_FONT_PARTNER_COLOR_H = 'CO_FONT_PARTNER_COLOR_H',
  CO_FONT_PARTNER_BLACK_V = 'CO_FONT_PARTNER_BLACK_V',
  CO_FONT_PARTNER_BLACK_H = 'CO_FONT_PARTNER_BLACK_H',
  CO_FONT_PARTNER_NEGATIVE_V = 'CO_FONT_PARTNER_NEGATIVE_V',
  CO_FONT_PARTNER_NEGATIVE_H = 'CO_FONT_PARTNER_NEGATIVE_H',
}
interface Props {
  className?: string;
  alt?: string;
  logoType: LogoType;
  logoLink?: string;
}

export const PartnerLogo: React.FC<Props> = (props) => {
  const { className, alt, logoType, logoLink } = props;
  const { partner } = usePartner();

  const logoUrl = partner && partner.logos && partner?.logos.find((logos) => logos.type === logoType)?.url;
  if (!logoUrl) {
    return null;
  }
  const logo = <img className={className} src={logoUrl} alt={alt || ''} />;

  return logoLink ? <a href={logoLink}>{logo}</a> : logo;
};
