import React, { HTMLAttributes } from 'react';
import { Typography } from '@altibox/design-system-component-lib';
import classnames from 'classnames';

import styles from './checkbox.module.scss';

interface Props extends HTMLAttributes<HTMLInputElement> {
  isChecked: boolean;
  label: React.ReactNode;
  children?: React.ReactNode;
}

export const Checkbox: React.FC<Props> = ({ className, isChecked, onChange, label, id, children, ...rest }) => (
  <div className={classnames(styles.container, className)}>
    <Typography component="label" htmlFor={id} variant="uiText2" className={styles.labelContainer}>
      <input
        type="checkbox"
        className={styles.checkboxInput}
        onChange={onChange}
        checked={isChecked}
        id={id}
        {...rest}
      />
      {label}
      <span className={styles.checkbox} />
    </Typography>
    {children}
  </div>
);
