import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from '@altibox/design-system-component-lib';

import { ContainerFixed } from 'app/components/container';
import { DeliveryStatus } from 'app/store/root-types';
import { formatDateOrDateRange, iso8601ToDayAndFullMonth } from 'app/utils/date-utils';
import { DeliveryStatusSteps } from 'app/features/delivery-status-timeline/delivery-status-timeline';

import styles from './introduction.module.scss';

interface IntroductionItems {
  [index: string]: string[];
}

interface Props {
  deliveryStatus: DeliveryStatus;
}

export const Introduction: React.FC<Props> = (props) => {
  const currentStep = props.deliveryStatus.step.combinedStep;
  const {
    excavationPlannedStartDate,
    excavationPlannedFinishDate,
    installationPlannedStartDate,
    connectionPlannedStartDate,
    connectionPlannedFinishDate,
  } = props.deliveryStatus;
  const { t } = useTranslation();

  const introductionItems: IntroductionItems = {
    '1-1': [t('pages.deliveryStatus.timeline.step1-1.introduction.paragraph1')],
    '2-1': [t('pages.deliveryStatus.timeline.step2-1.introduction.paragraph1')],
    '2-2': [t('pages.deliveryStatus.timeline.step2-2.introduction.paragraph1')],
    '2-3': [t('pages.deliveryStatus.timeline.step2-3.introduction.paragraph1')],
    '3-1': [t('pages.deliveryStatus.timeline.step3-1.introduction.paragraph1')],
    '3-2': [
      t('pages.deliveryStatus.timeline.step3-2.introduction.paragraph1'),
      t('pages.deliveryStatus.timeline.step3-2.introduction.paragraph2'),
    ],
    '4-1': [t('pages.deliveryStatus.timeline.step4-1.introduction.paragraph1')],
    '5-1': [t('pages.deliveryStatus.timeline.step5-1.introduction.paragraph1')],
  };

  const renderExcavationDate = (excavationStartDate?: string, excavationFinishDate?: string) => {
    if (!excavationStartDate) {
      return null;
    }

    const excavationDate = formatDateOrDateRange(excavationStartDate, excavationFinishDate);

    const dateLabel = excavationFinishDate
      ? t('pages.deliveryStatus.introductions.step2-2.dateRangeLabel')
      : t('pages.deliveryStatus.introductions.step2-2.dateLabel');

    return (
      <Typography
        className={styles.date}
        variant="uiText2"
        component="h3"
        maxBreakpoint={Breakpoint.TABLET}
        bold={true}
      >
        {dateLabel}: {excavationDate}
      </Typography>
    );
  };

  const renderPlannedConnectionDate = (startDate?: string, finishDate?: string) => {
    if (!startDate) {
      return null;
    }

    const formattedDate = formatDateOrDateRange(startDate, finishDate);

    return (
      <div className={styles.plannedConnectionContainer}>
        <Typography
          className={styles.date}
          variant="uiText2"
          component="h3"
          maxBreakpoint={Breakpoint.TABLET}
          bold={true}
        >
          {t('pages.deliveryStatus.plannedConnection.dateLabel')} {formattedDate}
        </Typography>
        <Typography
          className={styles.paragraph}
          component="p"
          variant="paragraph2"
          renderAsHTML={true}
          maxBreakpoint={Breakpoint.TABLET}
        >
          {t('pages.deliveryStatus.plannedConnection.extraInfo')}
        </Typography>
      </div>
    );
  };

  return (
    <ContainerFixed className={styles.introduction} isNarrow={true}>
      <Typography className={styles.headline} component="p" variant="headline1" maxBreakpoint={Breakpoint.MOBILE}>
        {t(`pages.deliveryStatus.timeline.DYNAMIC_KEYS.headings.step${currentStep}`)}
      </Typography>

      <>
        {(currentStep === DeliveryStatusSteps.step2_2 || currentStep === DeliveryStatusSteps.step2_3) &&
          renderExcavationDate(excavationPlannedStartDate, excavationPlannedFinishDate)}

        {currentStep === DeliveryStatusSteps.step4_1 && installationPlannedStartDate && (
          <Typography
            className={styles.date}
            variant="uiText2"
            component="h3"
            maxBreakpoint={Breakpoint.TABLET}
            bold={true}
          >
            {iso8601ToDayAndFullMonth(installationPlannedStartDate)}
          </Typography>
        )}
      </>
      <>
        {introductionItems[currentStep].map((paragraph: string, index: number) => (
          <Typography
            className={styles.paragraph}
            component="p"
            variant="paragraph2"
            key={index}
            renderAsHTML={true}
            maxBreakpoint={Breakpoint.TABLET}
          >
            {paragraph}
          </Typography>
        ))}
      </>

      {(currentStep === DeliveryStatusSteps.step2_2 ||
        currentStep === DeliveryStatusSteps.step2_3 ||
        currentStep === DeliveryStatusSteps.step3_1 ||
        currentStep === DeliveryStatusSteps.step3_2) &&
        connectionPlannedStartDate &&
        renderPlannedConnectionDate(connectionPlannedStartDate, connectionPlannedFinishDate)}
    </ContainerFixed>
  );
};
