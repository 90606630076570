import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Breakpoint, Typography } from '@altibox/design-system-component-lib';

import { CancelSource } from 'app/api/common';
import { swallowIfCancel } from 'app/utils/error-utils';
import { Search } from 'app/media';
import { MinesiderBackendService } from 'app/api/service/minesider-backend-service';
import { useDebounce } from 'app/hooks/use-debounce';

import styles from './location-search.module.scss';

interface Props {
  onSelectLocation: (address: { address: string | undefined; cadastreId: number | undefined }) => void;
}

const DEBOUNCE_WAIT_IN_MILLISECONDS = 400;
const MINIMUM_SEARCH_LENGTH = 3;

export const LocationSearch = (props: Props) => {
  const { t } = useTranslation();
  const [searchResults, setSearchResults] = useState<MinesiderBackend.Address[]>([]);
  const [focusCssClass, setFocusCssClass] = useState('');
  const [searchDataIndex, setSearchDataIndex] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce<string>(searchTerm, DEBOUNCE_WAIT_IN_MILLISECONDS);

  const seachFieldClassNames = classNames(styles.field, focusCssClass);

  useEffect(() => {
    if (debouncedSearchTerm.length < MINIMUM_SEARCH_LENGTH) {
      setSearchResults([]);
      return;
    }
    const cancelSource = new CancelSource();
    new MinesiderBackendService(cancelSource)
      .getAddressAutocomplete(debouncedSearchTerm)
      .then(setSearchResults)
      .catch(swallowIfCancel);
    return cancelSource.cancel;
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (searchResults.length > 0) {
      setSearchDataIndex(0);
    }
  }, [searchResults]);

  // Interactions with keyboard
  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      switch (key) {
        case 'ArrowDown':
          setSearchDataIndex(searchDataIndex < searchResults.length - 1 ? searchDataIndex + 1 : searchDataIndex);
          break;
        case 'ArrowUp':
          setSearchDataIndex(searchDataIndex !== 0 ? searchDataIndex - 1 : 0);
          break;
        case 'Enter':
          if (searchResults.length > 0 && searchResults[searchDataIndex].addressLine) {
            props.onSelectLocation({
              address: searchResults[searchDataIndex].addressLine,
              cadastreId: searchResults[searchDataIndex].cadastreUnitId,
            });
          }
          break;
      }
    },
    [searchResults, searchDataIndex, props],
  );

  // Add and remove event listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown, false);
  }, [handleKeyDown, searchDataIndex]);

  return (
    <div className={styles.searchContainer}>
      <Typography
        id="location-search"
        htmlFor="search-field"
        component="label"
        variant="uiText2"
        bold={true}
        maxBreakpoint={Breakpoint.TABLET}
        className={styles.label}
      >
        {t('components.locationSearch.addressLabel')}
      </Typography>
      <div
        role="combobox"
        aria-controls="location-results"
        aria-expanded="false"
        aria-owns="location-results"
        aria-haspopup="listbox"
        className={seachFieldClassNames}
      >
        <Search className={styles.icon} />
        <input
          id="search-field"
          onChange={({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) => setSearchTerm(currentTarget.value)}
          type="text"
          aria-autocomplete="list"
          className={styles.input}
          name="search"
          value={searchTerm}
          placeholder={t('components.locationSearch.placeholder')}
          autoComplete="off"
          onFocus={() => setFocusCssClass(styles.focus)}
          onBlur={() => {
            props.onSelectLocation({ address: searchTerm, cadastreId: 0 }); // allows the user to enter a custom address not from the search result
            setFocusCssClass('');
          }}
        />
      </div>
      {searchResults.length > 0 && debouncedSearchTerm.length > 0 ? (
        <div className={styles.results}>
          <ul role="listbox" aria-labelledby="location-search" id="location-results" className={styles.list}>
            {searchResults.map((address: MinesiderBackend.Address, index: number) => (
              <li
                onMouseDown={() =>
                  props.onSelectLocation({ address: address.addressLine, cadastreId: address.cadastreUnitId })
                }
                role="option"
                className={searchDataIndex === index ? classNames([styles.item, styles.itemFocus]) : styles.item}
                key={`key-searchresult-${address.addressId}`}
                aria-selected={index === 0 ? true : false}
              >
                {address.addressLine}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
