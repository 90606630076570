import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Breakpoint,
  Button,
  ButtonType,
  Typography,
  UiComponentSize,
} from '@altibox/design-system-component-lib';

import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { InactiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { clearUsersToastMessageAndStatuses } from 'app/store/actions/users-actions';

import { ContainerFixed } from 'app/components/container';
import { HiddenH1 } from 'app/components/hidden-h1';
import { Spinner } from 'app/components/spinner';

import { UserView } from './user-view';

import styles from './users-page.module.scss';
import { useUserInfo } from 'app/hooks/use-users-info';

export const UsersPage: InactiveCustomerLocationPage = ({ userContext }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showToastMessage, setShowToastMessage] = useState<string>();
  const crmId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.crmId);
  const usersFetchStatus = useAppSelector((state) => state.users.fetchStatus);
  const toastMessage = useAppSelector((state) => state.users.toastMessage);
  const { goToPath } = useAppNavigation();
  const { users } = useUserInfo(userContext);
  const myUser = (users || []).find((user) => user.id === crmId);
  const otherUsers = (users || []).filter((user) => user.id !== crmId);
  const isPrimaryOrAdmin = myUser?.role ? ['PRIMARY', 'ADMIN'].includes(myUser.role) : false;

  useEffect(() => {
    if (toastMessage) {
      setShowToastMessage(toastMessage);
    }
    dispatch(clearUsersToastMessageAndStatuses());
  }, [dispatch, toastMessage, setShowToastMessage]);

  const handleCreateUser = () => goToPath(navigationService.PAGES.userCreate.url);

  return (
    <ContainerFixed isNarrow={true} className={styles.usersPage}>
      <HiddenH1>{t(navigationService.PAGES.users.i18n)}</HiddenH1>
      <>
        {showToastMessage && (
          <div className={styles.toast}>
            <Alert
              alertType="success"
              heading={showToastMessage}
              headingElement="strong"
              isExpandable={false}
              isDismissable={true}
              role="status"
            />
          </div>
        )}
        <div className={styles.banner}>
          <Typography component="h2" variant="headline4" maxBreakpoint={Breakpoint.TABLET} className={styles.heading}>
            {t('pages.users.heading')}
          </Typography>
          <Typography component="p" variant="paragraph1" maxBreakpoint={Breakpoint.TABLET}>
            {t('pages.users.description')}
          </Typography>
        </div>
        {usersFetchStatus === FetchStatus.PENDING && <Spinner />}
        {(usersFetchStatus === FetchStatus.REJECTED || (usersFetchStatus === FetchStatus.FULFILLED && !users)) && (
          <Alert
            alertType="warning"
            heading={t('pages.users.errorMessages.fetchingFailed')}
            headingElement="strong"
            isExpandable={false}
            role="alert"
          />
        )}
        {usersFetchStatus === FetchStatus.FULFILLED && (
          <>
            {myUser && (
              <>
                <Typography
                  component="h2"
                  variant="headline4"
                  maxBreakpoint={Breakpoint.TABLET}
                  className={styles.sectionHeader}
                >
                  {t('pages.users.sections.mainUser')}
                </Typography>
                <UserView user={myUser} canChangePassword={true} canEdit={true} />
              </>
            )}
            {otherUsers[0] && (
              <>
                <Typography
                  component="h2"
                  variant="headline4"
                  maxBreakpoint={Breakpoint.TABLET}
                  className={styles.sectionHeader}
                >
                  {t('pages.users.sections.otherUsers')}
                </Typography>
                <ul className={styles.otherUsersList}>
                  {otherUsers.map((user) => (
                    <li key={user.id} className={styles.otherUser}>
                      <UserView user={user} canDelete={isPrimaryOrAdmin && user.role !== 'PRIMARY'} />
                    </li>
                  ))}
                </ul>
              </>
            )}
            {isPrimaryOrAdmin && (
              <div className={styles.buttonBar}>
                <Button
                  buttonType={ButtonType.SECONDARY}
                  uiComponentSize={UiComponentSize.LARGE}
                  onClick={handleCreateUser}
                >
                  {t('pages.users.buttons.createUser.name')}
                </Button>
              </div>
            )}
          </>
        )}
      </>
    </ContainerFixed>
  );
};
