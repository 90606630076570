import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Breakpoint,
  Button,
  ButtonType,
  Input,
  Typography,
  UiComponentState,
} from '@altibox/design-system-component-lib';

import { ContainerFixed } from 'app/components/container';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { CustomerPage } from 'app/pages/page-wrapper';
import { navigationService } from 'app/service/navigation/navigation-service';
import { changeEmailAccountPassword } from 'app/store/actions/email-thunks';
import { FetchStatus } from 'app/store/root-types';
import {
  emailAccountPasswordComplexityIsOk,
  emailAccountPasswordIsValid,
  emailAccountPasswordLengthIsOk,
} from 'app/utils/validators';

import { Spinner } from 'app/components/spinner/spinner';
import { IconMessage } from 'app/components/icon-message/icon-message';
import { useAppNavigation } from 'app/utils/navigation-utils';

import commonEmailCss from '../email-common.module.scss';

export const ChangePassword: CustomerPage = () => {
  const { emailAccountId } = useParams();
  const { emailAccounts } = useAppSelector((state) => state.email);
  const { fetchStatus } = useAppSelector((state) => state.email.changePassword);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();
  const [editAccount, setEditAccount] = useState<MinesiderBackend.EmailAccount>();
  const [password, setPassword] = useState('');
  const [passwordIsHidden, setPasswordIsHidden] = useState(true);
  const [showPasswordErrors, setShowPasswordErrors] = useState(false);

  useEffect(() => {
    if (fetchStatus === FetchStatus.FULFILLED) {
      goToPath(navigationService.PAGES.email.url);
    }
  }, [fetchStatus]);

  useEffect(() => {
    const account = emailAccounts?.find((x) => x.id?.toString() === emailAccountId);
    if (!account) {
      goToPath(navigationService.PAGES.email.url);
      return;
    }

    setEditAccount(account);
  }, [emailAccounts]);

  useEffect(() => {
    setShowPasswordErrors(false);
  }, [password]);

  const submitChangePassword = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!emailAccountPasswordIsValid(password)) {
      setShowPasswordErrors(true);
      return false;
    }

    dispatch(
      changeEmailAccountPassword({
        emailAccountId: editAccount!.id.toString(),
        password,
      }),
    );
  };

  return (
    <ContainerFixed isNarrow={true}>
      <form className={commonEmailCss.mobileEdge}>
        <Typography
          component="h1"
          variant="headline4"
          maxBreakpoint={Breakpoint.TABLET}
          className={commonEmailCss.heading}
        >
          {t('pages.emailChangePassword.heading', { emailAddress: editAccount?.email })}
        </Typography>
        <div className={commonEmailCss.formBorder}>
          <Input
            label={t('pages.emailChangePassword.passwordInputLabel')}
            id="password"
            autoFocus={true}
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            isPassword={true}
            helpText={!showPasswordErrors ? t('pages.email.emailPasswordRequirements.initial') : undefined}
            isHidden={passwordIsHidden}
            onToggleHidden={() => setPasswordIsHidden(!passwordIsHidden)}
            uiComponentState={showPasswordErrors ? UiComponentState.ERROR : undefined}
          />
          {showPasswordErrors && (
            <>
              {!emailAccountPasswordLengthIsOk(password) && (
                <IconMessage status="warning" text={t('pages.email.emailPasswordRequirements.length')} />
              )}
              {!emailAccountPasswordComplexityIsOk(password) && (
                <IconMessage status="warning" text={t('pages.email.emailPasswordRequirements.complexity')} />
              )}
            </>
          )}
        </div>

        {fetchStatus !== FetchStatus.PENDING && (
          <div className={commonEmailCss.buttonRow}>
            <Button
              buttonType={ButtonType.SECONDARY}
              className={commonEmailCss.cancel}
              onClick={(e) => {
                e.preventDefault();
                goToPath(navigationService.PAGES.email.url);
              }}
              type="button"
            >
              {t('pages.email.cancel')}
            </Button>
            <Button buttonType={ButtonType.PRIMARY_B} onClick={submitChangePassword}>
              {t('pages.emailChangePassword.changePasswordButton')}
            </Button>
          </div>
        )}

        {fetchStatus === FetchStatus.PENDING && <Spinner />}
        <div className={commonEmailCss.errorMessage}>
          {fetchStatus === FetchStatus.REJECTED && (
            <Alert
              alertType="warning"
              heading={t('pages.emailChangePassword.passwordNotSuccessfullyChanged')}
              headingElement="strong"
              isExpandable={false}
              role="alert"
            />
          )}
        </div>
      </form>
    </ContainerFixed>
  );
};
