import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Breakpoint, Button, ButtonType, Typography } from '@altibox/design-system-component-lib';

import { resendVerificationEmail } from 'app/store/actions/users-thunks';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { config } from 'app/config/config';

import { pages } from 'app/service/navigation/pages/pages';
import { navigationService } from 'app/service/navigation/navigation-service';
import { resolveForgerockUrl, useAppNavigation } from 'app/utils/navigation-utils';
import { User } from 'app/media';
import { Card } from 'app/components/card';
import { Spinner } from 'app/components/spinner';

import styles from './user-view.module.scss';

interface Props {
  user: MinesiderBackend.CrmUser;
  canEdit?: boolean;
  canChangePassword?: boolean;
  canDelete?: boolean;
}

export const UserView: React.FC<Props> = (props) => {
  const { user, canEdit, canChangePassword, canDelete } = props;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { goToPath } = useAppNavigation();
  const dispatch = useAppDispatch();
  const handleEditUser = () => goToPath(navigationService.PAGES.userEdit.url);
  const handleDeleteUser = (id: string) => goToPath(navigationService.getDeleteUserUrl(id));
  const { fetchStatus, resendUserId } = useAppSelector((state) => state.users.resendVerification);
  const isLoading = fetchStatus === FetchStatus.PENDING;
  const locale = i18n.language === 'nb' ? 'no' : i18n.language;

  const handleResendEmail = (userId: string) => {
    if (isLoading) {
      return;
    }
    dispatch(resendVerificationEmail(userId));
  };

  return (
    <Card className={styles.userView}>
      <div className={styles.contactsBlock}>
        <User className={styles.userIcon} role="presentation" />
        <div>
          <Typography
            data-hj-suppress={true}
            variant="uiText1"
            component="div"
            bold={true}
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.userName}
          >
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="uiText1" component="div" maxBreakpoint={Breakpoint.TABLET} className={styles.userRole}>
            {t(`features.user.role.DYNAMIC_KEYS.${user.role}`)}
          </Typography>
          <Typography variant="uiText1" component="div" maxBreakpoint={Breakpoint.TABLET} className={styles.userEmail}>
            {user.email}
          </Typography>
        </div>
      </div>

      {(canEdit || canDelete) && (
        <>
          {!user.emailVerified && (
            <Alert
              className={styles.alertBox}
              heading={t('pages.userVerify.heading')}
              headingElement="strong"
              isExpandable={false}
              role="alert"
              alertType="critical"
              isDismissable={true}
              isInitiallyExpanded={true}
            >
              <Typography component="p" variant="paragraph2">
                {t('pages.userVerify.information')}
              </Typography>
              <Typography bold={true} className={styles.alertInfo} component="p" variant="paragraph2">
                {t('pages.userVerify.question')}
              </Typography>
              <Typography component="p" variant="paragraph2">
                {t('pages.userVerify.answer')}
              </Typography>
            </Alert>
          )}
          {fetchStatus === FetchStatus.REJECTED && !user.emailVerified && resendUserId === user.id && (
            <Alert
              className={styles.alert}
              heading={t(`pages.userVerify.errorMessage`)}
              headingElement="strong"
              alertType="warning"
              role="alert"
              isDismissable={true}
              isExpandable={false}
            />
          )}
          {fetchStatus === FetchStatus.FULFILLED && !user.emailVerified && resendUserId === user.id && (
            <>
              <Alert
                className={styles.alert}
                heading={t(`pages.userVerify.successMessage`)}
                headingElement="strong"
                alertType="success"
                role="status"
                isExpandable={false}
              />
            </>
          )}
          <div className={styles.buttonBar}>
            {canEdit && (
              <Button buttonType={ButtonType.SECONDARY} onClick={handleEditUser} className={styles.alignLeft}>
                {t('pages.users.buttons.editUser.name')}
              </Button>
            )}
            {isLoading && user.id === resendUserId && <Spinner />}
            {!isLoading && canDelete && (
              <Button
                buttonType={canEdit ? ButtonType.TERTIARY : ButtonType.SECONDARY}
                onClick={() => handleDeleteUser(user.id)}
                className={canEdit ? undefined : styles.alignLeft}
              >
                {t('pages.users.buttons.deleteUser.name')}
              </Button>
            )}
            {!isLoading && !user.emailVerified && (
              <Button
                onClick={() => handleResendEmail(user.id)}
                buttonType={ButtonType.PRIMARY_B}
                className={styles.alignLeft}
              >
                {t('pages.userVerify.button')}
              </Button>
            )}
          </div>
        </>
      )}
      {canChangePassword && (
        <div className={styles.changeLoginDetails}>
          <a
            href={resolveForgerockUrl({ url: config.auth.changePassword, locale, returnPage: pages.users })}
            className={styles.changePasswordLink}
          >
            <Typography variant="uiText3" component="div" className={styles.changePasswordLinkText}>
              {t('pages.users.buttons.changePassword.name')}
            </Typography>
          </a>
          <a
            href={resolveForgerockUrl({
              url: config.auth.changeMultiFactorAuthentication,
              locale,
              returnPage: pages.users,
            })}
            className={styles.changePasswordLink}
          >
            <Typography variant="uiText3" component="div" className={styles.changePasswordLinkText}>
              {t('pages.users.buttons.updateMultiFactor.name')}
            </Typography>
          </a>
        </div>
      )}
    </Card>
  );
};
