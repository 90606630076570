import { useEffect } from 'react';
import { t } from 'i18next';
import { Alert, Breakpoint, Typography } from '@altibox/design-system-component-lib';
import { Page } from 'app/service/navigation/pages/page-types';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { Spinner } from 'app/components/spinner';
import { FetchStatus } from 'app/store/root-types';
import classNames from 'classnames';
import styles from './tabbed-content.module.scss';

export interface Tab {
  i18n: string;
  page: Page;
  url?: string; // overrides page.url, useful for params etc.
}

export interface TabList {
  [key: string]: Tab;
}

interface Props<T extends TabList> {
  children?: React.ReactNode;
  selectedTabKey: keyof TabList;
  tabList: T;
}

export const TabbedContent = <T extends TabList>(props: Props<T>) => {
  const { goToPath } = useAppNavigation();

  const { children, selectedTabKey } = props;
  const tabs = props.tabList;

  const { fetchStatus } = useAppSelector((state) => state.network);

  const tabKey: keyof TabList = selectedTabKey;
  const hasAccessToTab = tabs[tabKey] !== undefined;

  // Convert TabList object to an array of [key, value] pairs, then filter out undefined tabs
  const tabEntries = Object.entries(tabs) as [keyof TabList, Tab][];
  const tabIndexes = Object.keys(tabs) as (keyof TabList)[];
  const selectedTabIndex = tabIndexes.indexOf(selectedTabKey);

  const getTabClassNames = (tabIndex: number) =>
    classNames(styles.tab, tabIndex === selectedTabIndex ? styles.selected : null);

  const tabClick = (key: keyof TabList) => {
    goToPath(tabs[key].page.url);
  };

  useEffect(() => {
    document.getElementById(`tab-${selectedTabIndex}`)?.focus();
  }, []);

  const getNewTabIndex = (oldIndex: number, keyDownEventKey: string) => {
    switch (keyDownEventKey) {
      case 'ArrowRight':
        return oldIndex === tabEntries.length - 1 ? 0 : oldIndex + 1;
      case 'ArrowLeft':
        return oldIndex === 0 ? tabEntries.length - 1 : oldIndex - 1;
      default:
        return oldIndex;
    }
  };

  const tabKeyUp = async (e: React.KeyboardEvent<HTMLButtonElement>) => {
    const newTabIndex = getNewTabIndex(selectedTabIndex, e.key);
    const tab = tabEntries[newTabIndex][1];
    const url = tab.url || tab.page.url;
    goToPath(url);
  };

  if (fetchStatus === FetchStatus.PENDING) {
    return <Spinner />;
  }

  return (
    <>
      <div
        role="tablist"
        aria-label={t('features.tabbedContent.internetSettings.tablistAriaLabel')}
        className={styles.tabs}
      >
        {tabEntries.map(([key, tab], index) => (
          <button
            key={`tabkey-${index}`}
            role="tab"
            aria-selected={selectedTabIndex === index}
            className={getTabClassNames(index)}
            aria-controls={`panel-${index}`}
            id={`tab-${index}`}
            tabIndex={selectedTabIndex === index ? 0 : -1}
            onClick={() => tabClick(key as keyof TabList)}
            onKeyUp={tabKeyUp}
          >
            <Typography
              component="span"
              variant="uiText2"
              bold={selectedTabIndex === index}
              maxBreakpoint={Breakpoint.MOBILE}
            >
              {t(tab.i18n).toString()}
            </Typography>
          </button>
        ))}
      </div>
      <div
        id={`panel-${selectedTabIndex}`}
        role="tabpanel"
        tabIndex={0}
        aria-labelledby={`tab-${selectedTabIndex}`}
        hidden={false}
      >
        {hasAccessToTab ? (
          children
        ) : (
          <Alert
            alertType="warning"
            heading={t('features.tabbedContent.tabDoesNotExist')}
            headingElement="div"
            isExpandable={false}
            role="alert"
          />
        )}
      </div>
    </>
  );
};
