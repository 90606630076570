import React from 'react';

import { Typography } from '@altibox/design-system-component-lib';
import { Chromecast, Game, Wifi, Telephone, Tv } from 'app/media';

import styles from './product-category.module.scss';
import { t } from 'i18next';

interface Props {
  name: string;
  children?: React.ReactNode[];
}
const getCategoryIcon = (name: string) => {
  switch (name) {
    case 'INTERNET':
      return <Wifi className={styles.stroke} />;
    case 'TV':
      return <Tv className={styles.fill} />;
    case 'TV_CHANNELS':
      return <Chromecast className={styles.fill} />;
    case 'OTHER':
      return <Game className={styles.stroke} />;
    case 'VOIP':
    case 'SIP':
      return <Telephone className={styles.stroke} />;
    default:
      return <Chromecast className={styles.fill} />;
  }
};

export const ProductGroup: React.FC<Props> = ({ name, children }) => (
  <div className={styles.group}>
    <Typography variant="headline5" component="h2" className={styles.heading} bold={true}>
      <>
        <div className={styles.imageContainer}>{getCategoryIcon(name)}</div>
        {t(`pages.product.category.DYNAMIC_KEYS.${name}.title`)}
        <div className={styles.imageContainer} />
      </>
    </Typography>

    <ul className={styles.items}>{children}</ul>
  </div>
);
