import React, { PropsWithChildren, ReactElement, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Typography } from '@altibox/design-system-component-lib';

import {
  CustomerLocationContext,
  MainContext,
  isActiveLocation,
  isInactiveCustomer,
} from 'app/store/types/user-context-types';
import { LocationList } from 'app/components/location-list';
import { Spinner } from 'app/components/spinner';
import { SELECTED_LOCATION_COOKIE, WPBOX_SSOLOGIN_REDIRECT_URL } from 'app/utils/cookies';
import * as SentryUtils from 'app/utils/sentry-utils';
import { ContextSwitch } from 'app/features/context-switch/context-switch';
import { fetchUserContext, setSelectedCustomerLocation } from 'app/store/actions/user-context-thunks';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';

import styles from './context-provider.module.scss';
import { setUserContextCookies } from './context-provider-util';

interface Props {}

const ContextProvider: React.FunctionComponent<PropsWithChildren<Props>> = () => {
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);
  const customerLocations = useAppSelector((state) => state.userContext.customerLocations);
  const contextUser = useAppSelector((state) => state.userContext.user);

  const changingLocation = useAppSelector((state) => state.userContext.changingLocation);
  const isFetchingData = useAppSelector((state) => state.userContext.isFetchingData);
  const accessToken = useAppSelector((state) => state.auth.accessToken);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const selectedLocationCookie = Cookies.get(SELECTED_LOCATION_COOKIE);

  const setCustomerLocation = (customerLocation: CustomerLocationContext): void => {
    setUserContextCookies(customerLocation, contextUser, accessToken);
    dispatch(
      setSelectedCustomerLocation({
        customerLocation,
      }),
    );
  };

  // We should render LocationPicker if there's a mismatch between SELECTED_LOCATION_COOKIE and selectedCustomerLocation.
  // A new Location can have been set from an outside LocationPicker setting SELECTED_LOCATION_COOKIE,
  // or SELECTED_LOCATION_COOKIE is from another user login or something
  const locationCookieMismatch =
    selectedLocationCookie &&
    selectedCustomerLocation &&
    isActiveLocation(selectedCustomerLocation) &&
    selectedLocationCookie !== selectedCustomerLocation.siteId.toString();

  const shouldRenderApp = selectedCustomerLocation && !locationCookieMismatch;

  useEffect(() => {
    if (customerLocations.length === 0 && !isFetchingData) {
      dispatch(fetchUserContext());
      return;
    }

    if (changingLocation || shouldRenderApp) {
      return;
    }

    if (selectedLocationCookie) {
      const customerLocation = getCustomerLocationBySiteId(selectedLocationCookie);
      if (customerLocation && customerLocations.length > 0 && customerLocationsContainsSiteId(selectedLocationCookie)) {
        setCustomerLocation(customerLocation);
      } else if (customerLocations.length === 1) {
        setCustomerLocation(customerLocations[0]);
      }
    } else if (customerLocations.length === 1) {
      setCustomerLocation(customerLocations[0]);
    }
  }, [customerLocations, isFetchingData, changingLocation, shouldRenderApp, selectedLocationCookie]);

  useEffect(() => {
    if (contextUser) {
      SentryUtils.setUser(contextUser);
    }
  }, [contextUser]);

  const customerLocationsContainsSiteId = (siteId: string): boolean =>
    customerLocations.some((location) => isActiveLocation(location) && `${location.siteId}` === siteId);

  const getCustomerLocationBySiteId = (siteId: string): CustomerLocationContext | undefined =>
    customerLocations.find((location) => isActiveLocation(location) && `${location.siteId}` === siteId);

  const renderApp = (selectedContext: MainContext) => <ContextSwitch context={selectedContext} />;

  const renderLocationList = (): ReactElement => (
    <main className={styles.locationSelectorContainer}>
      <Typography id="locationListHeader" className={styles.title} variant="headline5" component="h1">
        {t('features.locationBoundary.headline')}
      </Typography>
      <Typography className={styles.subTitle} variant="uiText1" component="p">
        {t('features.locationBoundary.select')}
      </Typography>
      <LocationList selectLocation={setCustomerLocation} />
    </main>
  );

  const selectSiteOrRenderSelector = (siteId?: string): ReactElement | null => {
    if (siteId) {
      const customerLocation = getCustomerLocationBySiteId(siteId);
      if (customerLocation && customerLocations.length > 0 && customerLocationsContainsSiteId(siteId)) {
        return <Spinner marginVertical={true} />;
      }

      return customerLocations.length === 1 ? <Spinner marginVertical={true} /> : renderLocationList();
    }
    if (customerLocations.length === 1 && !isInactiveCustomer(customerLocations[0])) {
      return <Spinner marginVertical={true} />;
    }
    return renderLocationList();
  };

  if ((customerLocations.length === 0 && !isInactiveCustomer(customerLocations[0])) || isFetchingData) {
    return <Spinner marginVertical={true} />;
  }
  if (changingLocation || locationCookieMismatch) {
    return renderLocationList();
  }

  if (shouldRenderApp) {
    const redirectUrl = Cookies.get(WPBOX_SSOLOGIN_REDIRECT_URL);
    if (redirectUrl) {
      Cookies.remove(WPBOX_SSOLOGIN_REDIRECT_URL);
      window.location.replace(redirectUrl);
      return null;
    }
    return renderApp(selectedCustomerLocation as MainContext);
  }

  return selectSiteOrRenderSelector(selectedLocationCookie);
};

export { ContextProvider };
