import { useTranslation } from 'react-i18next';
import { Breakpoint, Col, Grid, GridWidth, Typography } from '@altibox/design-system-component-lib';

import styles from '../common.module.scss';
import { Navigation } from 'app/features/navigation/navigation';
import { navigationService } from 'app/service/navigation/navigation-service';
import { ResubscribeInformation } from '../resubscribe-information/resubscribe-information';

export const InactiveTv = () => {
  const { t } = useTranslation();

  const allLinkSections = [
    {
      pages: [navigationService.PAGES.inactiveMovies],
    },
  ];

  return (
    <>
      <ResubscribeInformation />
      <Grid width={GridWidth.NARROW}>
        <Col sm={2} md={6} lg={12}>
          <div className={styles.container}>
            <Typography
              component="h1"
              variant="headline5"
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.headline}
            >
              {t(navigationService.PAGES.tv.i18n)}
            </Typography>
            {allLinkSections.map((section, idx) => (
              <div key={`listkey-${idx}`}>
                <Navigation section={section} />
              </div>
            ))}
          </div>
        </Col>
      </Grid>
    </>
  );
};
