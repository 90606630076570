import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

interface ChangePasswordParameters {
  emailAccountId: string;
  password: string;
}
interface ChangeSenderNameParameters {
  emailAccountId: string;
  emailAccountName: MinesiderBackend.EmailAccountSetSenderName;
}
export interface ActivateEmailAccountParameters {
  emailAccountId: string;
  firstname: string;
  lastname: string;
  password: string;
}
export interface EmailAccountDeleted {
  emailAddress: string;
  disabled: boolean;
}

export const getEmailAccountResponse = createAsyncThunk('email/getEmailAccountResponse', async () =>
  minesiderBackendService.getEmailAccountResponse(),
);

export const createNewEmailAccount = createAsyncThunk<
  MinesiderBackend.EmailAccount, // Return type of the payload creator
  MinesiderBackend.EmailAccountCreate, // First argument to the payload creator
  { rejectValue: MinesiderBackend.ErrorResponse } // Types for ThunkAPI
>('email/createNewEmailAccount', async (emailAccount, thunkApi) =>
  minesiderBackendService.createNewEmailAccount(emailAccount).catch((error) => thunkApi.rejectWithValue(error.data)),
);

export const changeEmailAccountPassword = createAsyncThunk<
  MinesiderBackend.EmailAccount,
  ChangePasswordParameters,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('email/changePassword', async ({ emailAccountId, password }, thunkApi) =>
  minesiderBackendService
    .changeEmailAccountPassword(emailAccountId, { password })
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

export const changeEmailAccountSenderName = createAsyncThunk<
  MinesiderBackend.EmailAccount,
  ChangeSenderNameParameters,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('email/changeSenderName', async ({ emailAccountId, emailAccountName }, thunkApi) =>
  minesiderBackendService
    .changeEmailAccountName(emailAccountId, emailAccountName)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

export const deleteEmailAccount = createAsyncThunk<
  MinesiderBackend.EmailAccount,
  MinesiderBackend.EmailAccount,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('email/deleteEmailAccount', async (emailAccount, thunkApi) =>
  minesiderBackendService
    .deleteEmailAccount(emailAccount.id.toString())
    .then(() => emailAccount)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

export const activateEmailAccount = createAsyncThunk<
  MinesiderBackend.EmailAccount,
  ActivateEmailAccountParameters,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('email/activateEmailAccount', async (activateEmailAccountParameters, thunkApi) =>
  minesiderBackendService
    .activateEmailAccount(activateEmailAccountParameters)
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);
