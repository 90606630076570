import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, Typography } from '@altibox/design-system-component-lib';

import { RelocationStepId } from 'app/store/slices/relocations-slice';
import { setCurrentStep, setChangeOfAddress } from 'app/store/actions/relocation-actions';
import { displayDateToIso8601 } from 'app/utils/string-util';
import { ReactComponent as CheckMark } from 'app/media/icons/checkmark.svg';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';

import styles from './relocation-summary.module.scss';
import { isActiveLocation } from 'app/store/types/user-context-types';

export const RelocationSummary = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const movingToAddress = useAppSelector((state) => state.relocation.toAddress);
  const movingOutDate = useAppSelector((state) => state.relocation.movingOutDate);
  const movingInDate = useAppSelector((state) => state.relocation.movingInDate);
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);
  const { streetaddress, dwellingUnitNumber, postalcode, city } = selectedCustomerLocation!.address;
  const [siteId, setSiteId] = useState<string>('');
  useEffect(() => {
    if (selectedCustomerLocation && isActiveLocation(selectedCustomerLocation)) {
      setSiteId(selectedCustomerLocation.siteId.toString());
    }
  }, [selectedCustomerLocation]);

  const formatedFromAddress = selectedCustomerLocation
    ? `${streetaddress} ${dwellingUnitNumber}, ${postalcode} ${city}`
    : null;

  const submitAllowed = selectedCustomerLocation && movingToAddress && movingOutDate;

  return (
    <div className={styles.relocationSummary}>
      <Typography id="locationSummeryHeading" className={styles.heading} variant="headline5" component="h2">
        {t('pages.relocationForm.summaryStep.heading')}
      </Typography>
      <Typography className={styles.ingress} variant="uiText2" component="p">
        {t('pages.relocationForm.summaryStep.ingress')}
      </Typography>
      <Typography className={styles.notification} variant="uiText2" component="p" bold={true}>
        {t('pages.relocationForm.summaryStep.notification')}
      </Typography>
      <div className={styles.summaryCard}>
        <Typography className={styles.cardHeading} variant="uiText1" component="h3" bold={true}>
          {t('pages.relocationForm.summaryStep.subHeading')}
        </Typography>
        <div className={styles.section}>
          <Typography className={styles.sectionHeading} variant="uiText2" component="p" bold={true}>
            {t('pages.relocationForm.summaryStep.address.heading')}
          </Typography>
          <ul className={styles.sectionList}>
            <li className={styles.listItem}>
              <Typography className={styles.itemPrefix} variant="uiText2" component="span" bold={true}>
                {t('pages.relocationForm.summaryStep.address.from')}
              </Typography>
              <Typography data-hj-suppress={true} variant="uiText2" component="span">
                {formatedFromAddress}
              </Typography>
            </li>
            <li className={styles.listItem}>
              <Typography className={styles.itemPrefix} variant="uiText2" component="span" bold={true}>
                {t('pages.relocationForm.summaryStep.address.to')}
              </Typography>
              <Typography data-hj-suppress={true} variant="uiText2" component="span">
                {movingToAddress?.address}
              </Typography>
            </li>
          </ul>
          <Typography className={styles.sectionInfo} variant="uiText3" component="p">
            {t('pages.relocationForm.summaryStep.address.info')}
          </Typography>
        </div>

        <div className={styles.section}>
          <dl className={styles.movingDates}>
            <dt>
              <Typography className={styles.sectionHeading} variant="uiText2" component="p" bold={true}>
                {movingInDate
                  ? t('pages.relocationForm.summaryStep.date.movingOutHeading')
                  : t('pages.relocationForm.summaryStep.date.relocationDateHeading')}
              </Typography>
            </dt>
            <dd>
              <CheckMark className={styles.itemIcon} />
              <Typography variant="uiText2" component="span">
                {movingOutDate}
              </Typography>
            </dd>
            {movingInDate && (
              <>
                <dt>
                  <Typography className={styles.sectionHeading} variant="uiText2" component="p" bold={true}>
                    {t('pages.relocationForm.summaryStep.date.movingInHeading')}
                  </Typography>
                </dt>
                <dd>
                  <CheckMark className={styles.itemIcon} />
                  <Typography variant="uiText2" component="span">
                    {movingInDate}
                  </Typography>
                </dd>
              </>
            )}
          </dl>
        </div>
      </div>
      <div className={styles.stepNavigationContainer}>
        <Button onClick={() => dispatch(setCurrentStep(RelocationStepId.DATES))} buttonType={ButtonType.SECONDARY}>
          {t('pages.relocationForm.stepsNavigation.previous')}
        </Button>
        <Button
          disabled={!submitAllowed}
          onClick={() => {
            const from = {
              cadastreId: 0,
              address: formatedFromAddress!,
              when: displayDateToIso8601(movingOutDate!),
            };
            const to = {
              cadastreId: movingToAddress!.cadastreId,
              address: movingToAddress!.address,
              when: movingInDate ? displayDateToIso8601(movingInDate) : displayDateToIso8601(movingOutDate!),
            };
            dispatch(setChangeOfAddress({ siteId, from, to, userComment: '' }));
          }}
          buttonType={ButtonType.PRIMARY_B}
        >
          {t('pages.relocationForm.stepsNavigation.submit')}
        </Button>
      </div>
    </div>
  );
};
